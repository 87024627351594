import Row from 'design-system/Row'
import Typography from 'design-system/Typography'
import { forwardRef } from 'react'
import styled from 'styled-components'
import { space, layout, color, typography, compose } from 'styled-system'

const styles = compose(space, layout, color, typography)

const Base = styled.input.attrs({ type: 'radio' })`
  ${styles}
`

const RadioButton = forwardRef(({ label, ...props }, ref) => (
  <Row as='label' htmlFor={props?.ref} className='cursor-pointer' gap='8px'>
    <Base ref={ref} {...props} />
    {label && <Typography>{`${label} ${props?.required ? '*' : ''}`}</Typography>}
  </Row>
))

export default RadioButton
