import { Row, Typography } from 'design-system'

const PageHeader = ({ title, children }) => (
  <Row
    justifyContent='space-between'
    alignItems='center'
    flexDirection={['column', 'row']}
    minHeight='50px'
  >
    <Typography variant='title' color='primary'>
      {title || 'Page header'}
    </Typography>
    {children && (
      <Row
        mt={['16px', '0']}
        width={['100%', 'auto']}
        flexDirection={['column-reverse', 'row']}
        gap='16px'
      >
        {children}
      </Row>
    )}
  </Row>
)

export default PageHeader
