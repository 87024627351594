const isOutsideWorkHours = (workSchedule) => {
  const now = new Date()
  const weekday = now.getDay()
  const currentTime = now.toTimeString().split(' ')[0].substring(0, 5)

  if (workSchedule.length === 0) return true

  const userWorkHours = workSchedule.filter((work) => work.weekday === weekday)

  if (userWorkHours.length === 0) {
    return true
  }

  const { start, end } = userWorkHours[0]

  return currentTime < start || currentTime > end
}

export default isOutsideWorkHours
