import { useLocation } from 'react-router-dom'

import { UnauthorizedPermission, UnauthorizedWorkhours } from 'components'

const Unauthorized = (props) => {
  const location = useLocation()

  const unauthorizedDict = {
    permission: (p) => <UnauthorizedPermission {...p} />,
    workschedule: (p) => <UnauthorizedWorkhours {...p} />,
  }

  return unauthorizedDict[location?.state || 'permission'](props)
}

export default Unauthorized
