import { robotMessage } from 'assets/ilustrations'
import { Column, Image, Typography } from 'design-system'

const EmptyTicketContainer = () => {
  return (
    <Column alignItems='center' justifyContent='center' width='100%'>
      <Image
        maxWidth='270px'
        src={robotMessage}
        alt='Ilustração de robô indicando para selecionar uma mensagem na listagem'
      />
      <Typography maxWidth='200px' textAlign='center' fontSize='16px' fontWeight='400'>
        Selecione uma conversa para exibir as mensagens aqui.
      </Typography>
    </Column>
  )
}

export default EmptyTicketContainer
