
      import React, { memo } from 'react';

      const Tag = (props) => {
        return (
          <svg height="20" width="20"  viewBox="0 0 20 20" {...props}>
	<g clipPath="url(#clip0_212_3039)">
		<path d="M10.6998 18.4384L2 10.775V12.3405C2 12.7554 2.18661 13.1546 2.5243 13.4442L9.44682 19.5421C10.14 20.1526 11.2685 20.1526 11.9617 19.5421L17.4801 14.681C18.1733 14.0705 18.1733 13.0763 17.4801 12.4658L10.6998 18.4384Z" fill="#656565"/>
		<path d="M9.44682 15.6282C9.79339 15.9335 10.2466 16.09 10.6998 16.09C11.153 16.09 11.6062 15.9335 11.9528 15.6282L17.4713 10.7671C18.1644 10.1566 18.1644 9.16243 17.4713 8.55186L10.5487 2.45401C10.2199 2.16438 9.76673 2 9.29575 2H3.77728C2.79978 2 2 2.7045 2 3.56556V8.42661C2 8.84149 2.18661 9.24071 2.5243 9.53033L9.44682 15.6282ZM3.77728 3.56556H9.29575L16.2183 9.66341L10.6998 14.5245L3.77728 8.42661V3.56556Z" fill="#656565"/>
		<path d="M5.77673 6.30528C6.39021 6.30528 6.88753 5.86721 6.88753 5.32681C6.88753 4.78641 6.39021 4.34834 5.77673 4.34834C5.16325 4.34834 4.66593 4.78641 4.66593 5.32681C4.66593 5.86721 5.16325 6.30528 5.77673 6.30528Z" fill="#656565"/>
	</g>
	<defs>
		<clipPath id="clip0_212_3039">
			<rect height="20" width="20" fill="white"/>
		</clipPath>
	</defs>
</svg>
        );
      };
      const Memo = memo(Tag)
      export default Memo
    