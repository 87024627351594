
      import React, { memo } from 'react';

      const QrCode = (props) => {
        return (
          <svg height="20" width="20"  viewBox="0 0 20 20" {...props}>
	<path d="M2 8.66667V2H8.66667V8.66667H2ZM3.66667 7H7V3.66667H3.66667V7ZM2 17V10.3333H8.66667V17H2ZM3.66667 15.3333H7V12H3.66667V15.3333ZM10.3333 8.66667V2H17V8.66667H10.3333ZM12 7H15.3333V3.66667H12V7ZM15.3333 17V15.3333H17V17H15.3333ZM10.3333 12V10.3333H12V12H10.3333ZM12 13.6667V12H13.6667V13.6667H12ZM10.3333 15.3333V13.6667H12V15.3333H10.3333ZM12 17V15.3333H13.6667V17H12ZM13.6667 15.3333V13.6667H15.3333V15.3333H13.6667ZM13.6667 12V10.3333H15.3333V12H13.6667ZM15.3333 13.6667V12H17V13.6667H15.3333Z" fill="#656565"/>
</svg>
        );
      };
      const Memo = memo(QrCode)
      export default Memo
    