import { memo } from 'react'

const Megaphone = (props) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <mask x='0' y='0' width='24' height='24'>
        <rect width='24' height='24' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_1798_7650)'>
        <path
          d='M17.9623 12.7497V11.2497H21.5778V12.7497H17.9623ZM19.1046 19.4805L16.2123 17.3112L17.1238 16.1152L20.0161 18.2842L19.1046 19.4805ZM17.0853 7.84597L16.1738 6.64972L19.0661 4.48047L19.9778 5.67672L17.0853 7.84597ZM5.25083 18.4805V14.5767H4.23158C3.73291 14.5767 3.30699 14.4001 2.95383 14.0467C2.60049 13.6936 2.42383 13.2676 2.42383 12.769V11.2305C2.42383 10.7318 2.60049 10.3059 2.95383 9.95272C3.30699 9.59939 3.73291 9.42272 4.23158 9.42272H8.09708L12.5778 6.74972V17.2497L8.09708 14.5767H6.75083V18.4805H5.25083ZM11.0778 14.5845V9.41497L8.51233 10.9227H4.23158C4.15474 10.9227 4.08424 10.9548 4.02008 11.019C3.95591 11.0831 3.92383 11.1536 3.92383 11.2305V12.769C3.92383 12.8458 3.95591 12.9163 4.02008 12.9805C4.08424 13.0446 4.15474 13.0767 4.23158 13.0767H8.51233L11.0778 14.5845ZM13.9623 15.119V8.88047C14.3547 9.23564 14.6707 9.68305 14.9103 10.2227C15.1502 10.7626 15.2701 11.3549 15.2701 11.9997C15.2701 12.6446 15.1502 13.2369 14.9103 13.7767C14.6707 14.3164 14.3547 14.7638 13.9623 15.119Z'
          fill='#1C1B1F'
        />
      </g>
    </svg>
  )
}
const Memo = memo(Megaphone)
export default Memo
