import { forwardRef } from 'react'
import { Controller } from 'react-hook-form'
import AsyncCreatableSelect from 'react-select/async-creatable'
import styled from 'styled-components'
import { space, layout, compose } from 'styled-system'

import Typography from '../Typography'

import { colors } from 'configs'
import Icon from 'design-system/Icon'
import Row from 'design-system/Row'

const styles = compose(space, layout)

const Base = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: fit-content;
  width: 100%;
  ${styles}
`

const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    minHeight: '20px',
    border: `1px solid ${state.isFocused ? colors.blue[80] : colors.grey[50]}`,
    padding: '0px',
    color: colors.grey[300],
    fontSize: '14px',
    '&:hover': {
      borderColor: colors.blue[80],
    },
  }),
  option: (styles, { data }) => ({
    ...styles,
    borderRadius: '8px',
    width: 'fit-content',
    margin: '8px',
    padding: '2px 16px',
    fontSize: '12px',
    cursor: 'pointer',
    color: colors.grey[300],
    backgroundColor: data?.color || '#fff',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: '0px 8px',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '1.7px 8px',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: colors.grey[300],
  }),
}

const SelectAsyncCreate = forwardRef(
  (
    {
      label,
      maxWidth,
      keys,
      name,
      control,
      defaultValue,
      error,
      errorMessage,
      required,
      rules,
      loadOptions,
      callBack,
      ...props
    },
    ref,
  ) => {
    const handleChange = (data, field) => {
      if (field) {
        field.onChange(data)
      }
      if (callBack) {
        callBack(data)
      }
      return ''
    }

    return (
      <Base maxWidth={maxWidth}>
        {label && <Typography>{`${label} ${required ? '*' : ''}`}</Typography>}
        {control ? (
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={{ required, ...rules }}
            render={({ field }) => (
              <AsyncCreatableSelect
                ref={ref}
                loadOptions={loadOptions}
                defaultOptions
                loadingMessage={() => 'Carregando...'}
                formatCreateLabel={(inputValue) => (
                  <Row alignItems='center' gap='4px'>
                    <Icon icon='Plus' size='xs' color='secondary' />
                    <Typography textAlign='center' as='span' color='secondary' cursor='pointer'>
                      {`Criar opção "${inputValue}"`}
                    </Typography>
                  </Row>
                )}
                styles={selectStyles}
                isClearable
                {...props}
                {...field}
                onChange={(data) => handleChange(data, field)}
              />
            )}
          />
        ) : (
          <AsyncCreatableSelect
            ref={ref}
            loadOptions={loadOptions}
            defaultOptions
            cacheOptions
            loadingMessage={() => 'Carregando...'}
            formatCreateLabel={(inputValue) => (
              <Row width='100%' justifyContent='center' alignItems='center' gap='4px'>
                <Icon icon='Plus' size='xs' color='secondary' />
                <Typography
                  width='100%'
                  textAlign='center'
                  as='span'
                  color='secondary'
                  cursor='pointer'
                >
                  {`Criar opção "${inputValue}"`}
                </Typography>
              </Row>
            )}
            styles={selectStyles}
            defaultValue={defaultValue}
            isClearable
            value=''
            onChange={(data) => handleChange(data, { onChange: () => {} })}
            {...props}
          />
        )}
        {error && (
          <Typography variant='helper' color='danger'>
            {errorMessage || 'Campo obrigatório'}
          </Typography>
        )}
      </Base>
    )
  },
)

SelectAsyncCreate.defaultProps = {
  options: [],
  placeholder: 'Selecione',
  keys: { label: 'label', value: 'value' },
  callBack: null,
}

export default SelectAsyncCreate
