import React, { memo } from 'react'

const MarkChatRead = (props) => {
  return (
    <svg height='20' width='20' viewBox='0 0 20 20' {...props}>
      <path
        d='M14.0417 16.667L11.1042 13.7087L12.2708 12.542L14.0417 14.3128L17.5833 10.7712L18.75 11.9587L14.0417 16.667ZM1.25 18.3337V3.33366C1.25 2.87533 1.41319 2.48296 1.73958 2.15658C2.06597 1.83019 2.45833 1.66699 2.91667 1.66699H16.25C16.7083 1.66699 17.1007 1.83019 17.4271 2.15658C17.7535 2.48296 17.9167 2.87533 17.9167 3.33366V9.16699H16.25V3.33366H2.91667V14.2712L3.875 13.3337H9.58333V15.0003H4.58333L1.25 18.3337Z'
        fill='#656565'
      />
    </svg>
  )
}
const Memo = memo(MarkChatRead)
export default Memo
