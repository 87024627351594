import { useEffect, useState } from 'react'

import { Column, Row, Button, Icon, Card, Table, Typography } from 'design-system'
import {
  PageHeader,
  ModalConfirmation,
  ModalQuickAnswers,
  RobotHelp,
  ProtectedComponent,
} from 'components'

import { deleteQuickAnswer, showQuickAnswersList } from 'services'

import { useQuickResponsesReducer, actionTypes } from './store'
import { notify, withAuthorization } from 'helpers'

const QuickResponses = () => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(null)
  const [pagination, setPagination] = useState({
    count: 0,
    next: null,
    previus: null,
    page_size: 50,
    total_pages: 1,
  })
  const [modalConfirmation, setModalConfirmation] = useState(false)
  const [state, dispatch] = useQuickResponsesReducer()

  useEffect(() => {
    fetch()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination?.current_page])

  const fetch = async () => {
    try {
      setLoading(true)
      const { data } = await showQuickAnswersList(pagination)
      dispatch({
        type: actionTypes.LOAD_ITEMS,
        payload: data.results,
      })

      delete data.results

      setPagination({ ...data })
    } catch {
      notify.error('Não foi possível resgatar lista de respostas rápidas.')
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async (id) => {
    try {
      await deleteQuickAnswer(id)
      dispatch({
        type: actionTypes.REMOVE_ITEM,
        payload: id,
      })
      notify.success('Resposta rápida excluída com sucesso.')
      setModalConfirmation(null)
    } catch {
      notify.error('Não foi possível excluir a resposta rápida.')
    }
  }

  const columns = [
    {
      header: 'Nome',
      field: 'name',
      cellProps: {
        width: '50%',
      },
    },
    {
      header: 'Atalho',
      field: 'shortcut',
      cellProps: {
        width: '50%',
      },
    },
    {
      header: 'Mensagem',
      field: 'message',
      cellProps: {
        width: '50%',
      },
      render: ({ message }) => (
        <Typography
          maxWidth='800px'
          overflow='hidden'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          wordBreak='break-word'
        >
          {message}
        </Typography>
      ),
    },
    {
      header: 'Ações',
      render: (row) => (
        <ProtectedComponent allowedRoles={['manage_quick_answers']} unauthorizedComponent={false}>
          <Row gap='20px'>
            <Icon cursor='pointer' icon='Edit' color='primary' onClick={() => setModal(row)} />
            <Icon
              cursor='pointer'
              icon='Trash'
              color='danger'
              onClick={() =>
                setModalConfirmation({
                  type: 'danger',
                  title: `Excluir a resposta rápida ${row?.shortcut}.`,
                  message:
                    'Deseja realmente excluir essa resposta rápida? Todos os usuários do sistema perderão acesso a ela.',
                  handler: () => handleDelete(row.id),
                })
              }
            />
          </Row>
        </ProtectedComponent>
      ),
    },
  ]

  return (
    <Column p='24px'>
      <PageHeader title='Respostas rápidas'>
        <ProtectedComponent allowedRoles={['manage_quick_answers']} unauthorizedComponent={false}>
          <Row gap='8px' justifyContent='flex-end'>
            <Button variant='icon' color='primary' onClick={() => setModal(true)}>
              <Icon icon='Add' />
            </Button>
          </Row>
        </ProtectedComponent>
      </PageHeader>
      <Card mt='16px' overflow='auto'>
        <Table
          title='Respostas rápidas'
          columns={columns}
          loading={loading}
          data={state.quick_responses}
          pagination={pagination}
          setPagination={setPagination}
          emptyMessage='Você ainda não tem respostas rápidas cadastradas. Cadastre agora e tenha mensagens prontas para facilitar ainda mais o atendimento do seu cliente.'
          handleEmptyData={() => setModal(true)}
        />
      </Card>
      <RobotHelp
        maxWidth='600px'
        mt='16px'
        text={`As respostas rápidas são úteis para facilitar e agilizar a comunicação durante o atendimento a um contato. Para utilizar o atalho, basta ir até uma conversa de contato e iniciar a mensagem com a tecla '/' para abrir uma caixa com a lista de mensagens rápidas disponíveis para uso na conversa.`}
      />
      <ModalQuickAnswers
        quickResponse={modal}
        onClose={() => setModal(null)}
        dispatch={dispatch}
        actionTypes={actionTypes}
      />
      <ModalConfirmation
        open={modalConfirmation}
        onClose={() => setModalConfirmation(null)}
        {...modalConfirmation}
      />
    </Column>
  )
}

export default withAuthorization(QuickResponses, ['access_quick_answers'])
