import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Cookies from 'js-cookie'

import { Row, Form, Input, Button, Image, Typography, Checkbox } from 'design-system'

import { system } from 'configs'

import { login } from 'services'

import { useAuth, useStorage } from 'hooks'

import { chatGDSLogo1 } from 'assets'
import { useNavigate } from 'react-router-dom'
import { notify } from 'helpers'

const Login = () => {
  const { COOKIE_TOKEN } = system
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [storeCredential, setStoreCredential] = useState(false)

  const { updateLoggedUser, userActionTypes } = useAuth()
  const { setStorage, getStorage } = useStorage()

  const navigate = useNavigate()

  const hasCookie = Cookies.get(COOKIE_TOKEN)

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    const credential = getStorage('credential')

    if (credential) {
      setValue('identifier', credential)
      setStoreCredential(true)
    }

    if (hasCookie) {
      return navigate('/tickets')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (values) => {
    try {
      setLoading(true)

      if (storeCredential) {
        setStorage('credential', values.identifier)
      }

      const { data } = await login(values)

      Cookies.set(COOKIE_TOKEN, data.token)

      const permissions = data?.session?.module_actions

      updateLoggedUser({
        type: userActionTypes.ADD_ITEM,
        payload: { ...data, permissions },
      })

      if (!data.company_user) return navigate('/settings')

      navigate('/tickets')
    } catch (err) {
      notify.error(
        err?.response?.data?.statusCode === 401
          ? 'Credenciais inválidas.'
          : 'Não foi possível acessar o ChatGDS no momento.',
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <Row
      alignItems='center'
      justifyContent='center'
      height='100%'
      p='20px'
      minHeight='100vh'
      backgroundColor='rgb(99,81,172)'
      backgroundImage='linear-gradient(90deg, rgba(17,23,42,1) 0%, rgba(99,81,172,1) 52%, rgba(17,23,42,1) 95%)'
    >
      <Form
        maxWidth='380px'
        p='30px'
        border='1px solid #E8E8E8'
        borderRadius='5px'
        backgroundColor='#fff'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Image src={chatGDSLogo1} />
        <Typography variant='caption' textAlign='center'>
          Bem-vindo! insira seu email e senha para entrar no ChatGDS.
        </Typography>
        <Row mt='24px'>
          <Input
            placeholder='Digite seu e-mail'
            icon='Email'
            error={errors?.identifier}
            iconProps={{ color: 'grey.400' }}
            {...register('identifier', { required: true })}
          />
        </Row>
        <Row mt='24px'>
          <Input
            type={showPassword ? 'text' : 'password'}
            placeholder='Digite sua senha'
            icon={showPassword ? 'Eye' : 'ClosedEye'}
            error={errors?.password}
            iconProps={{
              color: 'grey.400',
              onClick: () => setShowPassword((prevState) => !prevState),
            }}
            {...register('password', { required: true })}
          />
        </Row>
        <Row mt='24px' justifyContent='space-between'>
          <Checkbox
            label='Lembre de mim'
            checked={storeCredential}
            onChange={() => setStoreCredential((prevState) => !prevState)}
          />
        </Row>
        <Button type='submit' mt='24px' disabled={loading}>
          Entrar
        </Button>
        <Button mt='24px' variant='outlined' onClick={() => navigate('/register')}>
          Registrar
        </Button>
      </Form>
    </Row>
  )
}

export default Login
