import React from 'react'

import Row from '../Row'
import Button from '../Button'
import Icon from '../Icon'
import { colors } from 'configs'

const Pagination = ({ pagination, setPagination }) => {
  const renderPagination = () => {
    const { page, total_pages } = pagination
    const pagesToShow = 3

    if (total_pages <= pagesToShow) {
      return Array.from({ length: total_pages }, (_, index) => (
        <Button
          key={index + 1}
          variant='pagination'
          bg={page === index + 1 ? colors.purple[100] : '#fff'}
          color={page === index + 1 ? '#fff' : colors.purple[100]}
          onClick={() =>
            setPagination((prevState) => ({
              ...prevState,
              page: index + 1,
            }))
          }
        >
          {index + 1}
        </Button>
      ))
    } else {
      const visiblePages = []
      if (page <= Math.ceil(pagesToShow / 2)) {
        for (let i = 1; i <= pagesToShow - 1; i++) {
          visiblePages.push(
            <Button
              key={i}
              variant='pagination'
              bg={page === i ? colors.purple[100] : '#fff'}
              color={page === i ? '#fff' : colors.purple[100]}
              onClick={() =>
                setPagination((prevState) => ({
                  ...prevState,
                  page: i,
                }))
              }
            >
              {i}
            </Button>,
          )
        }
        visiblePages.push(
          <Button key={total_pages} bg='#fff' variant='pagination'>
            ...
          </Button>,
        )
      } else if (page > total_pages - Math.floor(pagesToShow / 2)) {
        visiblePages.push(
          <Button key={1} bg='#fff' variant='pagination'>
            ...
          </Button>,
        )
        for (let i = total_pages - (pagesToShow - 2); i <= total_pages; i++) {
          visiblePages.push(
            <Button
              key={i}
              variant='pagination'
              bg={page === i ? colors.purple[100] : '#fff'}
              color={page === i ? '#fff' : colors.purple[100]}
              onClick={() =>
                setPagination((prevState) => ({
                  ...prevState,
                  page: i,
                }))
              }
            >
              {i}
            </Button>,
          )
        }
      } else {
        visiblePages.push(
          <Button key={1} bg='#fff' variant='pagination'>
            ...
          </Button>,
        )
        for (
          let i = page - Math.floor(pagesToShow / 2);
          i <= page + Math.floor(pagesToShow / 2);
          i++
        ) {
          visiblePages.push(
            <Button
              key={i}
              variant='pagination'
              bg={page === i ? colors.purple[100] : '#fff'}
              color={page === i ? '#fff' : colors.purple[100]}
              onClick={() =>
                setPagination((prevState) => ({
                  ...prevState,
                  page: i,
                }))
              }
            >
              {i}
            </Button>,
          )
        }
        visiblePages.push(
          <Button key={total_pages} bg='#fff' variant='pagination'>
            ...
          </Button>,
        )
      }

      return visiblePages
    }
  }

  return (
    <Row mt='24px' width='100%' alignItems='center' justifyContent='flex-end' gap='8px'>
      {pagination.page > 1 && (
        <Button
          variant='pagination'
          onClick={() =>
            setPagination((prevState) => ({
              ...prevState,
              page: pagination.page - 1,
            }))
          }
        >
          <Icon icon='Arrow' direction='left' />
        </Button>
      )}
      {renderPagination()}
      {pagination.page < pagination.total_pages && (
        <Button
          variant='pagination'
          onClick={() =>
            setPagination((prevState) => ({
              ...prevState,
              page: pagination.page + 1,
            }))
          }
        >
          <Icon icon='Arrow' direction='right' color='grey.300' />
        </Button>
      )}
    </Row>
  )
}

export default Pagination
