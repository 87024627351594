import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  Row,
  Button,
  Typography,
  ColorPicker,
  Column,
  Checkbox,
} from 'design-system'

import { createRole, showRoleInfo, showRolesPermissions, updatedRole } from 'services'
import { defaultColorsTags, modulePermissionsLabelDict } from 'configs'
import { formatPermissions, notify } from 'helpers'

const ModalRoles = ({ onClose, role, dispatch, actionTypes, ...props }) => {
  const [loading, setLoading] = useState(false)
  const [currentColor, setCurrentColor] = useState('')
  const [permissions, setPermissions] = useState({})

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm()

  const randomColor = defaultColorsTags[0]

  useEffect(() => {
    if (role?.id) {
      handleGetRoleInfo()
    }

    role && handleGetPermissions()

    reset({
      name: '',
      color: randomColor,
      module_actions: [],
    })

    return () => {
      setCurrentColor(randomColor)
      setPermissions({})
      reset({
        name: '',
        color: randomColor,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role])

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      values.module_actions = Object.values(values.module_actions)
        .flat()
        .filter((l) => l.selected)
        .map((l) => l.id)

      const { data } = role?.id ? await updatedRole(role?.id, values) : await createRole(values)

      dispatch({
        type: actionTypes.UPDATE_ITEM,
        payload: data,
        tab: 'roles',
      })
      notify.success(role?.id ? 'Cargo salvo com sucesso.' : 'Novo cargo criado com sucesso.')
      onClose()
    } catch {
      notify.error('Não foi possível salvar configuração de cargo.')
    } finally {
      setLoading(false)
    }
  }

  const handleGetPermissions = async () => {
    try {
      const { data } = await showRolesPermissions()

      const permissions = formatPermissions(data.results)

      setPermissions(permissions)
    } catch {
      notify.error('Não foi possível listar permissões.')
    }
  }

  const handleGetRoleInfo = async () => {
    try {
      setLoading(true)
      const { data } = await showRoleInfo(role?.id)

      setCurrentColor(data.color)

      data.module_actions = formatPermissions(data.module_actions)

      reset(data)
    } catch {
      notify.error('Não foi possível buscar dados do cargo.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal size='sm' open={role} onClose={onClose} {...props}>
      <ModalHeader onClose={onClose}>
        <Typography variant='title' color='primary'>
          {role?.id ? 'Editar cargo' : 'Novo cargo'}
        </Typography>
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Column gap='16px'>
            <Row width='100%'>
              <Input
                label='Título'
                placeholder='Digite'
                error={errors?.name}
                disabled={loading}
                {...register('name', { required: true })}
              />
            </Row>
            <Row width='100%'>
              <ColorPicker
                name='color'
                defaultValue={currentColor}
                onChange={setValue}
                colorsList={defaultColorsTags}
              />
            </Row>
          </Column>
          <Row mt='16px'>
            <Typography>Permissões</Typography>
          </Row>
          <Row
            mt='16px'
            p='10px'
            gap='16px'
            border='1px solid #e6e6e6'
            flexWrap='wrap'
            height='300px'
            overflow='auto'
          >
            {Object.keys(permissions).map((module, idx) => (
              <Column width='100%' key={`${module.name}.${idx}`} gap='8px'>
                <Typography fontSize='12px' fontWeight='bold' color='primary' whiteSpace='nowrap'>
                  {modulePermissionsLabelDict[module]}
                </Typography>
                {permissions[module].map((permission, idx) => (
                  <div key={`${module}_${idx}`}>
                    <input
                      hidden
                      value={permission.id}
                      {...register(`module_actions.${module}.${idx}.id`)}
                    />
                    <input
                      hidden
                      value={permission.key}
                      {...register(`module_actions.${module}.${idx}.key`)}
                    />
                    <input
                      hidden
                      value={permission.label}
                      {...register(`module_actions.${module}.${idx}.label`)}
                    />
                    <Checkbox
                      label={permission.label}
                      {...register(`module_actions.${module}.${idx}.selected`)}
                    />
                  </div>
                ))}
              </Column>
            ))}
          </Row>
          <Row mt='16px' justifyContent='flex-end' gap='12px'>
            <Button variant='outlined' onClick={onClose}>
              Cancelar
            </Button>
            <Button type='submit' disabled={loading}>
              Salvar
            </Button>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default ModalRoles
