import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  Row,
  Card,
  Input,
  Form,
  Button,
  Typography,
  InputMasked,
  Table,
  Badge,
  Icon,
  Column,
} from 'design-system'
import { ModalConfirmation } from 'components'

import { useAuth } from 'hooks'
import { notify, hexToRGBA } from 'helpers'
import { updateMe } from 'services'
import { inviteStatusDict } from 'configs'
import { showUserInvites, acceptUserInvite } from 'services'
import { useSettingsReducer, actionTypes } from './store'
import { format } from 'date-fns'

const UserPreferences = () => {
  const [loading, setLoading] = useState(false)
  const { userData, updateLoggedUser, userActionTypes } = useAuth()
  const [modalConfirmation, setModalConfirmation] = useState(false)
  const [pagination, setPagination] = useState({
    count: 0,
    next: null,
    previus: null,
    page_size: 50,
    total_pages: 1,
  })

  const [state, dispatch] = useSettingsReducer()

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm()

  // const { settings } = userData.user

  useEffect(() => {
    const { email, name, phone } = userData.user

    getUserInvites()

    reset({ email, name, phone })

    return () => reset({})

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, reset])

  const onSubmit = async (values) => {
    try {
      setLoading(true)

      const { data } = await updateMe(values)

      updateLoggedUser({
        type: userActionTypes.UPDATE_ITEM,
        payload: { ...userData, user: { ...data.user, ...userData.user.settings } },
      })
      notify.success('Preferências salvas com sucesso!')
    } catch {
      notify.error('Não foi possível salvar preferências no momento.')
    } finally {
      setLoading(false)
    }
  }

  // const handlerChangeSettings = async (target) => {
  //   try {
  //     setLoading(true)
  //     const {
  //       data: {
  //         user: { settings },
  //       },
  //     } = await updateMe({
  //       settings: { [target.name]: target.checked },
  //     })

  //     updateLoggedUser({
  //       type: userActionTypes.UPDATE_ITEM,
  //       payload: { ...userData, user: { ...userData.user, settings } },
  //     })

  //     notify.success('Preferências salvas com sucesso!')
  //   } catch {
  //     notify.error('Não foi possível salvar configuração.')
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  const getUserInvites = async () => {
    try {
      setLoading(true)

      const { data } = await showUserInvites()

      dispatch({
        type: actionTypes.LOAD_ITEMS,
        payload: data?.results,
        tab: 'user_invites',
        filter: 'email',
      })
    } catch {
      notify.error('Não foi possível resgatar lista de convites.')
    } finally {
      setLoading(false)
    }
  }

  const handleUpdateInviteStatus = async (values, status) => {
    try {
      setLoading(true)

      const { data } = await acceptUserInvite(values?.id, status)

      dispatch({
        type: actionTypes.UPDATE_ITEM,
        payload: data,
        tab: 'user_invites',
        filter: 'email',
      })
      setModalConfirmation(null)
      notify.success('Convite atualizado com sucesso.')
    } catch {
      notify.error('Não foi possível atualizar o convite no momento.')
    } finally {
      setLoading(false)
    }
  }

  const columns = [
    {
      header: 'Empresa',
      render: ({ company: { name } }) => <Typography>{name}</Typography>,
    },
    {
      header: 'Status',
      render: ({ status }) => (
        <Row gap='8px'>
          <Badge
            width='90px'
            backgroundColor={hexToRGBA(inviteStatusDict[status].color, 0.2)}
            color={inviteStatusDict[status].color}
          >
            {inviteStatusDict[status].label}
          </Badge>
        </Row>
      ),
    },
    {
      header: 'Data de expiração',
      render: ({ expiration_date }) => (
        <Typography>{format(expiration_date, 'dd/MM/yyyy')}</Typography>
      ),
    },
    {
      header: 'Ações',
      render: (row) =>
        row.status === 'pending' && (
          <Row gap='20px' width='100%'>
            <Icon
              cursor='pointer'
              icon='Check'
              color='success'
              onClick={() =>
                setModalConfirmation({
                  type: 'primary',
                  title: `Aceitar convite de ${row?.company?.name}.`,
                  message:
                    'Você realmente deseja aceitar o convite da empresa? Ao aceitá-lo, você estará vinculado a ela e poderá começar a acessar as informações disponíveis de acordo com seu cargo.',
                  handler: () =>
                    handleUpdateInviteStatus(row, {
                      status: 'accepted',
                    }),
                })
              }
            />
            <Icon
              cursor='pointer'
              icon='Close'
              color='danger'
              onClick={() =>
                setModalConfirmation({
                  type: 'danger',
                  title: `Rejeitar convite de ${row?.company?.name}.`,
                  message:
                    'Você realmente deseja rejeitar o convite da empresa? Caso o rejeite, não será possível aceitá-lo posteriormente, e a empresa não poderá enviar novos convites para o seu e-mail.',
                  handler: () =>
                    handleUpdateInviteStatus(row, {
                      status: 'declined',
                    }),
                })
              }
            />
          </Row>
        ),
    },
  ]

  return (
    <Column gap='16px'>
      <Card>
        <Typography mt='16px' variant='caption' fontWeight='600'>
          Configurar informações básicas do usuário
        </Typography>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row mt='16px'>
            <Input label='E-mail' error={errors?.email} {...register('email')} disabled readOnly />
          </Row>
          <Row mt='16px'>
            <Input
              label='Nome'
              error={errors?.name}
              disabled={loading}
              {...register('name', { required: true })}
            />
          </Row>
          <Row mt='16px'>
            <InputMasked
              label='Telefone'
              name='phone'
              mask='(11) 11111-1111'
              control={control}
              disabled={loading}
            />
          </Row>
          <Row maxWidth={['100%', '100%', '280px']} mt='16px'>
            <Button type='submit' disabled={loading}>
              Salvar
            </Button>
          </Row>
        </Form>
      </Card>
      {/* <Card>
        <Typography mt='16px' variant='caption' fontWeight='600'>
          Configurar personalizações do sistema
        </Typography>
        <SettingSwitch
          name='dark_mode'
          checked={settings?.dark_mode}
          onChange={(e) => handlerChangeSettings(e.target)}
          label='Tema escuro'
          description='Você pode alternar entro o tema claro e escuro da plataforma.'
        />
      </Card> */}
      <Card>
        <Typography mt='16px' variant='caption' fontWeight='600'>
          Lista de convites
        </Typography>
        <Table
          mt='24px'
          title='usuário'
          columns={columns}
          data={state.user_invites}
          pagination={pagination}
          setPagination={setPagination}
          emptyMessage='Parece que você não tem nenhum convite ainda. Caso tenha sido convidado para alguma empresa, verifique seu e-mail ou entre em contato com a empresa.'
        />
      </Card>
      <ModalConfirmation
        open={modalConfirmation}
        onClose={() => setModalConfirmation(null)}
        {...modalConfirmation}
      />
    </Column>
  )
}

export default UserPreferences
