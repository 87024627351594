import { memo } from 'react'

const ClosedEye = (props) => {
  return (
    <svg height='20' width='20' viewBox='0 0 20 20' {...props}>
      <path
        d='M10.0002 4.97884C13.1585 4.97884 15.9752 6.75384 17.3502 9.56217C16.8585 10.5788 16.1668 11.4538 15.3418 12.1622L16.5168 13.3372C17.6752 12.3122 18.5918 11.0288 19.1668 9.56217C17.7252 5.90384 14.1668 3.31217 10.0002 3.31217C8.94183 3.31217 7.92516 3.47884 6.96683 3.78717L8.34183 5.16217C8.8835 5.05384 9.4335 4.97884 10.0002 4.97884ZM9.1085 5.92884L10.8335 7.65384C11.3085 7.86217 11.6918 8.24551 11.9002 8.72051L13.6252 10.4455C13.6918 10.1622 13.7418 9.86217 13.7418 9.55384C13.7502 7.48717 12.0668 5.81217 10.0002 5.81217C9.69183 5.81217 9.40016 5.85384 9.1085 5.92884ZM1.67516 3.20384L3.9085 5.43717C2.55016 6.50384 1.47516 7.92051 0.833496 9.56217C2.27516 13.2205 5.8335 15.8122 10.0002 15.8122C11.2668 15.8122 12.4835 15.5705 13.6002 15.1288L16.4502 17.9788L17.6252 16.8038L2.85016 2.02051L1.67516 3.20384ZM7.92516 9.45384L10.1002 11.6288C10.0668 11.6372 10.0335 11.6455 10.0002 11.6455C8.85016 11.6455 7.91683 10.7122 7.91683 9.56217C7.91683 9.52051 7.92516 9.49551 7.92516 9.45384ZM5.09183 6.62051L6.55016 8.07884C6.3585 8.53717 6.25016 9.03717 6.25016 9.56217C6.25016 11.6288 7.9335 13.3122 10.0002 13.3122C10.5252 13.3122 11.0252 13.2038 11.4752 13.0122L12.2918 13.8288C11.5585 14.0288 10.7918 14.1455 10.0002 14.1455C6.84183 14.1455 4.02516 12.3705 2.65016 9.56217C3.2335 8.37051 4.0835 7.38717 5.09183 6.62051Z'
        fill='#656565'
      />
    </svg>
  )
}
const Memo = memo(ClosedEye)
export default Memo
