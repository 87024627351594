import { clientApi } from 'providers'

export const showCompaniesList = (params) => clientApi.get(`/companies/`, { params })

export const updateCompanyInfo = (id, data) => clientApi.put(`/companies/${id}/`, data)

export const updateCompanyInvite = (id, data) =>
  clientApi.put(`/companies/user-invites/${id}/`, data)

export const createCompanyInvite = (data) => clientApi.post('/companies/user-invites/', data)

export const showCompanyInviteList = () => clientApi.get('/companies/user-invites/')

export const generateNewCompanyUuid = (id) => clientApi.post(`/companies/${id}/generate-new-uuid/`)
