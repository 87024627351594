const navigation = [
  {
    title: 'Conversas',
    icon: 'Whatsapp',
    defaultRoute: '/tickets',
    feat: false,
    routes: [],
  },
  {
    title: 'Contatos',
    icon: 'ContactCard',
    defaultRoute: '/contacts',
    feat: false,
    routes: [],
  },
  {
    title: 'Departamentos',
    icon: 'Connection',
    defaultRoute: '/departments',
    feat: false,
    routes: [],
  },
  {
    title: 'Respostas rápidas',
    icon: 'Message',
    defaultRoute: '/quick-responses',
    feat: false,
    routes: [],
  },
  {
    title: 'Etiquetas',
    icon: 'Tag',
    defaultRoute: '/tags',
    feat: false,
    routes: [],
  },
  {
    title: 'Templates',
    icon: 'Chat',
    defaultRoute: '/templates',
    feat: false,
    routes: [],
  },
  {
    title: 'Configurações',
    icon: 'Cogs',
    defaultRoute: '/settings',
    feat: false,
    routes: [],
  },
]

export default navigation
