import styled from 'styled-components'

import Row from '../Row'

const ListItem = styled(Row)`
  width: 100%;
  color: #656565;
  font-size: 16px;
  transition: all 0.5s ease;
  padding: 8px;
  gap: 8px;
  cursor: pointer;
  background: ${({ active }) => (active ? '#edf3fc' : 'transparent')};

  & * {
    cursor: pointer;
  }

  &:hover {
    background: #edf3fc;
  }
`

export default ListItem
