import { Row, Icon, Typography, Button } from 'design-system'

const CloseTicketHeader = ({ setOpenCloseOption, handlerUpdateTicket, tickedId, loading }) => {
  return (
    <>
      <Row
        background='rgba(13, 13, 13, 0.25)'
        width='100%'
        height='100vh'
        position='absolute'
        zIndex='1'
      />
      <Row
        position='absolute'
        background='#EDEDED'
        borderRadius='0 0 8px 8px'
        zIndex='10'
        p='15px'
        width='100%'
        justifyContent='space-between'
      >
        <Row width='100%' gap='8px' alignItems='center'>
          <Icon icon='Information' color='#474747' />
          <Typography color='#474747'>Tem certeza que deseja encerrar essa conversa?</Typography>
        </Row>
        <Row gap='16px'>
          <Button
            maxWidth='100px'
            variant='text'
            color='grey.500'
            onClick={() => setOpenCloseOption(false)}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            maxWidth='240px'
            color='#C52525'
            onClick={() => handlerUpdateTicket(tickedId, { status: 'closed' })}
            disabled={loading}
          >
            Encerrar
          </Button>
        </Row>
      </Row>
    </>
  )
}

export default CloseTicketHeader
