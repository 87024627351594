import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { space, layout, compose } from 'styled-system'

import Typography from '../Typography'

const styles = compose(space, layout)

const Base = styled.label`
  position: relative;
  display: flex;
  cursor: pointer;
  ${styles}
`

const SwitchInput = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: #fff;
  }

  &:checked + span:before {
    left: 0;
    transform: translateX(calc(100% - 2px));
  }

  &:checked + span:before {
    background-color: #fff;
  }

  &:checked + span {
    background-color: ${({ theme: { colors }, color, disabled }) =>
      disabled ? colors.grey[50] : colors[color]};
  }
`

const SwitchSlider = styled.span`
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
  background-color: #e5e5e5;
  border-radius: 15px;
  transition: background-color 0.3s;

  &:before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    bottom: 2px;
    left: 2px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
  }
`

const Switch = forwardRef(({ label, color, ...props }, ref) => (
  <Base>
    {label && <Typography fontSize='14px'>{label}</Typography>}
    <SwitchInput ref={ref} color={color} {...props} />
    <SwitchSlider />
  </Base>
))

Switch.defaultProps = {
  label: null,
  color: 'primary',
}

export default Switch
