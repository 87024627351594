import { useReducer } from 'react'

export const initialValues = {
  user: {},
}

export const actionTypes = {
  ADD_ITEM: 'ADD_ITEM',
  UPDATE_ITEM: 'UPDATE_ITEM',
  RESET: 'RESET',
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.ADD_ITEM:
      return {
        ...state,
        user: action.payload,
      }
    case actionTypes.UPDATE_ITEM:
      return {
        ...state,
        user: action.payload,
      }

    case actionTypes.RESET:
      return {}
    default:
      return state
  }
}

export const useUserReduce = () => useReducer(reducer, initialValues)
