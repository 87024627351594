import React, { memo } from 'react'

const CheckCircle = (props) => {
  return (
    <svg height='20' width='20' viewBox='0 0 20 20' {...props}>
      <path
        d='M8.74 14.14L15.085 7.795L13.825 6.535L8.74 11.62L6.175 9.055L4.915 10.315L8.74 14.14ZM10 19C8.755 19 7.585 18.7638 6.49 18.2913C5.395 17.8188 4.4425 17.1775 3.6325 16.3675C2.8225 15.5575 2.18125 14.605 1.70875 13.51C1.23625 12.415 1 11.245 1 10C1 8.755 1.23625 7.585 1.70875 6.49C2.18125 5.395 2.8225 4.4425 3.6325 3.6325C4.4425 2.8225 5.395 2.18125 6.49 1.70875C7.585 1.23625 8.755 1 10 1C11.245 1 12.415 1.23625 13.51 1.70875C14.605 2.18125 15.5575 2.8225 16.3675 3.6325C17.1775 4.4425 17.8188 5.395 18.2913 6.49C18.7638 7.585 19 8.755 19 10C19 11.245 18.7638 12.415 18.2913 13.51C17.8188 14.605 17.1775 15.5575 16.3675 16.3675C15.5575 17.1775 14.605 17.8188 13.51 18.2913C12.415 18.7638 11.245 19 10 19ZM10 17.2C12.01 17.2 13.7125 16.5025 15.1075 15.1075C16.5025 13.7125 17.2 12.01 17.2 10C17.2 7.99 16.5025 6.2875 15.1075 4.8925C13.7125 3.4975 12.01 2.8 10 2.8C7.99 2.8 6.2875 3.4975 4.8925 4.8925C3.4975 6.2875 2.8 7.99 2.8 10C2.8 12.01 3.4975 13.7125 4.8925 15.1075C6.2875 16.5025 7.99 17.2 10 17.2Z'
        fill='#656565'
      />
    </svg>
  )
}
const Memo = memo(CheckCircle)
export default Memo
