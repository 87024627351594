import { memo } from 'react'

const ExpandArrow = (props) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <path d='M9.5 4.5V6.5H16.09L4.5 18.09L5.91 19.5L17.5 7.91V14.5H19.5V4.5H9.5Z' fill='black' />
    </svg>
  )
}
const Memo = memo(ExpandArrow)
export default Memo
