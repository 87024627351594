import { compareDates } from 'helpers'
import { useReducer } from 'react'

export const initialValues = {
  tickets_list: [],
  messages: [],
  selected_ticket: {},
  current_status: 'attending',
}

export const actionCurrentStatusTypes = {
  SELECT_STATUS: 'SELECT_STATUS',
}

export const actionTicketTypes = {
  LOAD_TICKETS_LIST: 'LOAD_TICKETS_LIST',
  ADD_TICKET: 'ADD_TICKET',
  UPDATE_TICKET: 'UPDATE_TICKET',
  REMOVE_TICKET: 'REMOVE_TICKET',
}

export const actionMessagesTypes = {
  LOAD_MESSAGES: 'LOAD_MESSAGES',
  ADD_MESSAGE: 'ADD_MESSAGE',
  REMOVE_MESSAGES: 'REMOVE_MESSAGES',
  UPDATE_MESSAGES: 'UPDATE_MESSAGES',
  RESET_MESSAGES: 'RESET_MESSAGES',
}

export const actionSelectedTicketTypes = {
  LOAD_SELECTED_TICKET: 'LOAD_SELECTED_TICKET',
  UPDATE_SELECTED_TICKET: 'UPDATE_SELECTED_TICKET',
}

const reducer = (state, action) => {
  const key = action?.payload?.key
  const removeTicketFromList = state?.tickets_list?.filter(
    (ticket) => ticket?.id !== action?.payload?.id,
  )

  switch (action.type) {
    case actionCurrentStatusTypes.SELECT_STATUS:
      return {
        ...state,
        current_status: action.payload,
      }
    case actionTicketTypes.LOAD_TICKETS_LIST:
      return {
        ...state,
        tickets_list: action.payload.sort((itemA, itemB) =>
          compareDates(itemA?.last_message?.created_at, itemB?.last_message?.created_at),
        ),
      }
    case actionTicketTypes.ADD_TICKET:
      return {
        ...state,
        tickets_list: [...state.tickets_list, action.payload].sort((itemA, itemB) =>
          compareDates(itemA?.last_message?.created_at, itemB?.last_message?.created_at),
        ),
      }
    case actionTicketTypes.UPDATE_TICKET:
      return {
        ...state,
        tickets_list: [...removeTicketFromList, action.payload].sort((itemA, itemB) =>
          compareDates(itemA?.last_message?.created_at, itemB?.last_message?.created_at),
        ),
      }
    case actionTicketTypes.REMOVE_TICKET:
      return {
        ...state,
        tickets_list: removeTicketFromList,
      }
    case actionMessagesTypes.LOAD_MESSAGES:
      return { ...state, messages: action.payload }
    case actionMessagesTypes.ADD_MESSAGE:
      const updatedMessagesGroup = [action.payload, ...(state.messages[key] || [])]
      return {
        ...state,
        messages: {
          ...state.messages,
          [key]: updatedMessagesGroup,
        },
      }
    case actionMessagesTypes.REMOVE_MESSAGES:
      return {
        ...state,
        messages: state.messages?.filter((message) => message.id !== action.payload),
      }
    case actionMessagesTypes.UPDATE_MESSAGES:
      const updatedMessages = state.messages[key].find((m) => m?.id === action?.payload?.id)

      const status =
        updatedMessages.status.value > action?.payload?.status?.value
          ? updatedMessages.status
          : action?.payload?.status

      updatedMessages.status = status

      return {
        ...state,
        messages: state.messages,
      }
    case actionMessagesTypes.RESET_MESSAGES:
      return {
        ...state,
        messages: [],
      }
    case actionSelectedTicketTypes.LOAD_SELECTED_TICKET:
      return { ...state, selected_ticket: action.payload }
    case actionSelectedTicketTypes.UPDATE_SELECTED_TICKET:
      return {
        ...state,
        selected_ticket: { ...state.selected_ticket, ...action.payload },
      }
    default:
      return state
  }
}

export const useTicketReducer = () => useReducer(reducer, initialValues)
