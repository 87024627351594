import { memo } from 'react'

const Clip = (props) => {
  return (
    <svg height='20' width='20' viewBox='0 0 20 20' {...props}>
      <path
        d='M13.3334 4.99967V14.583C13.3334 16.4247 11.8417 17.9163 10.0001 17.9163C8.15841 17.9163 6.66675 16.4247 6.66675 14.583V4.16634C6.66675 3.01634 7.60008 2.08301 8.75008 2.08301C9.90008 2.08301 10.8334 3.01634 10.8334 4.16634V12.9163C10.8334 13.3747 10.4584 13.7497 10.0001 13.7497C9.54175 13.7497 9.16675 13.3747 9.16675 12.9163V4.99967H7.91675V12.9163C7.91675 14.0663 8.85008 14.9997 10.0001 14.9997C11.1501 14.9997 12.0834 14.0663 12.0834 12.9163V4.16634C12.0834 2.32467 10.5917 0.833008 8.75008 0.833008C6.90841 0.833008 5.41675 2.32467 5.41675 4.16634V14.583C5.41675 17.1163 7.46675 19.1663 10.0001 19.1663C12.5334 19.1663 14.5834 17.1163 14.5834 14.583V4.99967H13.3334Z'
        fill='#656565'
      />
    </svg>
  )
}
const Memo = memo(Clip)
export default Memo
