import { memo, forwardRef } from 'react'

const Plus = forwardRef((props, ref) => {
  return (
    <svg ref={ref} width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <path d='M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z' fill='black' />
    </svg>
  )
})
const Memo = memo(Plus)
export default Memo
