import { platformTypeDict } from 'configs'
import { Avatar, Column, Typography, Button, Icon, Row } from 'design-system'

const NotificationTicketCard = ({ contact, unread_messages }) => {
  return (
    <Column className='cursor-pointer'>
      <Avatar size='md' src={contact?.picture} square badge={platformTypeDict['whatsapp']?.logo} />
      <Typography mt='8px' fontSize='16px' fontWeight='bold'>
        {contact?.name}
      </Typography>
      <Row alignItems='center' gap='4px'>
        <Icon icon='Message' />
        <Typography>{`${unread_messages} mensagens não lidas`}</Typography>
      </Row>
      <Column width='100%' alignItems='flex-end'>
        <Button maxWidth='100px' variant='text'>
          Visualizar
        </Button>
      </Column>
    </Column>
  )
}

export default NotificationTicketCard
