import { lastMessageIconDict, messageStatusDict } from 'configs'
import { Icon, Row, Typography } from 'design-system'

const ignoreSender = ['system', 'contact', 'internal']

const LastMessageText = ({ last_message }, senderContact) => (
  <Row alignItems='center' gap='4px'>
    {(!ignoreSender.includes(last_message?.sender_type) || last_message?.sent_through_whatsapp) && (
      <Icon
        size='14'
        icon={messageStatusDict[last_message?.status?.value]?.icon}
        color={messageStatusDict[last_message?.status?.value]?.color || 'grey.300'}
      />
    )}
    <Typography fontSize='14px' fontWeight={senderContact ? '600' : '400'} width='200px' ellipsis>
      {last_message?.body?.text}
    </Typography>
  </Row>
)

const LastMessageTemplate = ({ last_message }, senderContact) => (
  <Row alignItems='center' gap='4px'>
    <Typography fontWeight={senderContact ? '600' : '400'} fontSize='14px' width='200px' ellipsis>
      {last_message?.body?.text}
    </Typography>
  </Row>
)

const LastMessageFile = ({ last_message }, senderContact) => (
  <Row alignItems='center' gap='2px'>
    <Icon
      size='14'
      color='grey.300'
      icon={lastMessageIconDict[last_message?.files[0]?.file_category?.value || 'File']}
    />
    <Typography fontWeight={senderContact ? '600' : '400'} fontSize='14px' width='200px' ellipsis>
      {last_message?.files[0]?.caption || last_message?.files[0]?.file_type?.label}
    </Typography>
  </Row>
)

const LastMessageList = ({ last_message }, senderContact) => (
  <Row alignItems='center' gap='4px'>
    <Icon icon='Megaphone' size='14' color='grey.300' />
    <Typography fontWeight={senderContact ? '600' : '400'} fontSize='14px' width='200px' ellipsis>
      {last_message?.body?.text}
    </Typography>
  </Row>
)

const LastMessageLocation = ({ last_message }, senderContact) => (
  <Row alignItems='center' gap='4px'>
    <Icon icon='ContactCard' size='14' color='grey.300' />
    <Typography fontWeight={senderContact ? '600' : '400'} fontSize='14px' width='200px' ellipsis>
      {last_message?.body?.text}
    </Typography>
  </Row>
)

const LastMessageContact = ({ last_message }, senderContact) => (
  <Row alignItems='center' gap='4px'>
    <Icon icon='ContactCard' size='14' color='grey.300' />
    <Typography fontWeight={senderContact ? '600' : '400'} fontSize='14px' width='200px' ellipsis>
      {last_message?.body?.text}
    </Typography>
  </Row>
)

const lastMessageDict = {
  text: LastMessageText,
  template: LastMessageTemplate,
  file: LastMessageFile,
  list: LastMessageList,
  location: LastMessageLocation,
  contact: LastMessageContact,
}

export default lastMessageDict
