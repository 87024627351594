import { useState } from 'react'
import styled from 'styled-components'

import { Popover, Column, Button, Typography } from 'design-system'
import { hexToRGBA } from 'helpers'
import { useClickout } from 'hooks'
import { templateButtonLimit } from 'configs'

const TemplateButtonPopover = ({ fields, append, ...props }) => {
  const [open, setOpen] = useState(false)

  const { elementRef } = useClickout(open, setOpen)

  const defaultObject = { phone: null, suffix: null, text: '', url: null }

  const handleAddButton = (type) => {
    append({ ...defaultObject, ...type })
    setOpen(false)
  }

  const inputsLimit = fields.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.button_type]: acc[cur.button_type] ? acc[cur.button_type] + 1 : 1,
    }),
    {},
  )

  return (
    <Column position='relative' maxWidth='180px' {...props}>
      <Button
        maxWidth='180px'
        width='180px'
        onClick={() => setOpen(!open)}
        disabled={fields?.length >= 10}
      >
        Adicionar botão de ação
      </Button>
      {fields?.length >= 10 && (
        <Typography color='danger' variant='caption'>
          Você pode adicionar apenas 10 botões de ação por template.
        </Typography>
      )}
      <Popover ref={elementRef} top='-180px' open={open} onClose={() => setOpen(false)}>
        <Column>
          <Option
            disabled={inputsLimit.QUICK_REPLY >= templateButtonLimit.QUICK_REPLY}
            onClick={() =>
              inputsLimit.QUICK_REPLY >= templateButtonLimit.QUICK_REPLY
                ? undefined
                : handleAddButton({ button_type: 'QUICK_REPLY' })
            }
          >
            Resposta rápida
          </Option>
          <Option
            disabled={inputsLimit.URL >= templateButtonLimit.URL}
            onClick={() =>
              inputsLimit.URL >= templateButtonLimit.URL
                ? undefined
                : handleAddButton({ button_type: 'URL' })
            }
          >
            URL
          </Option>
          <Option
            disabled={inputsLimit.PHONE_NUMBER >= templateButtonLimit.PHONE_NUMBER}
            onClick={() =>
              inputsLimit.PHONE_NUMBER >= templateButtonLimit.PHONE_NUMBER
                ? undefined
                : handleAddButton({ button_type: 'PHONE_NUMBER' })
            }
          >
            Número de telefone
          </Option>
          <Option
            disabled={inputsLimit.COPY_CODE >= templateButtonLimit.COPY_CODE}
            onClick={() =>
              inputsLimit.COPY_CODE >= templateButtonLimit.COPY_CODE
                ? undefined
                : handleAddButton({ button_type: 'COPY_CODE' })
            }
          >
            Copiar código de oferta
          </Option>
        </Column>
      </Popover>
    </Column>
  )
}
const Option = styled.div`
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  font-weight: 600;
  color: ${({ theme, disabled }) => (disabled ? theme.colors.text : theme.colors.primary)};

  &:hover {
    background-color: ${({ theme, disabled }) =>
      hexToRGBA(disabled ? theme.colors.text : theme.colors.primary, 0.3)};
  }
`

export default TemplateButtonPopover
