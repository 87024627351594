import colors from './colors'

const breakpoints = ['40em', '62em', '75em', '80em']
const responsiveConfigs = {
  mobileWidth: 640,
  tabletWidth: 992,
  desktopWidth: 1200,
}
const space = [4, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80]
const fontSizes = [12, 14, 16, 18, 22, 24, 32, 40, 48, 56, 64, 72, 80]
const theme = {
  colors,
  breakpoints,
  responsiveConfigs,
  space,
  fontSizes,
}

// DEFAULT COLORS
theme.colors.primary = colors.purple[100]
theme.colors.secondary = colors.blue[80]
theme.colors.danger = colors.red[400]
theme.colors.success = colors.green[300]
theme.colors.warning = colors.orange[400]
theme.colors.text = colors.grey[300]
theme.colors.default = colors.grey[50]

// RESPONSIVE CUSTOM BREAKPOINTS
theme.breakpoints.mobile = theme.breakpoints[1]
theme.breakpoints.tablet = theme.breakpoints[2]
theme.breakpoints.desktop = theme.breakpoints[3]

export default theme
