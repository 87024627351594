
      import React, { memo } from 'react';

      const Timer = (props) => {
        return (
          <svg height="20" width="20"  viewBox="0 0 20 20" {...props}>
	<path d="M7.33333 2.71429V1H12.6667V2.71429H7.33333ZM9.11111 12.1429H10.8889V7H9.11111V12.1429ZM10 19C8.9037 19 7.87037 18.7964 6.9 18.3893C5.92963 17.9821 5.08148 17.4286 4.35556 16.7286C3.62963 16.0286 3.05556 15.2107 2.63333 14.275C2.21111 13.3393 2 12.3429 2 11.2857C2 10.2286 2.21111 9.23214 2.63333 8.29643C3.05556 7.36071 3.62963 6.54286 4.35556 5.84286C5.08148 5.14286 5.92963 4.58929 6.9 4.18214C7.87037 3.775 8.9037 3.57143 10 3.57143C10.9185 3.57143 11.8 3.71429 12.6444 4C13.4889 4.28571 14.2815 4.7 15.0222 5.24286L16.2667 4.04286L17.5111 5.24286L16.2667 6.44286C16.8296 7.15714 17.2593 7.92143 17.5556 8.73571C17.8519 9.55 18 10.4 18 11.2857C18 12.3429 17.7889 13.3393 17.3667 14.275C16.9444 15.2107 16.3704 16.0286 15.6444 16.7286C14.9185 17.4286 14.0704 17.9821 13.1 18.3893C12.1296 18.7964 11.0963 19 10 19ZM10 17.2857C11.7185 17.2857 13.1852 16.7 14.4 15.5286C15.6148 14.3571 16.2222 12.9429 16.2222 11.2857C16.2222 9.62857 15.6148 8.21429 14.4 7.04286C13.1852 5.87143 11.7185 5.28571 10 5.28571C8.28148 5.28571 6.81482 5.87143 5.6 7.04286C4.38519 8.21429 3.77778 9.62857 3.77778 11.2857C3.77778 12.9429 4.38519 14.3571 5.6 15.5286C6.81482 16.7 8.28148 17.2857 10 17.2857Z" fill="#656565"/>
</svg>
        );
      };
      const Memo = memo(Timer)
      export default Memo
    