import { useState, useEffect, useRef, useMemo } from 'react'
import { intervalToDuration } from 'date-fns'

const Timer = ({ initialTime }) => {
  const [currentTime, setCurrentTime] = useState(new Date())
  const intervalRef = useRef(null)

  const updateCurrentTime = () => setCurrentTime(new Date())

  useEffect(() => {
    intervalRef.current = setInterval(updateCurrentTime, 1000)

    return () => clearInterval(intervalRef.current)
  }, [])

  const duration = useMemo(
    () => intervalToDuration({ start: initialTime, end: currentTime }),
    [currentTime, initialTime],
  )

  const { days, hours, minutes } = duration

  const formatTime = () => {
    if (days > 0) return `há ${days} dias`
    if (hours > 0)
      return `${hours}h${minutes > 0 ? `${minutes.toString().padStart(2, '0')}min` : ''}`
    if (minutes >= 1) return `${minutes}min`
    return 'Agora'
  }

  return formatTime()
}

export default Timer
