import HeaderButtons from 'components/TicketHeader/HeaderButtons'
import { Avatar, Column, Row, Typography } from 'design-system'
import SkeletonHeader from './SkeletonHeader'

const TicketContactHeader = ({
  ticket,
  loading,
  openDrawer,
  setOpenDrawer,
  openCloseOption,
  setOpenCloseOption,
  acceptTicket,
  setAcceptTicket,
  ...props
}) => {
  const customIcon = [
    {
      icon: 'ArrowSwitch',
      handler: () => setAcceptTicket(ticket),
      disabled: false,
      tooltip: 'Transferir conversa',
      active: acceptTicket,
    },
    {
      icon: 'Check',
      handler: () => setOpenCloseOption(!openCloseOption),
      disabled: ticket?.status === 'closed',
      tooltip: ticket?.status === 'closed' ? 'A conversa já está encerrada.' : 'Encerrar conversa',
      active: openCloseOption,
    },
  ]

  return (
    <Row
      p='8px 16px'
      border='1px solid #E8E8E8'
      borderLeft='none'
      borderRight='none'
      gap='8px'
      minHeight='75px'
      alignItems='center'
      justifyContent='space-between'
      background='#fff'
      {...props}
    >
      {loading ? (
        <SkeletonHeader />
      ) : (
        <>
          <Row
            gap='8px'
            alignItems='center'
            onClick={() => setOpenDrawer(!openDrawer)}
            className='cursor-pointer'
          >
            <Avatar square size='md' src={ticket?.contact?.picture} alt={ticket?.contact?.name} />
            <Column>
              <Typography fontSize='14px' fontWeight='600'>
                {ticket?.contact?.name}
              </Typography>
              <Typography fontSize='14px'>{` ${
                ticket?.attendant?.name
                  ? `Responsável: ${ticket?.attendant?.name}`
                  : 'Sem responsável atendente'
              } | ${ticket?.department?.name || 'Sem departamento'}`}</Typography>
            </Column>
          </Row>
          <Row gap='8px'>
            <HeaderButtons actions={customIcon} />
          </Row>
        </>
      )}
    </Row>
  )
}

export default TicketContactHeader
