
      import React, { memo } from 'react';

      const MoreMessage = (props) => {
        return (
          <svg height="20" width="20"  viewBox="0 0 20 20" {...props}>
	<path d="M0.833252 19.167L2.45825 13.5837C2.19436 13.0142 1.99645 12.4309 1.8645 11.8337C1.73256 11.2364 1.66659 10.6253 1.66659 10.0003C1.66659 8.84755 1.88534 7.76421 2.32284 6.75033C2.76034 5.73644 3.35409 4.85449 4.10409 4.10449C4.85409 3.35449 5.73603 2.76074 6.74992 2.32324C7.76381 1.88574 8.84714 1.66699 9.99992 1.66699C11.1527 1.66699 12.236 1.88574 13.2499 2.32324C14.2638 2.76074 15.1458 3.35449 15.8958 4.10449C16.6458 4.85449 17.2395 5.73644 17.677 6.75033C18.1145 7.76421 18.3333 8.84755 18.3333 10.0003C18.3333 11.1531 18.1145 12.2364 17.677 13.2503C17.2395 14.2642 16.6458 15.1462 15.8958 15.8962C15.1458 16.6462 14.2638 17.2399 13.2499 17.6774C12.236 18.1149 11.1527 18.3337 9.99992 18.3337C9.37492 18.3337 8.76381 18.2677 8.16659 18.1357C7.56936 18.0038 6.98603 17.8059 6.41659 17.542L0.833252 19.167ZM3.29159 16.7087L5.95825 15.917C6.1527 15.8614 6.35061 15.8406 6.552 15.8545C6.75339 15.8684 6.94436 15.917 7.12492 16.0003C7.56936 16.2225 8.03464 16.3892 8.52075 16.5003C9.00686 16.6114 9.49992 16.667 9.99992 16.667C11.861 16.667 13.4374 16.0212 14.7291 14.7295C16.0208 13.4378 16.6666 11.8614 16.6666 10.0003C16.6666 8.13921 16.0208 6.56283 14.7291 5.27116C13.4374 3.97949 11.861 3.33366 9.99992 3.33366C8.13881 3.33366 6.56242 3.97949 5.27075 5.27116C3.97909 6.56283 3.33325 8.13921 3.33325 10.0003C3.33325 10.5003 3.38881 10.9934 3.49992 11.4795C3.61103 11.9656 3.7777 12.4309 3.99992 12.8753C4.09714 13.0559 4.14922 13.2469 4.15617 13.4482C4.16311 13.6496 4.13881 13.8475 4.08325 14.042L3.29159 16.7087ZM9.16659 13.3337H10.8333V10.8337H13.3333V9.16699H10.8333V6.66699H9.16659V9.16699H6.66659V10.8337H9.16659V13.3337Z" fill="#656565"/>
</svg>
        );
      };
      const Memo = memo(MoreMessage)
      export default Memo
    