
      import React, { memo } from 'react';

      const UnlockedRight = (props) => {
        return (
          <svg height="20" width="20"  viewBox="0 0 20 20" {...props}>
	<path d="M4.99992 16.6663H14.9999V8.33301H4.99992V16.6663ZM9.99992 14.1663C10.4583 14.1663 10.8506 14.0031 11.177 13.6768C11.5034 13.3504 11.6666 12.958 11.6666 12.4997C11.6666 12.0413 11.5034 11.649 11.177 11.3226C10.8506 10.9962 10.4583 10.833 9.99992 10.833C9.54158 10.833 9.14922 10.9962 8.82283 11.3226C8.49645 11.649 8.33325 12.0413 8.33325 12.4997C8.33325 12.958 8.49645 13.3504 8.82283 13.6768C9.14922 14.0031 9.54158 14.1663 9.99992 14.1663ZM4.99992 18.333C4.54159 18.333 4.14922 18.1698 3.82284 17.8434C3.49645 17.517 3.33325 17.1247 3.33325 16.6663V8.33301C3.33325 7.87467 3.49645 7.48231 3.82284 7.15592C4.14922 6.82954 4.54159 6.66634 4.99992 6.66634H10.8333V4.99967C10.8333 3.8469 11.2395 2.86426 12.052 2.05176C12.8645 1.23926 13.8471 0.833008 14.9999 0.833008C16.1527 0.833008 17.1353 1.23926 17.9478 2.05176C18.7603 2.86426 19.1666 3.8469 19.1666 4.99967H17.4999C17.4999 4.30523 17.2569 3.71495 16.7708 3.22884C16.2846 2.74273 15.6944 2.49967 14.9999 2.49967C14.3055 2.49967 13.7152 2.74273 13.2291 3.22884C12.743 3.71495 12.4999 4.30523 12.4999 4.99967V6.66634H14.9999C15.4583 6.66634 15.8506 6.82954 16.177 7.15592C16.5034 7.48231 16.6666 7.87467 16.6666 8.33301V16.6663C16.6666 17.1247 16.5034 17.517 16.177 17.8434C15.8506 18.1698 15.4583 18.333 14.9999 18.333H4.99992Z" fill="#656565"/>
</svg>
        );
      };
      const Memo = memo(UnlockedRight)
      export default Memo
    