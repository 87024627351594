import { useCallback, useEffect, useState } from 'react'

import { Checkbox, ColorChip, Column, Popover, Row, Spinner, Typography } from 'design-system'
import { notify } from 'helpers'
import { showCompanyUsersListSelect, showDepartmentsListSelect } from 'services'
import styled from 'styled-components'

const PopoverTicketsFilter = ({
  open,
  elementRef,
  departmentsFilter,
  setDepartmentsFilter,
  attendantFilter,
  setAttendantFilter,
  setUserFilter,
  userFilter,
  otherFilters,
  setOtherFilters,
  ...props
}) => {
  const [loading, setLoading] = useState(false)
  const [departmentList, setDepartmentsList] = useState([])
  const [attendantList, setAttendantList] = useState([])

  useEffect(() => {
    handleGetDepartaments()
  }, [])

  const handleGetDepartaments = async () => {
    try {
      setLoading(true)

      const [{ data: departaments }, { data: attendants }] = await Promise.all([
        showDepartmentsListSelect({ active: true }),
        showCompanyUsersListSelect({ active: true }),
      ])

      setDepartmentsList([{ id: 'null', name: 'Sem departamento' }, ...departaments.results])
      setAttendantList([{ id: 'null', name: 'Sem atendente' }, ...attendants.results])
    } catch {
      notify.error('Não foi possível listar departamentos.')
    } finally {
      setLoading(false)
    }
  }

  const handleClickDepartment = useCallback(
    (item) => {
      const find = departmentsFilter.find((q) => q.id === item.id)
      if (find) {
        const filter = departmentsFilter.filter((q) => q.id !== item.id)
        return setDepartmentsFilter(filter)
      }
      setDepartmentsFilter((prevState) => [...prevState, item])
    },
    [departmentsFilter, setDepartmentsFilter],
  )

  const handleClickAttendant = useCallback(
    (item) => {
      const find = attendantFilter.find((q) => q.id === item.id)
      if (find) {
        const filter = attendantFilter.filter((q) => q.id !== item.id)
        return setAttendantFilter(filter)
      }
      setAttendantFilter((prevState) => [...prevState, item])
    },
    [attendantFilter, setAttendantFilter],
  )

  const handleClickFilterItem = (value) =>
    setOtherFilters((prevState) => (prevState === value ? '' : value))

  return (
    <Popover ref={elementRef} top='24px' right='0' open={open} {...props}>
      <Column minWidth='250px'>
        <Column p='8px'>
          <Typography as='span' color='#474747' fontWeight='600' notSelectable>
            Conversas
          </Typography>
        </Column>
        <Item active={userFilter === 'all'} onClick={() => setUserFilter('all')}>
          Todas as conversas
        </Item>
        <Item active={userFilter === 'attendant'} onClick={() => setUserFilter('attendant')}>
          Suas conversas
        </Item>

        <Column borderTop='3px solid #E8E8E8'>
          <Item active={otherFilters === 'unread'} onClick={() => handleClickFilterItem('unread')}>
            Não lidas
          </Item>
        </Column>
        <Item
          active={otherFilters === 'attendant'}
          onClick={() => handleClickFilterItem('attendant')}
        >
          Aguardando resposta
        </Item>
        <Item active={otherFilters === 'contact'} onClick={() => handleClickFilterItem('contact')}>
          Não respondido
        </Item>
        <Column p='8px' borderTop='4px solid #E8E8E8'>
          <Typography as='span' color='#474747' fontWeight='600' notSelectable>
            Departamentos
          </Typography>
        </Column>
        {loading ? (
          <Column p='16px' alignItems='center' justifyContent='center'>
            <Spinner />
          </Column>
        ) : (
          <Column>
            {departmentList.map((department) => (
              <Item key={department?.id} onClick={() => handleClickDepartment(department)}>
                <Row gap='4px' className='cursor-pointer'>
                  <Checkbox checked={!!departmentsFilter.find((q) => q.id === department?.id)} />
                  <Typography notSelectable>{department?.name}</Typography>
                </Row>
                <ColorChip color={department?.color} width='11px' height='11px' />
              </Item>
            ))}
          </Column>
        )}
        <Column p='8px' borderTop='4px solid #E8E8E8'>
          <Typography as='span' color='#474747' fontWeight='600'>
            Atendente
          </Typography>
        </Column>
        {loading ? (
          <Column p='16px' alignItems='center' justifyContent='center'>
            <Spinner />
          </Column>
        ) : (
          <Column>
            {attendantList.map((attendant) => (
              <Item key={attendant?.id} onClick={() => handleClickAttendant(attendant)}>
                <Row gap='4px' className='cursor-pointer'>
                  <Checkbox checked={!!attendantFilter.find((q) => q.id === attendant?.id)} />
                  <Typography notSelectable>{attendant?.name}</Typography>
                </Row>
              </Item>
            ))}
          </Column>
        )}
      </Column>
    </Popover>
  )
}

const Item = styled(Row)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: #656565;
  font-size: 16px;
  transition: all 0.5s ease;
  padding: 8px;
  border-top: 1px solid #e8e8e8;
  background: ${({ active }) => (active ? '#edf3fc' : 'transparent')};
  user-select: none;

  &:hover {
    background: #edf3fc;
  }
`

export default PopoverTicketsFilter
