import { clientApi } from 'providers'

export const showTagInfo = (id) => clientApi.get(`/contacts/tags/${id}/`)

export const showTagsList = (params) => clientApi.get(`/contacts/tags/`, { params })

export const createTag = (data) => clientApi.post('/contacts/tags/', data)

export const updatedTag = (id, data) => clientApi.put(`/contacts/tags/${id}/`, data)

export const deleteTag = (id) => clientApi.delete(`/contacts/tags/${id}/`)
