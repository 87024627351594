import { variant } from 'styled-system'

export const variants = () =>
  variant({
    prop: 'variant',
    variants: {
      title: {
        fontSize: '22px',
        fontWeight: '600',
        letterSpacing: '0px',
      },
      subtitle: {
        fontSize: '16px',
        fontWeight: '600',
        letterSpacing: '0.1px',
      },
      paragraph: {
        fontSize: '14px',
        fontWeight: '400',
        letterSpacing: '0px',
      },
      caption: {
        fontSize: '12px',
        fontWeight: '400',
        letterSpacing: '0px',
      },
      helper: {
        fontSize: '10px',
        fontWeight: '400',
        letterSpacing: '0px',
      },
    },
  })
