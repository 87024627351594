
      import React, { memo } from 'react';

      const Scan = (props) => {
        return (
          <svg height="20" width="20"  viewBox="0 0 20 20" {...props}>
	<path d="M5 12H3V17H8V15H5V12ZM3 8H5V5H8V3H3V8ZM15 15H12V17H17V12H15V15ZM12 3V5H15V8H17V3H12Z" fill="#656565"/>
</svg>
        );
      };
      const Memo = memo(Scan)
      export default Memo
    