import { Image, Modal, ModalBody, Row } from 'design-system'

const ModalExpandImage = ({ data, ...props }) => {
  return (
    <Modal size='md' {...props}>
      <ModalBody>
        <Row gap='8px'>
          <Image src={data.file} width='100%' />
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default ModalExpandImage
