import { memo } from 'react'

const Filter = (props) => {
  return (
    <svg height='20' width='20' viewBox='0 0 20 20' {...props}>
      <path
        d='M5.83739 4.99967H14.1707L9.99572 10.2497L5.83739 4.99967ZM3.54572 4.67467C5.22905 6.83301 8.33739 10.833 8.33739 10.833V15.833C8.33739 16.2913 8.71239 16.6663 9.17072 16.6663H10.8374C11.2957 16.6663 11.6707 16.2913 11.6707 15.833V10.833C11.6707 10.833 14.7707 6.83301 16.4541 4.67467C16.8791 4.12467 16.4874 3.33301 15.7957 3.33301H4.20405C3.51239 3.33301 3.12072 4.12467 3.54572 4.67467Z'
        fill='#656565'
      />
    </svg>
  )
}
const Memo = memo(Filter)
export default Memo
