import { memo } from 'react'

const Company = (props) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <mask maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'>
        <rect width='24' height='24' fill='#D9D9D9' />
      </mask>
      <g>
        <path
          d='M17 9H19V7H17V9ZM17 13H19V11H17V13ZM17 17H19V15H17V17ZM17 21V19H21V5H12V6.4L10 4.95V3H23V21H17ZM1 21V11L8 6L15 11V21H9V16H7V21H1ZM3 19H5V14H11V19H13V12L8 8.45L3 12V19Z'
          fill='#1C1B1F'
        />
      </g>
    </svg>
  )
}
const Memo = memo(Company)
export default Memo
