import React from 'react'

import Column from '../Column'
import Typography from '../Typography'
import Button from '../Button'
import Image from '../Image'
import { robotWarning } from 'assets/ilustrations'

const Empty = ({ emptyMessage, handleEmptyData, title }) => {
  return (
    <Column p='20px 0' minHeight='350px' width='100%' justifyContent='center' alignItems='center'>
      <Image width='70px' src={robotWarning} alt='Robô informando que nenhum dado foi encontado.' />
      {emptyMessage && (
        <Typography mt='16px' maxWidth='500px' textAlign='center'>
          {emptyMessage}
        </Typography>
      )}
      {handleEmptyData && (
        <Button
          mt='16px'
          maxWidth='360px'
          color='primary'
          onClick={handleEmptyData}
        >{`Adicionar ${title}`}</Button>
      )}
    </Column>
  )
}

Empty.defaultPros = {
  title: '',
  emptyMessage: '',
  handleEmptyData: null,
}

export default Empty
