import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { space, color, compose, layout } from 'styled-system'

import Typography from '../Typography'
import { colors } from 'configs'

const style = compose(space, color, layout)

const BaseInput = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  position: relative;
  width: 15px;
  height: 15px;
  border: ${({ disabled }) => `1px solid ${disabled ? colors.grey[50] : colors.grey[300]}`};
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  background-color: white;
  ${style}
  &:checked {
    background-color: ${({ color }) => colors[color]};
    border-color: ${({ color }) => colors[color]};
    &::after {
      content: '';
      display: block;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 2px 2px 0;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
`

const Base = styled.label`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 4px;
  flex-direction: ${({ labelPosition }) => (labelPosition === 'left' ? 'row' : 'row-reverse')};

  ${style};
`

const Checkbox = forwardRef(
  ({ label, labelPosition, onChange, hidden, disabled, ...props }, ref) => (
    <Base htmlFor={ref} labelPosition={labelPosition}>
      <BaseInput
        hidden={hidden}
        ref={ref}
        id={ref}
        onChange={onChange}
        disabled={disabled}
        {...props}
      />
      {label && !hidden && (
        <Typography
          cursor='pointer'
          variant='caption'
          color={disabled ? 'grey.50' : 'grey.300'}
          notSelectable
        >
          {label}
        </Typography>
      )}
    </Base>
  ),
)

Checkbox.defaultProps = {
  labelPosition: 'left',
  hidden: false,
  color: 'secondary',
}

export default Checkbox
