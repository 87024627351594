import { useEffect, useState } from 'react'

import {
  Badge,
  Card,
  Column,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Typography,
} from 'design-system'

import { getDateLabel, hexToRGBA, notify } from 'helpers'
import TicketMessage from 'components/TicketMessage'
import { showTicketMessagesList } from 'services'
import { colors } from 'configs'
import { format } from 'date-fns'
import DateSeparator from 'components/TicketContainer/DateSeparator'

const ModalHistoricTicket = ({ open, onClose, ticket, historicList }) => {
  const [loading, setLoading] = useState(false)
  const [conversation, setConversation] = useState([])
  const [selectedTicket, setSelectedTicket] = useState({})

  const { contact, attendant, department } = ticket || {}

  useEffect(() => {
    ticket?.id && handleGetConversation(ticket?.id)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket?.id])

  const handleGetConversation = async (id) => {
    try {
      setLoading(true)

      const { data } = await showTicketMessagesList(id)

      const groupedMessages = data.results.reduce((acc, curr) => {
        const key = getDateLabel(curr.created_at)

        acc[key] ? acc[key].push(curr) : (acc[key] = [{ ...curr, key }])

        return acc
      }, {})

      setConversation(groupedMessages)
    } catch {
      notify.error('Não foi possível buscar histórico da conversa.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    selectedTicket?.id && handleGetConversation(selectedTicket?.id)
  }, [selectedTicket])

  return (
    <Modal open={open} size='lg' closeClickOut onClose={onClose}>
      <ModalHeader onClose={onClose}>
        <Column>
          <Typography variant='title' color='primary'>
            {contact?.name}
          </Typography>
          <Typography>{`Responsável: ${attendant?.name || 'Sem atendente'} | ${
            department?.name || 'Sem departamento'
          }`}</Typography>
        </Column>
      </ModalHeader>
      <ModalBody>
        <Card p='0' background={colors.grey[40]}>
          <Row>
            <Column
              width='100%'
              p='10px 10px 0'
              height='80%'
              minHeight='300px'
              maxHeight='500px'
              flexDirection='column-reverse'
              gap='16px'
              overflow='auto'
            >
              {!loading ? (
                <Column width='100%' flexDirection='column-reverse' height='80%' overflow='auto'>
                  {Object.keys(conversation).map((date) => (
                    <Column position='relative' p='10px 10px 0'>
                      <Column flexDirection='column-reverse' gap='8px'>
                        {conversation[date].map((message) => (
                          <TicketMessage message={message} />
                        ))}
                        <DateSeparator date={date} />
                      </Column>
                    </Column>
                  ))}
                </Column>
              ) : (
                <Column>
                  <Spinner size='lg' />
                </Column>
              )}
            </Column>
            <Column p='10px' width='450px' border={`1px solid ${colors.grey[40]}`}>
              <Column maxHeight='500px' overflow='auto'>
                {historicList.length > 0 &&
                  historicList.map(({ closed_at, department, attendant, id, contact }, idx) => (
                    <Column
                      key={idx}
                      className='cursor-pointer'
                      p='8px'
                      borderBottom={`1px solid ${colors.grey[50]}`}
                      onClick={() =>
                        setSelectedTicket({ id, contact, attendant, closed_at, department })
                      }
                    >
                      <Row width='100%' justifyContent='space-between'>
                        <Row gap='4px' width='100%'>
                          <Typography>{format(closed_at, 'dd/MM/yyyy HH:mm')}</Typography>
                        </Row>
                        {department && (
                          <Badge
                            width='100px'
                            backgroundColor={hexToRGBA(department?.color, 0.2)}
                            color={department?.color}
                            title={department?.name}
                            fontStyle={{ fontSize: '12px' }}
                          />
                        )}
                      </Row>
                      <Row>
                        <Typography fontSize='12px'>{`Responsável: ${
                          attendant?.name || 'Sem responsável'
                        }`}</Typography>
                      </Row>
                    </Column>
                  ))}
              </Column>
            </Column>
          </Row>
        </Card>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  )
}

export default ModalHistoricTicket
