import styled from 'styled-components'
import { space, color, layout, compose, border } from 'styled-system'

const styles = compose(space, color, layout, border)

const Base = styled.video`
  width: 100%;
  ${styles};
`

const VideoPlayer = ({ src, ...props }) => {
  return (
    <Base controls {...props}>
      <source src={src} type='video/mp4' />
      <source src={src} type='video/ogg' />
      <source src={src} type='video/gif' />
    </Base>
  )
}

export default VideoPlayer
