import React, { forwardRef } from 'react'
import { Controller } from 'react-hook-form'
import MaskedInput from 'react-maskedinput'
import styled from 'styled-components'
import { space, layout, typography, compose, border } from 'styled-system'

import Icon from '../Icon'
import Typography from '../Typography'
import Skeleton from 'design-system/Skeleton'

const styles = compose(space, layout, typography, border)

const Base = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: fit-content;
  width: 100%;
  ${styles}
`

const InputField = styled(MaskedInput)`
  width: inherit;
  border-radius: 4px;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme: { colors } }) => colors.grey[300]};
  padding: ${({ position, icon }) =>
    icon ? (position === 'end' ? '5px 36px 5px 8px' : '5px 8px 5px 36px') : '5px 16px'};
  border: ${({ theme: { colors } }) => `1px solid ${colors.grey[50]}`};
  &:hover {
    border: ${({ theme: { colors } }) => `1px solid ${colors.blue[80]}`};
  }
  &:focus {
    border: ${({ theme: { colors } }) => `1px solid ${colors.blue[80]}`};
  }
  ${styles}
`

const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  ${({ position }) => (position === 'end' ? 'right: 8px;' : 'left: 8px;')};
  top: ${({ label }) => (label ? ' 10px;' : '0px;')};
`

const InputMasked = forwardRef(
  (
    {
      label,
      rules,
      required,
      error,
      errorMessage,
      icon,
      iconPosition,
      containerProps,
      maxWidth,
      iconProps,
      control,
      name,
      defaultValue,
      loading,
      ...props
    },
    ref,
  ) => {
    return loading ? (
      <Base maxWidth={maxWidth} {...containerProps}>
        {label && <Skeleton width='100px' height='20px' />}
        <Skeleton mt='2px' maxWidth={maxWidth} p='15px' {...containerProps} />
      </Base>
    ) : (
      <Base maxWidth={maxWidth} {...containerProps}>
        {label && <Typography>{`${label} ${required ? '*' : ''}`}</Typography>}
        {control ? (
          <Controller
            name={name}
            defaultValue={defaultValue}
            control={control}
            rules={{ required, ...rules }}
            render={({ field }) => (
              <InputField ref={ref} icon={icon} position={iconPosition} {...props} {...field} />
            )}
          />
        ) : (
          <InputField ref={ref} icon={icon} position={iconPosition} {...props} />
        )}
        {icon && (
          <IconWrapper label={label} position={iconPosition}>
            <Icon icon={icon} {...iconProps} />
          </IconWrapper>
        )}
        {error && (
          <Typography ml='5px' variant='helper' color='danger'>
            {errorMessage || 'Campo obrigatório'}
          </Typography>
        )}
      </Base>
    )
  },
)

InputMasked.defaultProps = {
  type: 'text',
  mask: '11/11/1111',
}

export default InputMasked
