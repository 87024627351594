import React, { memo } from 'react'

const GraphicArrowUp = (props) => {
  return (
    <svg width='14' height='10' viewBox='0 0 14 10' fill='none' {...props}>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M10.3698 2.92432H8.61572V0.924316H12.6157H13.6157V1.92432V5.92432H11.6157V4.50687L8.31796 7.80462L7.61086 8.51173L6.90375 7.80462L4.79451 5.69539L1.41422 9.0757L0 7.66149L4.0874 3.57407L4.79451 2.86696L5.50162 3.57407L7.61086 5.6833L10.3698 2.92432Z'
        fill='#00DEA3'
      />
    </svg>
  )
}
const Memo = memo(GraphicArrowUp)
export default Memo
