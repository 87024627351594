import { clientApi } from 'providers'

export const showDepartmentInfo = (id) => clientApi.get(`/departments/${id}/`)

export const showDepartmentsList = (params) => clientApi.get(`/departments/`, { params })

export const showDepartmentsListSelect = (params) =>
  clientApi.get(`/departments/select/`, { params })

export const createDepartment = (data) => clientApi.post('/departments/', data)

export const updatedDepartment = (id, data) => clientApi.put(`/departments/${id}/`, data)

export const deleteDepartment = (id) => clientApi.delete(`/departments/${id}/`)
