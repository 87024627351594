import { memo } from 'react'

const Search = (props) => {
  return (
    <svg height='20' width='20' viewBox='0 0 20 20' {...props}>
      <path
        d='M13.1291 11.8796H12.4707L12.2374 11.6546C13.0541 10.7046 13.5457 9.47122 13.5457 8.12956C13.5457 5.13789 11.1207 2.71289 8.12907 2.71289C5.1374 2.71289 2.7124 5.13789 2.7124 8.12956C2.7124 11.1212 5.1374 13.5462 8.12907 13.5462C9.47074 13.5462 10.7041 13.0546 11.6541 12.2379L11.8791 12.4712V13.1296L16.0457 17.2879L17.2874 16.0462L13.1291 11.8796ZM8.12907 11.8796C6.05407 11.8796 4.37907 10.2046 4.37907 8.12956C4.37907 6.05456 6.05407 4.37956 8.12907 4.37956C10.2041 4.37956 11.8791 6.05456 11.8791 8.12956C11.8791 10.2046 10.2041 11.8796 8.12907 11.8796Z'
        fill='#656565'
      />
    </svg>
  )
}
const Memo = memo(Search)
export default Memo
