import React, { memo } from 'react'

const GraphicArrowDown = (props) => {
  return (
    <svg width='14' height='10' viewBox='0 0 14 10' fill='none' {...props}>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M10.3698 7.07571H8.61572V9.07571H12.6157H13.6157V8.07571V4.07571H11.6157V5.49317L8.31796 2.19541L7.61086 1.4883L6.90375 2.19541L4.79451 4.30464L1.41422 0.924329L0 2.33854L4.0874 6.42596L4.79451 7.13307L5.50162 6.42597L7.61086 4.31673L10.3698 7.07571Z'
        fill='#F23985'
      />
    </svg>
  )
}
const Memo = memo(GraphicArrowDown)
export default Memo
