import React, { memo } from 'react'

const Refresh = (props) => {
  return (
    <svg height='20' width='20' viewBox='0 0 20 20' {...props}>
      <path
        d='M10 18.333C8.95833 18.333 7.98264 18.1351 7.07292 17.7393C6.16319 17.3434 5.37153 16.8087 4.69792 16.1351C4.02431 15.4615 3.48958 14.6698 3.09375 13.7601C2.69792 12.8504 2.5 11.8747 2.5 10.833H4.16667C4.16667 12.458 4.73264 13.8365 5.86458 14.9684C6.99653 16.1004 8.375 16.6663 10 16.6663C11.625 16.6663 13.0035 16.1004 14.1354 14.9684C15.2674 13.8365 15.8333 12.458 15.8333 10.833C15.8333 9.20801 15.2674 7.82954 14.1354 6.69759C13.0035 5.56565 11.625 4.99967 10 4.99967H9.875L11.1667 6.29134L10 7.49967L6.66667 4.16634L10 0.833008L11.1667 2.04134L9.875 3.33301H10C11.0417 3.33301 12.0174 3.53092 12.9271 3.92676C13.8368 4.32259 14.6285 4.85731 15.3021 5.53092C15.9757 6.20454 16.5104 6.9962 16.9062 7.90592C17.3021 8.81565 17.5 9.79134 17.5 10.833C17.5 11.8747 17.3021 12.8504 16.9062 13.7601C16.5104 14.6698 15.9757 15.4615 15.3021 16.1351C14.6285 16.8087 13.8368 17.3434 12.9271 17.7393C12.0174 18.1351 11.0417 18.333 10 18.333Z'
        fill='#656565'
      />
    </svg>
  )
}
const Memo = memo(Refresh)
export default Memo
