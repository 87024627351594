import { clientApi } from 'providers'

export const showTicketInfo = (id) => clientApi.get(`/tickets/${id}/`)

export const showTicketList = (params) => clientApi.get(`/tickets/`, { params })

export const createTicket = (data) => clientApi.post('/tickets/', data)

export const updateTicket = (id, data) => clientApi.put(`/tickets/${id}/`, data)

// #MESSAGES
export const sendMessageTicket = (id, data) => clientApi.post(`/tickets/${id}/messages/`, data)

export const showTicketMessagesList = (id, params) =>
  clientApi.get(`/tickets/${id}/messages/`, { params })

export const markMessageRead = (id) => clientApi.post(`/tickets/${id}/mark-messages-as-read/`)
