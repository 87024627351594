import { clientApi } from 'providers'

// #TEMPLATES
export const showTemplatesList = (params) => clientApi.get(`/message-templates/`, { params })

export const showTemplatesListSelect = (params) =>
  clientApi.get(`/message-templates/select`, { params })

export const showTemplateInfo = (id) => clientApi.get(`/message-templates/${id}/`)

export const createTemplate = (data) => clientApi.post(`/message-templates/`, data)

export const updateTemplate = (id, data) => clientApi.put(`/message-templates/${id}/`, data)

export const refreshTemplate = (id) => clientApi.put(`/message-templates/${id}/refresh/`)

export const importTemplateUuid = (data) => clientApi.post(`/message-templates/fetch/`, data)

export const deleteSoftTemplate = (id) => clientApi.delete(`/message-templates/${id}/soft/`)

// #TAGS
export const showTemplateTagsList = (params) =>
  clientApi.get(`/message-templates/tags/`, { params })

export const showTemplateTag = (id) => clientApi.get(`/message-templates/tags/${id}/`)

export const createTemplateTag = (data) => clientApi.post(`/message-templates/tags/`, data)

export const updateTemplateTag = (id, data) => clientApi.put(`/message-templates/tags/${id}/`, data)

export const deleteTemplateTag = (id) => clientApi.delete(`/message-templates/tags/${id}/`)
