import { colors } from 'configs'
import { Column, Icon, Row, Typography } from 'design-system'

const CompanyItem = ({ company, active, handleChangeCompany }) => {
  return (
    <Row
      key={company.id}
      className='cursor-pointer'
      p='8px'
      gap='12px'
      alignItems='center'
      justifyContent='space-between'
      borderTop={`1px solid ${colors.grey[50]}`}
      onClick={() => handleChangeCompany({ company: company?.id })}
    >
      <Icon icon='Company' color='grey.100' />
      <Column width='100%'>
        <Typography whiteSpace='nowrap' fontWeight={active ? '600' : '400'}>
          {company?.name}
        </Typography>
        {company?.cpnj && (
          <Typography whiteSpace='nowrap' color='grey.200'>
            {`CNPJ: ${company?.cnpj || ''}`}
          </Typography>
        )}
      </Column>
      {active && <Icon icon='Check' color='grey.300' />}
    </Row>
  )
}

export default CompanyItem
