import { useState } from 'react'
import { colors } from 'configs'
import { Button, Icon, Row, Typography } from 'design-system'
import ModalSendConversationTemplate from 'components/ModalSendTemplate'

const BlockedMessage = ({ ticket }) => {
  const [open, setOpen] = useState(false)
  const [templateSended, setTemplateSended] = useState(false)

  return (
    <Row
      p='8px 8px 18px 8px'
      width='100%'
      alignItems='center'
      backgroundColor={colors.blue[60]}
      borderRadius='8px 8px 0 0'
      justifyContent='space-between'
      mb='-10px'
    >
      <Row gap='4px'>
        <Icon icon={templateSended ? 'CheckCircle' : 'Alert'} color='secondary' />
        {templateSended ? (
          <Typography>
            Template enviado! Você pode continuar enviando mais templates para manter a conversa
            ativa.
          </Typography>
        ) : (
          <Typography>
            {!ticket?.contact_interacted && !ticket?.conversation_expiration_datetime
              ? 'Aguarde a resposta do cliente para continuar a conversa.'
              : 'Esta conversa foi fechada após 24 horas de inatividade. Envie um novo template para reabrir.'}
          </Typography>
        )}
      </Row>
      <Button variant='outlined' width='150px' iconLeft='Add' onClick={() => setOpen(true)}>
        Buscar template
      </Button>
      {open && (
        <ModalSendConversationTemplate
          open={open}
          ticket={ticket}
          onClose={() => setOpen(null)}
          callBack={setTemplateSended}
        />
      )}
    </Row>
  )
}

export default BlockedMessage
