
      import React, { memo } from 'react';

      const ChatgdsIcon = (props) => {
        return (
          <svg height="20" width="20"  viewBox="0 0 20 20" {...props}>
	<path d="M8.65346 12.165C9.17527 13.6853 9.67471 15.1823 10.2142 16.6663C10.2822 16.8544 10.5804 17.1134 10.7239 17.0855C10.9457 17.0435 11.2168 16.8218 11.3091 16.6085C12.103 14.7584 12.8615 12.8925 13.6293 11.0313C14.7577 8.29623 15.8842 5.55931 17.0135 2.8252C17.1244 2.55691 17.213 2.29794 16.9604 2.05574C16.7182 1.82378 16.4638 1.87688 16.187 1.99053C11.6427 3.86016 7.09736 5.72606 2.55205 7.59197C2.50918 7.60967 2.46632 7.62737 2.42253 7.64414C2.13553 7.75406 1.86997 7.89473 1.87742 8.25431C1.88488 8.61482 2.15696 8.73685 2.45048 8.83653C3.88824 9.32559 5.32415 9.81932 6.76098 10.3112C6.86348 10.3466 7.00884 10.3531 7.06008 10.4248C7.18029 10.5953 7.25576 10.7965 7.34894 10.9856C7.15886 11.0769 6.9725 11.1785 6.77495 11.2511C6.71905 11.2716 6.63146 11.2073 6.55785 11.1822C5.09026 10.6829 3.62175 10.1864 2.1551 9.68331C1.38357 9.41875 1.01644 8.96788 1.0006 8.28784C0.98383 7.59476 1.31648 7.13923 2.0731 6.82809C6.61841 4.96126 11.1637 3.09535 15.71 1.23224C15.9243 1.14467 16.1451 1.05245 16.3706 1.01798C17.5093 0.845645 18.3246 1.94115 17.8736 3.05529C17.0834 5.00783 16.27 6.95106 15.4696 8.89987C14.3691 11.579 13.2724 14.261 12.171 16.9401C11.87 17.6714 11.3669 18.0273 10.696 17.9984C10.0689 17.9714 9.57967 17.6062 9.3551 16.955C8.82491 15.418 8.29658 13.879 7.799 12.3308C7.7375 12.1398 7.79714 11.824 7.92479 11.6703C9.56289 9.70567 11.2243 7.75965 12.8773 5.80711C13.0823 5.56491 13.3087 5.34785 13.6171 5.61335C13.9442 5.89561 13.7457 6.15831 13.5305 6.41076C12.0536 8.14624 10.5795 9.88546 9.10445 11.6228C8.96282 11.7914 8.82584 11.9582 8.65346 12.165Z" fill="#B6ADD7"/>
</svg>
        );
      };
      const Memo = memo(ChatgdsIcon)
      export default Memo
    