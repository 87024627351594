import React, { memo } from 'react'

const Locked = (props) => {
  return (
    <svg height='20' width='20' viewBox='0 0 20 20' {...props}>
      <path
        d='M5.00016 18.333C4.54183 18.333 4.14947 18.1698 3.82308 17.8434C3.49669 17.517 3.3335 17.1247 3.3335 16.6663V8.33301C3.3335 7.87467 3.49669 7.48231 3.82308 7.15592C4.14947 6.82954 4.54183 6.66634 5.00016 6.66634H5.8335V4.99967C5.8335 3.8469 6.23975 2.86426 7.05225 2.05176C7.86475 1.23926 8.84738 0.833008 10.0002 0.833008C11.1529 0.833008 12.1356 1.23926 12.9481 2.05176C13.7606 2.86426 14.1668 3.8469 14.1668 4.99967V6.66634H15.0002C15.4585 6.66634 15.8509 6.82954 16.1772 7.15592C16.5036 7.48231 16.6668 7.87467 16.6668 8.33301V16.6663C16.6668 17.1247 16.5036 17.517 16.1772 17.8434C15.8509 18.1698 15.4585 18.333 15.0002 18.333H5.00016ZM5.00016 16.6663H15.0002V8.33301H5.00016V16.6663ZM10.0002 14.1663C10.4585 14.1663 10.8509 14.0031 11.1772 13.6768C11.5036 13.3504 11.6668 12.958 11.6668 12.4997C11.6668 12.0413 11.5036 11.649 11.1772 11.3226C10.8509 10.9962 10.4585 10.833 10.0002 10.833C9.54183 10.833 9.14947 10.9962 8.82308 11.3226C8.49669 11.649 8.3335 12.0413 8.3335 12.4997C8.3335 12.958 8.49669 13.3504 8.82308 13.6768C9.14947 14.0031 9.54183 14.1663 10.0002 14.1663ZM7.50016 6.66634H12.5002V4.99967C12.5002 4.30523 12.2571 3.71495 11.771 3.22884C11.2849 2.74273 10.6946 2.49967 10.0002 2.49967C9.30572 2.49967 8.71544 2.74273 8.22933 3.22884C7.74322 3.71495 7.50016 4.30523 7.50016 4.99967V6.66634Z'
        fill='#656565'
      />
    </svg>
  )
}
const Memo = memo(Locked)
export default Memo
