import { clientApi } from 'providers'

export const showConnectionInfo = (id) => clientApi.get(`/connections/${id}/`)

export const showConnectionsList = (params) => clientApi.get(`/connections`, { params })

export const showConnectionsListSelect = (params) =>
  clientApi.get(`/connections/select`, { params })

export const createConnection = (data) => clientApi.post('/connections/', data)

export const updatedConnection = (id, data) => clientApi.put(`/connections/${id}/`, data)

export const deleteConnection = (id) => clientApi.delete(`/connections/${id}/`)
