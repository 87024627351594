
      import React, { memo } from 'react';

      const RightArrow = (props) => {
        return (
          <svg height="20" width="20"  viewBox="0 0 20 20" {...props}>
	<path d="M6.9126 13.825L10.7293 10L6.9126 6.175L8.0876 5L13.0876 10L8.0876 15L6.9126 13.825Z" fill="#656565"/>
</svg>
        );
      };
      const Memo = memo(RightArrow)
      export default Memo
    