import { Row, Image, Typography, Icon } from 'design-system'

import { robotTags } from 'assets/ilustrations'

const RobotHelp = ({ text, robot, color, alert, ...props }) => {
  return (
    <Row
      p='12px 8px'
      borderRight='none'
      border={`1px solid ${color}`}
      borderLeft={`5px solid ${color}`}
      borderRadius='4px'
      gap='12px'
      alignItems='center'
      maxWidth='480px'
      {...props}
    >
      {robot && (
        <Image width='80px' src={robotTags} alt='Robô ajudante informando algum tipo de auxílio' />
      )}
      {alert && <Icon icon='Information' color='#656565' />}
      <Typography variant='caption'>{text}</Typography>
    </Row>
  )
}

RobotHelp.defaultProps = {
  text: '',
  robot: true,
  color: '#AFADD7',
  alert: false,
}

export default RobotHelp
