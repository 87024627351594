import { clientApi } from 'providers'

export const showRoleInfo = (id) => clientApi.get(`/roles/${id}/`)

export const showRolesList = (params) => clientApi.get(`/roles/`, { params })

export const showRolesPermissions = () => clientApi.get('/roles/module-actions/')

export const createRole = (data) => clientApi.post('/roles/', data)

export const updatedRole = (id, data) => clientApi.put(`/roles/${id}/`, data)

export const deleteRole = (id) => clientApi.delete(`/roles/${id}/`)
