import { useEffect, useState, createContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Cookies from 'js-cookie'

import { Image, Column, Spinner } from 'design-system'

import { system } from 'configs'
import { me } from 'services'
import { useUserReduce, actionTypes } from './store'

import { chatGDSLogo } from 'assets'

export const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [loadingApp, setLoadingApp] = useState(true)

  const [state, dispatch] = useUserReduce()

  const navigate = useNavigate()
  const location = useLocation()

  const { COOKIE_TOKEN } = system

  const logout = () => {
    dispatch({ type: actionTypes.RESET })
    Cookies.remove(COOKIE_TOKEN)
    navigate('/login', { replace: true })
    setLoadingApp(false)
  }

  const handleMe = async () => {
    try {
      setLoadingApp(true)

      const { data } = await me()

      const permissions = data?.session?.module_actions

      dispatch({
        type: actionTypes.ADD_ITEM,
        payload: { ...data, permissions },
      })

      navigate(
        redirectDict[location?.pathname] ? redirectDict[location?.pathname] : location?.pathname,
        { replace: true },
      )
    } catch (err) {
      console.error(err)
      logout()
    } finally {
      setLoadingApp(false)
    }
  }

  useEffect(() => {
    const isAuthenticated = Cookies.get(COOKIE_TOKEN)

    if (!!isAuthenticated) {
      handleMe()
    } else {
      setLoadingApp(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const userData = state.user
  const updateLoggedUser = dispatch
  const userActionTypes = actionTypes

  return (
    <AuthContext.Provider
      value={{
        userData,
        updateLoggedUser,
        logout,
        loadingApp,
        userActionTypes,
        setLoadingApp,
      }}
    >
      {loadingApp ? (
        <Column height='100%' minHeight='100vh' alignItems='center' justifyContent='center'>
          <Image width='480px' src={chatGDSLogo} />
          <Spinner size='xlg' />
        </Column>
      ) : (
        children
      )}
    </AuthContext.Provider>
  )
}

const redirectDict = {
  '/': '/tickets',
  '/login': '/tickets',
}

export default AuthContext
