import { Column, Row, Skeleton } from 'design-system'

const SkeletonContactCard = () => {
  return (
    <Row
      gap='12px'
      flexDirection={['column', 'column', 'column', 'row']}
      justifyContent='space-between'
      alignItems='center'
    >
      <Skeleton borderRadius='16px' maxWidth='110px' height='110px' />
      <Column width='100%' gap='4px'>
        <Skeleton maxWidth='150px' height='40px' />
        <Skeleton maxWidth='150px' height='20px' />
        <Skeleton maxWidth='200px' height='15px' />
      </Column>
    </Row>
  )
}

export default SkeletonContactCard
