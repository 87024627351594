import { clientApi } from 'providers'

export const showExtraFieldInfo = (id) => clientApi.get(`/contacts/extra-fields/${id}/`)

export const showExtraFieldsList = (params) => clientApi.get(`/contacts/extra-fields/`, { params })

export const createExtraField = (data) => clientApi.post('/contacts/extra-fields/', data)

export const updatedExtraField = (id, data) => clientApi.put(`/contacts/extra-fields/${id}/`, data)

export const deleteExtraField = (id) => clientApi.delete(`/contacts/extra-fields/${id}/`)
