import { useReducer } from 'react'

export const initialValues = {
  departments: [],
}

export const actionTypes = {
  LOAD_ITEMS: 'LOAD_ITEMS',
  ADD_ITEM: 'ADD_ITEM',
  UPDATE_ITEM: 'UPDATE_ITEM',
  REMOVE_ITEM: 'REMOVE_ITEM',
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.LOAD_ITEMS:
      return { ...state, departments: action.payload }
    case actionTypes.ADD_ITEM:
      return {
        ...state,
        departments: [...state.departments, action.payload].sort(
          (itemA, itemB) => itemA.name.localeCompare(itemB.name)
        ),
      }
    case actionTypes.UPDATE_ITEM:
      const filter = state.departments.filter(
        (item) => item.id !== action.payload.id
      )
      return {
        ...state,
        departments: [...filter, action.payload].sort((itemA, itemB) =>
          itemA.name.localeCompare(itemB.name)
        ),
      }
    case actionTypes.REMOVE_ITEM:
      return {
        ...state,
        departments: state.departments.filter(
          (department) => department.id !== action.payload
        ),
      }
    default:
      return state
  }
}

export const useDepartmentsReducer = () => useReducer(reducer, initialValues)
