import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  Row,
  Button,
  Typography,
  ColorPicker,
  ModalFooter,
} from 'design-system'

import { createTag, showTagInfo, updatedTag } from 'services'
import { defaultColorsTags } from 'configs'
import { notify } from 'helpers'

const ModalTags = ({ onClose, tag, dispatch, actionTypes, callBack, ...props }) => {
  const [loading, setLoading] = useState(false)
  const [currentColor, setCurrentColor] = useState('')

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: { active: true } })

  const randomColor = defaultColorsTags[0]

  useEffect(() => {
    if (tag?.id) {
      handleGetInfo()
    }

    reset({
      active: true,
      name: '',
      color: randomColor,
    })

    return () => {
      setCurrentColor(randomColor)
      reset({
        active: true,
        name: '',
        color: randomColor,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag])

  const onSubmit = async (values) => {
    try {
      setLoading(true)

      const { data } = tag?.id ? await updatedTag(tag?.id, values) : await createTag(values)

      dispatch &&
        dispatch({
          type: tag?.id ? actionTypes.UPDATE_ITEM : actionTypes.ADD_ITEM,
          payload: data,
        })
      notify.success(tag?.id ? 'Etiqueta salva com sucesso.' : 'Nova etiqueta criada com sucesso.')
      callBack && callBack(data)
      onClose()
    } catch {
      notify.error('Não foi possível salvar configuração da etiqueta.')
    } finally {
      setLoading(false)
    }
  }

  const handleGetInfo = async () => {
    try {
      setLoading(true)
      const { data } = await showTagInfo(tag?.id)

      setCurrentColor(data.color)
      reset(data)
    } catch {
      notify.error('Não foi possível buscar dados da etiqueta.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal size='sm' open={tag} onClose={onClose} {...props}>
      <ModalHeader onClose={onClose}>
        <Typography variant='title' color='primary'>
          {tag?.id ? 'Editar etiqueta' : 'Nova etiqueta'}
        </Typography>
      </ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Row gap='8px'>
            <Input
              label='Nome'
              error={errors?.name}
              errorMessage={errors?.name?.message}
              disabled={loading}
              {...register('name', {
                required: true,
                maxLength: {
                  message: 'A etiqueta pode ter no máximo 15 caracteres',
                  value: 15,
                },
              })}
            />
            <ColorPicker
              label='Destaque'
              name='color'
              defaultValue={currentColor}
              onChange={setValue}
              colorsList={defaultColorsTags}
            />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button maxWidth='100px' variant='text' color='grey.500' onClick={onClose}>
            Cancelar
          </Button>
          <Button maxWidth='70px' type='submit' disabled={loading}>
            Salvar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default ModalTags
