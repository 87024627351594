import React, { memo } from 'react'

const rotateDict = {
  bottom: '-90deg',
  right: '-180deg',
  left: '0deg',
  top: '90deg',
}

const Arrow = ({ direction = 'top', ...props }) => {
  return (
    <svg
      height='20'
      width='20'
      viewBox='0 0 20 20'
      style={{ transform: `rotate(${rotateDict[direction]})` }}
      {...props}
    >
      <path
        d='M13.0876 13.825L9.27093 10L13.0876 6.175L11.9126 5L6.9126 10L11.9126 15L13.0876 13.825Z'
        fill='#656565'
      />
    </svg>
  )
}
const Memo = memo(Arrow)
export default Memo
