import { colors } from 'configs'
import { Row, Typography } from 'design-system'
import { hexToRGBA } from 'helpers'

const SignedRow = ({ attendantName, isBlockedInteraction }) => (
  <Row
    p='2px 0px 10px 10px'
    mt={isBlockedInteraction ? '10px' : '0'}
    mb='-5px'
    borderRadius={isBlockedInteraction ? 0 : '8px 8px 0 0'}
    background={hexToRGBA(colors.orange[300], 0.08)}
  >
    <Typography fontSize='12px'>{`Assinado por ${attendantName}`}</Typography>
  </Row>
)

export default SignedRow
