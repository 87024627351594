import { useEffect, useState } from 'react'

import { Column, Icon, Popover, Row, Spinner, Typography } from 'design-system'
import CompanyItem from './CompanyItem'

import { notify } from 'helpers'

import { changeUserCompany, showCompaniesList } from 'services'

import { useAuth, useStorage } from 'hooks'
import { useLocation, useNavigate } from 'react-router-dom'

const PopoverSelectCompany = ({ open, elementRef, ...props }) => {
  const [loading, setLoading] = useState(false)
  const [listCompany, setListCompany] = useState([])

  const { setStorage } = useStorage()
  const navigate = useNavigate()
  const location = useLocation()

  const {
    userData: {
      company_user: { company },
    },
    updateLoggedUser,
    setLoadingApp,
    userActionTypes,
  } = useAuth()

  useEffect(() => {
    getInfo()
  }, [])

  const handleChangeCompany = async (company) => {
    try {
      setLoadingApp(true)
      const { data } = await changeUserCompany(company)

      const permissions = data?.session?.module_actions

      if (location?.pathname.includes('/tickets/')) navigate('/tickets')

      updateLoggedUser({
        type: userActionTypes.ADD_ITEM,
        payload: { ...data, permissions },
      })
      setStorage('current_company', { ...data, permissions })
    } catch {
      notify.error('Não foi possível alterar empresa no momento.')
    } finally {
      setLoadingApp(false)
    }
  }

  const getInfo = async () => {
    try {
      setLoading(true)
      const { data } = await showCompaniesList({ active: true })

      setListCompany(data.results)
    } catch {
      notify.error('Não foi possível listar informações.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Popover ref={elementRef} open={open} {...props}>
      <Column p='16px'>
        <Column mb='12px' alignItems='center'>
          <Icon icon='Company' />
          <Typography fontWeight='600'>{company?.name}</Typography>
          {company?.cnpj && <Typography variant='caption'>{`CNPJ: ${company?.cnpj}`}</Typography>}
        </Column>
        {loading ? (
          <Row p='8px'>
            <Spinner />
          </Row>
        ) : (
          listCompany.map((comp) => (
            <CompanyItem
              active={company?.id === comp?.id}
              company={comp}
              handleChangeCompany={handleChangeCompany}
            />
          ))
        )}
      </Column>
    </Popover>
  )
}

export default PopoverSelectCompany
