import { memo } from 'react'

const Add = (props) => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' {...props}>
      <path
        d='M10.8332 5.83366H9.1665V9.16699H5.83317V10.8337H9.1665V14.167H10.8332V10.8337H14.1665V9.16699H10.8332V5.83366ZM9.99984 1.66699C5.39984 1.66699 1.6665 5.40033 1.6665 10.0003C1.6665 14.6003 5.39984 18.3337 9.99984 18.3337C14.5998 18.3337 18.3332 14.6003 18.3332 10.0003C18.3332 5.40033 14.5998 1.66699 9.99984 1.66699ZM9.99984 16.667C6.32484 16.667 3.33317 13.6753 3.33317 10.0003C3.33317 6.32533 6.32484 3.33366 9.99984 3.33366C13.6748 3.33366 16.6665 6.32533 16.6665 10.0003C16.6665 13.6753 13.6748 16.667 9.99984 16.667Z'
        fill='#656565'
      />
    </svg>
  )
}
const Memo = memo(Add)
export default Memo
