import { memo } from 'react'

const Copy = (props) => {
  return (
    <svg height='20' width='20' viewBox='0 0 20 20' {...props}>
      <path
        d='M13.7499 0.833008H3.74992C2.83325 0.833008 2.08325 1.58301 2.08325 2.49967V14.1663H3.74992V2.49967H13.7499V0.833008ZM16.2499 4.16634H7.08325C6.16658 4.16634 5.41659 4.91634 5.41659 5.83301V17.4997C5.41659 18.4163 6.16658 19.1663 7.08325 19.1663H16.2499C17.1666 19.1663 17.9166 18.4163 17.9166 17.4997V5.83301C17.9166 4.91634 17.1666 4.16634 16.2499 4.16634ZM16.2499 17.4997H7.08325V5.83301H16.2499V17.4997Z'
        fill='#656565'
      />
    </svg>
  )
}
const Memo = memo(Copy)
export default Memo
