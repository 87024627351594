import { variant } from 'styled-system'

const variants = variant({
  prop: 'size',
  variants: {
    sm: {
      width: '40px',
      height: '40px',
      minWidth: '40px',
      minHeight: '40px',
      fontSize: '16px',
    },
    md: {
      width: '60px',
      height: '60px',
      minWidth: '60px',
      minHeight: '60px',
      fontSize: '18px',
    },
    lg: {
      width: '70px',
      height: '70px',
      minWidth: '70px',
      minHeight: '70px',
      fontSize: '20px',
    },
    110: {
      width: '110px',
      height: '110px',
    },
    xl: {
      width: '180px',
      height: '180px',
    },
  },
})

export default variants
