import { useReducer } from 'react'

export const initialValues = {
  quick_responses: [],
}

export const actionTypes = {
  LOAD_ITEMS: 'LOAD_ITEMS',
  ADD_ITEM: 'ADD_ITEM',
  UPDATE_ITEM: 'UPDATE_ITEM',
  REMOVE_ITEM: 'REMOVE_ITEM',
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.LOAD_ITEMS:
      return { ...state, quick_responses: action.payload }
    case actionTypes.ADD_ITEM:
      return {
        ...state,
        quick_responses: [...state.quick_responses, action.payload].sort(
          (itemA, itemB) => itemA.shortcut.localeCompare(itemB.shortcut)
        ),
      }
    case actionTypes.UPDATE_ITEM:
      const filter = state.quick_responses.filter(
        (item) => item.id !== action.payload.id
      )
      return {
        ...state,
        quick_responses: [...filter, action.payload].sort((itemA, itemB) =>
          itemA.shortcut.localeCompare(itemB.shortcut)
        ),
      }
    case actionTypes.REMOVE_ITEM:
      return {
        ...state,
        quick_responses: state.quick_responses.filter(
          (item) => item.id !== action.payload
        ),
      }
    default:
      return state
  }
}

export const useQuickResponsesReducer = () => useReducer(reducer, initialValues)
