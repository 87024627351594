import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  Row,
  Button,
  Typography,
  ModalFooter,
  Select,
} from 'design-system'

import { notify } from 'helpers'
import { importTemplateUuid, showConnectionsList } from 'services'

const ModalTemplatesImport = ({ onClose, dispatch, actionTypes, ...props }) => {
  const [loading, setLoading] = useState(false)
  const [connectionsList, setConnectionsList] = useState([])

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    props.open && fetchConnections()

    reset({
      uuid: '',
      connection: '',
    })

    return () => {
      reset({
        uuid: '',
        connection: '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open])

  const fetchConnections = async () => {
    try {
      setLoading(true)
      const {
        data: { results },
      } = await showConnectionsList({ page_size: 1000 })

      setConnectionsList(results)
    } catch {
      notify.error('Não foi possível recuperar a lista de tags.')
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = async (values) => {
    try {
      setLoading(true)

      values.connection = values.connection.id

      const { data } = await importTemplateUuid(values)

      dispatch({
        type: actionTypes.ADD_ITEM,
        payload: data,
      })
      notify.success('Novo template adicionado com sucesso.')
      onClose()
    } catch {
      notify.error('Não foi possível adicionar template.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal size='sm' onClose={onClose} {...props}>
      <ModalHeader onClose={onClose}>
        <Typography variant='title' color='primary'>
          Importar template
        </Typography>
      </ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Row>
            <Select
              label='Conexões'
              options={connectionsList}
              name='connection'
              control={control}
              error={errors?.connection}
              required
              keys={{ label: 'name', value: 'id' }}
            />
          </Row>
          <Row mt='16px'>
            <Input
              label='Código único'
              error={errors?.uuid}
              disabled={loading}
              {...register('uuid', { required: true })}
            />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button maxWidth='100px' variant='text' color='grey.500' onClick={onClose}>
            Cancelar
          </Button>
          <Button maxWidth='70px' type='submit' disabled={loading}>
            Importar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default ModalTemplatesImport
