
      import React, { memo } from 'react';

      const Time = (props) => {
        return (
          <svg height="20" width="20"  viewBox="0 0 20 20" {...props}>
	<path d="M9.99984 1.66699C5.4165 1.66699 1.6665 5.41699 1.6665 10.0003C1.6665 14.5837 5.4165 18.3337 9.99984 18.3337C14.5832 18.3337 18.3332 14.5837 18.3332 10.0003C18.3332 5.41699 14.5832 1.66699 9.99984 1.66699ZM9.99984 16.667C6.32484 16.667 3.33317 13.6753 3.33317 10.0003C3.33317 6.32533 6.32484 3.33366 9.99984 3.33366C13.6748 3.33366 16.6665 6.32533 16.6665 10.0003C16.6665 13.6753 13.6748 16.667 9.99984 16.667ZM10.4165 5.83366H9.1665V10.8337L13.4998 13.5003L14.1665 12.417L10.4165 10.167V5.83366Z" fill="#656565"/>
</svg>
        );
      };
      const Memo = memo(Time)
      export default Memo
    