import { Column, Skeleton } from 'design-system'

const SkeletonMessages = () => {
  return (
    <Column p='10px'>
      <Column mt='10px' alignItems='center'>
        <Skeleton maxWidth='250px' height='50px' />
      </Column>
      <Column mt='10px' alignItems='flex-end'>
        <Skeleton maxWidth='250px' height='80px' />
      </Column>
      <Column mt='10px' alignItems='flex-start'>
        <Skeleton maxWidth='250px' height='150px' />
      </Column>
      <Column mt='10px' alignItems='flex-end'>
        <Skeleton maxWidth='250px' height='80px' />
      </Column>
      <Column mt='10px' alignItems='flex-start'>
        <Skeleton maxWidth='250px' height='80px' />
      </Column>
      <Column mt='10px' alignItems='flex-end'>
        <Skeleton maxWidth='250px' height='40px' />
      </Column>
      <Column mt='10px' alignItems='flex-end'>
        <Skeleton maxWidth='250px' height='60px' />
      </Column>
      <Column mt='10px' alignItems='center'>
        <Skeleton maxWidth='250px' height='50px' />
      </Column>
      <Column mt='10px' alignItems='flex-start'>
        <Skeleton maxWidth='250px' height='80px' />
      </Column>
      <Column mt='10px' alignItems='flex-end'>
        <Skeleton maxWidth='100%' height='150px' />
      </Column>
    </Column>
  )
}

export default SkeletonMessages
