
      import React, { memo } from 'react';

      const Close = (props) => {
        return (
          <svg height="20" width="20"  viewBox="0 0 20 20" {...props}>
	<path d="M15.8334 5.34199L14.6584 4.16699L10.0001 8.82533L5.34175 4.16699L4.16675 5.34199L8.82508 10.0003L4.16675 14.6587L5.34175 15.8337L10.0001 11.1753L14.6584 15.8337L15.8334 14.6587L11.1751 10.0003L15.8334 5.34199Z" fill="#656565"/>
</svg>
        );
      };
      const Memo = memo(Close)
      export default Memo
    