
      import React, { memo } from 'react';

      const Alert = (props) => {
        return (
          <svg height="20" width="20"  viewBox="0 0 20 20" {...props}>
	<path d="M9.1665 12.5003H10.8332V14.167H9.1665V12.5003ZM9.1665 5.83366H10.8332V10.8337H9.1665V5.83366ZM9.9915 1.66699C5.3915 1.66699 1.6665 5.40033 1.6665 10.0003C1.6665 14.6003 5.3915 18.3337 9.9915 18.3337C14.5998 18.3337 18.3332 14.6003 18.3332 10.0003C18.3332 5.40033 14.5998 1.66699 9.9915 1.66699ZM9.99984 16.667C6.3165 16.667 3.33317 13.6837 3.33317 10.0003C3.33317 6.31699 6.3165 3.33366 9.99984 3.33366C13.6832 3.33366 16.6665 6.31699 16.6665 10.0003C16.6665 13.6837 13.6832 16.667 9.99984 16.667Z" fill="#656565"/>
</svg>
        );
      };
      const Memo = memo(Alert)
      export default Memo
    