import Typography from '../Typography'

const HighlightedText = ({ hightlight, text }) => (
  <Typography fontWeight='600'>
    {`${hightlight}: `}
    <Typography as='span'>{text}</Typography>
  </Typography>
)

HighlightedText.defaultProps = {
  hightlight: 'Strong',
  text: 'text',
}

export default HighlightedText
