import { memo } from 'react'

const Check = (props) => {
  return (
    <svg height='20' width='20' viewBox='0 0 20 20' {...props}>
      <path
        d='M7.33317 13.2503L3.83317 9.75033L2.6665 10.917L7.33317 15.5837L17.3332 5.58366L16.1665 4.41699L7.33317 13.2503Z'
        fill='#656565'
      />
    </svg>
  )
}
const Memo = memo(Check)
export default Memo
