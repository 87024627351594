import { Fragment } from 'react'
import { Typography } from 'design-system'

import { breakLineRegex, strongRegex } from 'utils'

const formatMessageText = (text) => {
  const lines = text.split(breakLineRegex)

  return lines.map((line, lineIndex) => {
    const parts = line.split(strongRegex)

    return (
      <Fragment key={lineIndex}>
        {parts.map((part, partIndex) =>
          partIndex % 2 === 1 ? (
            <Typography
              as='span'
              fontWeight='600'
              fontSize='14px'
              color='grey.500'
              key={`${lineIndex}-${partIndex}`}
            >
              {part}
            </Typography>
          ) : (
            part
          ),
        )}
        {lineIndex < lines.length - 1 && <br />}
      </Fragment>
    )
  })
}

export default formatMessageText
