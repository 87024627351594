import { useNavigate } from 'react-router-dom'

import { Button, Column, Image, Typography } from 'design-system'
import { robotBlocked } from 'assets/ilustrations'

const UnauthorizedPermission = (props) => {
  const navigate = useNavigate()

  return (
    <Column
      width='100%'
      mt='200px'
      height='auto'
      alignItems='center'
      justifyContent='center'
      p='24px'
      {...props}
    >
      <Image maxWidth='330px' width='100%' src={robotBlocked} />
      <Typography mt='56px' fontWeight='600' fontSize='24px'>
        Acesso negado
      </Typography>
      <Typography width='415px' textAlign='center' fontSize='16px' mt='16px'>
        Você não tem permissão para acessar esta área ou realizar esta ação.
      </Typography>
      {!props.noRedirect && (
        <Button maxWidth='320px' mt='24px' onClick={() => navigate('/settings/')}>
          Voltar para página de configuração
        </Button>
      )}
    </Column>
  )
}

export default UnauthorizedPermission
