
      import React, { memo } from 'react';

      const Message = (props) => {
        return (
          <svg height="20" width="20"  viewBox="0 0 20 20" {...props}>
	<g clipPath="url(#clip0_212_3036)">
		<path d="M12.5001 3.33366V9.16699H4.30841L3.33341 10.142V3.33366H12.5001ZM13.3334 1.66699H2.50008C2.04175 1.66699 1.66675 2.04199 1.66675 2.50033V14.167L5.00008 10.8337H13.3334C13.7917 10.8337 14.1667 10.4587 14.1667 10.0003V2.50033C14.1667 2.04199 13.7917 1.66699 13.3334 1.66699ZM17.5001 5.00033H15.8334V12.5003H5.00008V14.167C5.00008 14.6253 5.37508 15.0003 5.83342 15.0003H15.0001L18.3334 18.3337V5.83366C18.3334 5.37533 17.9584 5.00033 17.5001 5.00033Z" fill="#656565"/>
	</g>
	<defs>
		<clipPath id="clip0_212_3036">
			<rect height="20" width="20" fill="white"/>
		</clipPath>
	</defs>
</svg>
        );
      };
      const Memo = memo(Message)
      export default Memo
    