import React, { forwardRef } from 'react'
import styled from 'styled-components'
import {
  space,
  layout,
  color,
  flexbox,
  compose,
  border,
  shadow,
} from 'styled-system'

const style = compose(space, layout, color, flexbox, border, shadow)

const Base = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${style};
`

const Form = forwardRef(({ children, ...props }, ref) => (
  <Base ref={ref} {...props}>
    {children}
  </Base>
))

export default Form
