import { memo } from 'react'

const Share = (props) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M7 7.5V4.5L0 11.5L7 18.5V15.5L3 11.5L7 7.5ZM13 8.5V4.5L6 11.5L13 18.5V14.4C18 14.4 21.5 16 24 19.5C23 14.5 20 9.5 13 8.5Z'
        fill='black'
      />
    </svg>
  )
}
const Memo = memo(Share)
export default Memo
