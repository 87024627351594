import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
  Typography,
  ModalFooter,
  SelectAsync,
  Select,
} from 'design-system'

import { showDepartmentsListSelect, showCompanyUsersListSelect, updateTicket } from 'services'
import { notify } from 'helpers'

const ModalAcceptTicket = ({
  onClose,
  ticket,
  dispatch,
  actionTypes,
  setLoading,
  loading,
  ...props
}) => {
  const [attendants, setAttendants] = useState([])

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: { active: true } })

  const watchDepartment = watch('department')

  useEffect(() => {
    watchDepartment && getUserOptions(watchDepartment)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDepartment])

  useEffect(() => {
    ticket?.department && getUserOptions(ticket?.department?.id)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket])

  const onSubmit = async (values) => {
    try {
      values.attendant = values.attendant.id
      values.department = values.department.id

      const { data } = await updateTicket(ticket.id, { ...values, status: 'attending' })

      dispatch({
        type: actionTypes.UPDATE_SELECTED_TICKET,
        payload: data,
      })

      notify.success('Conversa atribuída com sucesso.')
      onClose()
    } catch {
      notify.error('Não foi possível salvar configuração da etiqueta.')
    }
  }

  const getUserOptions = async (department) => {
    try {
      const { data } = await showCompanyUsersListSelect({
        page_size: 100,
        active: true,
        departments: department?.id,
      })

      const findCurrentUser = data.results.find((i) => i.id === ticket?.attendant?.id)

      setValue('attendant', findCurrentUser || null)

      setAttendants(() => data.results)
    } catch {
      notify.error('Não foi possível resgatar lista de departamentos.')
    }
  }

  const getDepartmentsOptions = async (search) =>
    (await showDepartmentsListSelect({ page_size: 100, active: true })).data.results

  return (
    <Modal size='sm' open={ticket} onClose={onClose} {...props}>
      <ModalHeader onClose={onClose}>
        <Typography variant='title' color='primary'>
          Atribuir Responsável
        </Typography>
      </ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Row width='100%'>
            <SelectAsync
              label='Departamento'
              cacheOptions
              defaultOptions
              loadOptions={getDepartmentsOptions}
              defaultValue={ticket?.department}
              keys={{ label: 'name', value: 'id' }}
              name='department'
              control={control}
              error={errors?.department}
              required
            />
          </Row>
          <Row mt='16px' width='100%'>
            <Select
              label='Atendente responsável'
              defaultOptions
              options={attendants}
              defaultValue={ticket?.attendant}
              keys={{ label: 'name', value: 'id' }}
              name='attendant'
              control={control}
              error={errors?.attendant}
              required
            />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button maxWidth='100px' variant='text' color='grey.300' onClick={onClose}>
            Cancelar
          </Button>
          <Button maxWidth='70px' type='submit' disabled={loading}>
            Salvar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default ModalAcceptTicket
