import Column from '../Column'

const ColorChip = ({ color, ...props }) => (
  <Column backgroundColor={color} {...props} />
)

ColorChip.defaultProps = {
  width: '50px',
  height: '15px',
  borderRadius: '10px',
  color: '#e6e6e6',
}

export default ColorChip
