import Chart from 'react-apexcharts'

import { theme } from 'configs'

const GraphicGauge = ({ series, colors, ...props }) => {
  const options = {
    chart: {
      type: 'radialBar',
      height: 'auto',
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          size: '60%',
        },
        track: {
          background: theme.colors.grey[200],
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: true,
            fontSize: '32px',
            color: theme.colors.primary,
            formatter: (value) => `${value}%`,
          },
        },
      },
    },
    fill: {
      colors,
    },
  }

  return (
    <Chart
      options={options}
      series={series}
      type='radialBar'
      height={350}
      {...props}
    />
  )
}

GraphicGauge.defaultProps = {
  series: [],
  colors: [theme.colors.primary],
}

export default GraphicGauge
