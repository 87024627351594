import React, { memo } from 'react'

const TimerRefresh = (props) => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
      <path
        d='M15.8333 4.16667V15.8333H4.16667V4.16667H15.8333ZM15.8333 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5ZM11.7833 9.88333L9.28333 13.1083L7.5 10.95L5 14.1667H15L11.7833 9.88333Z'
        fill='#656565'
      />
    </svg>
  )
}
const Memo = memo(TimerRefresh)
export default Memo
