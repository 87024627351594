import { memo } from 'react'

const Download = (props) => {
  return (
    <svg height='20' width='20' viewBox='0 0 20 20' {...props}>
      <path
        d='M17.1167 4.35833L15.9583 2.95833C15.7333 2.675 15.3917 2.5 15 2.5H5C4.60833 2.5 4.26667 2.675 4.03333 2.95833L2.88333 4.35833C2.64167 4.64167 2.5 5.01667 2.5 5.41667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V5.41667C17.5 5.01667 17.3583 4.64167 17.1167 4.35833ZM5.2 4.16667H14.8L15.475 4.975H4.53333L5.2 4.16667ZM4.16667 15.8333V6.66667H15.8333V15.8333H4.16667ZM11.2083 8.33333H8.79167V10.8333H6.66667L10 14.1667L13.3333 10.8333H11.2083V8.33333Z'
        fill='#656565'
      />
    </svg>
  )
}
const Memo = memo(Download)
export default Memo
