import { Navigate, Outlet } from 'react-router-dom'
import Cookies from 'js-cookie'

import { system } from 'configs'

const AuthGuard = () => {
  const { COOKIE_TOKEN } = system

  const isAuthenticated = !!Cookies.get(COOKIE_TOKEN)

  if (isAuthenticated) {
    return <Outlet />
  } else {
    return <Navigate to='/login' />
  }
}

export default AuthGuard
