import styled from 'styled-components'

import { Column, Icon, Tooltip } from 'design-system'

const HeaderButtons = ({ actions }) => {
  return (
    <Column position='relative'>
      <Base>
        {actions.map((action, idx) => (
          <Tooltip key={idx} position='bottom' title={action?.tooltip || ''}>
            <Custom
              padding='8px'
              onClick={() => (action?.disabled ? undefined : action.handler())}
              disabled={action.disabled}
              active={action.active}
            >
              <Icon icon={action.icon} color={action?.disabled ? 'text' : 'primary'} />
            </Custom>
          </Tooltip>
        ))}
      </Base>
    </Column>
  )
}

const Base = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(197, 192, 227, 0.1);
  border-radius: 8px;
  border: 1px solid #e8e8e8;
`

const Custom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;

  border-radius: 8px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  background-color: ${({ active }) => (active ? 'rgba(197, 192, 227, 0.3)' : '')};

  &:hover {
    background: rgba(197, 192, 227, 0.3);
  }
`

export default HeaderButtons
