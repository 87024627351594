import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

import { Popover } from 'design-system'

const PopoverEmoji = ({ elementRef, open, onEmojiSelect, ...props }) => {
  return (
    <Popover ref={elementRef} open={open} {...props}>
      <Picker data={data} theme='light' onEmojiSelect={(emoji) => onEmojiSelect(emoji.native)} />
    </Popover>
  )
}

export default PopoverEmoji
