import { useReducer } from 'react'

export const initialValues = {
  contacts: [],
}

export const actionTypes = {
  LOAD_ITEMS: 'LOAD_ITEMS',
  ADD_ITEM: 'ADD_ITEM',
  UPDATE_ITEM: 'UPDATE_ITEM',
  REMOVE_ITEM: 'REMOVE_ITEM',
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.LOAD_ITEMS:
      return { ...state, contacts: action.payload }
    case actionTypes.ADD_ITEM:
      return {
        ...state,
        contacts: [...state.contacts, action.payload].sort((itemA, itemB) =>
          itemA.name.localeCompare(itemB.name),
        ),
      }
    case actionTypes.UPDATE_ITEM:
      const filter = state.contacts.filter((item) => item.id !== action.payload.id)
      return {
        ...state,
        contacts: [...filter, action.payload].sort((itemA, itemB) =>
          itemA.name.localeCompare(itemB.name),
        ),
      }
    case actionTypes.REMOVE_ITEM:
      return {
        ...state,
        contacts: state.contacts.filter((contact) => contact.id !== action.payload),
      }
    default:
      return state
  }
}

export const useContactsReducer = () => useReducer(reducer, initialValues)
