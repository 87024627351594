import { memo } from 'react'

const Edit2 = (props) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <mask x='0' y='0' width='24' height='24'>
        <rect width='24' height='24' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_3302_5311)'>
        <path
          d='M4.79775 21C4.30337 21 3.88015 20.824 3.52809 20.4719C3.17603 20.1199 3 19.6966 3 19.2022V6.61798C3 6.1236 3.17603 5.70037 3.52809 5.34831C3.88015 4.99625 4.30337 4.82022 4.79775 4.82022H12.8202L11.0225 6.61798H4.79775V19.2022H17.382V12.9551L19.1798 11.1573V19.2022C19.1798 19.6966 19.0037 20.1199 18.6517 20.4719C18.2996 20.824 17.8764 21 17.382 21H4.79775ZM8.39326 15.6067V11.7865L16.6405 3.53933C16.8202 3.35955 17.0225 3.22472 17.2472 3.13483C17.4719 3.04494 17.6966 3 17.9214 3C18.1611 3 18.3895 3.04494 18.6067 3.13483C18.824 3.22472 19.0225 3.35955 19.2022 3.53933L20.4607 4.82022C20.6255 5 20.7528 5.1985 20.8427 5.41573C20.9326 5.63296 20.9775 5.85393 20.9775 6.07865C20.9775 6.30337 20.9363 6.52434 20.8539 6.74157C20.7715 6.9588 20.6405 7.1573 20.4607 7.33708L12.2135 15.6067H8.39326ZM10.191 13.809H11.4494L16.6629 8.59551L16.0337 7.96629L15.382 7.33708L10.191 12.5281V13.809Z'
          fill='#7F7F7F'
        />
      </g>
    </svg>
  )
}
const Memo = memo(Edit2)
export default Memo
