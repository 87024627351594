const formatPermissions = (arr) =>
  arr.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.module.name]: !acc[cur.module.name]
        ? [
            {
              id: cur.id,
              key: cur.name,
              label: cur.description,
              selected: cur.selected,
            },
          ]
        : [
            ...acc[cur.module.name],
            {
              id: cur.id,
              key: cur.name,
              label: cur.description,
              selected: cur.selected,
            },
          ],
    }),
    {}
  )

export default formatPermissions
