import { Column, Row, Skeleton } from 'design-system'

const SkeletonHeader = () => {
  return (
    <Row width='100%' gap='8px' alignItems='center'>
      <Skeleton borderRadius='16px' width='60px' height='60px' />
      <Column gap='8px'>
        <Skeleton width='150px' height='15px' />
        <Skeleton width='250px' height='10px' />
      </Column>
    </Row>
  )
}

export default SkeletonHeader
