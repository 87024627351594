import React from 'react'

import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Typography } from 'design-system'

const ModalConfirmation = ({
  title,
  actions,
  onClose,
  message,
  content,
  handler,
  type,
  ...props
}) => {
  return (
    <Modal size='md' onClose={onClose} {...props}>
      <ModalHeader onClose={onClose}>
        <Typography
          fontWeight='600'
          fontSize='18px'
          color='primary'
          maxWidth='800px'
          overflow='hidden'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          wordBreak='break-word'
        >
          {title}
        </Typography>
      </ModalHeader>
      <ModalBody>
        <Typography>{message}</Typography>
        {content ? content : ''}
      </ModalBody>
      <ModalFooter>
        <Button maxWidth='100px' variant='text' color='grey.500' onClick={onClose}>
          Cancelar
        </Button>
        <Button maxWidth='100px' color={type} onClick={handler}>
          Continuar
        </Button>
      </ModalFooter>
    </Modal>
  )
}

ModalConfirmation.defaultProps = {
  size: 'md',
  content: null,
  handler: () => {},
}

export default ModalConfirmation
