
      import React, { memo } from 'react';

      const Whatsapp = (props) => {
        return (
          <svg height="20" width="20"  viewBox="0 0 20 20" {...props}>
	<g clipPath="url(#clip0_212_3025)">
		<path d="M15.2232 4.70982C13.8504 3.30357 11.9754 2.5 9.96652 2.5C5.8817 2.5 2.53348 5.84821 2.53348 9.93304C2.53348 11.2723 2.90179 12.5446 3.53795 13.6496L2.5 17.5L6.41741 16.4955C7.52232 17.0647 8.72768 17.3996 9.96652 17.3996C14.0848 17.3996 17.5 14.0513 17.5 9.96652C17.5 7.95759 16.6295 6.11607 15.2232 4.70982ZM9.96652 16.1272C8.86161 16.1272 7.79018 15.8259 6.8192 15.2567L6.6183 15.1228L4.27455 15.7589L4.91071 13.4821L4.7433 13.2478C4.14062 12.2433 3.8058 11.1049 3.8058 9.93304C3.8058 6.55134 6.58482 3.77232 10 3.77232C11.6406 3.77232 13.1808 4.40848 14.3527 5.58036C15.5246 6.75223 16.2277 8.29241 16.2277 9.96652C16.2277 13.3482 13.3817 16.1272 9.96652 16.1272ZM13.3817 11.5067C13.1808 11.4062 12.2768 10.971 12.1094 10.904C11.942 10.8371 11.808 10.8036 11.6741 11.0045C11.5737 11.1719 11.2054 11.6071 11.1049 11.7411C10.971 11.8415 10.8705 11.875 10.7031 11.7746C9.59821 11.2388 8.89509 10.8036 8.15848 9.56473C7.95759 9.22991 8.35938 9.26339 8.6942 8.56027C8.76116 8.42634 8.72768 8.32589 8.6942 8.22545C8.66071 8.125 8.25893 7.22098 8.125 6.85268C7.95759 6.48437 7.82366 6.51786 7.68973 6.51786C7.58929 6.51786 7.45536 6.51786 7.35491 6.51786C7.22098 6.51786 7.02009 6.55134 6.85268 6.75223C6.68527 6.95312 6.21652 7.38839 6.21652 8.29241C6.21652 9.22991 6.85268 10.1004 6.95312 10.2344C7.05357 10.3348 8.25893 12.2098 10.1339 13.0134C11.3058 13.5491 11.7746 13.5826 12.3772 13.4821C12.7121 13.4487 13.4487 13.0469 13.6161 12.6116C13.7835 12.1763 13.7835 11.808 13.7165 11.7411C13.683 11.6406 13.5491 11.6071 13.3817 11.5067Z" fill="#656565"/>
	</g>
	<defs>
		<clipPath id="clip0_212_3025">
			<rect height="20" width="20" fill="white"/>
		</clipPath>
	</defs>
</svg>
        );
      };
      const Memo = memo(Whatsapp)
      export default Memo
    