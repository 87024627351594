import UnauthorizedPermission from 'components/UnauthorizedPermission'
import UnauthorizedWorkhours from 'components/UnauthorizedWorkhours'
import { isOutsideWorkHours } from 'helpers'
import { useAuth } from 'hooks'

const ProtectedComponent = ({ customProtected, allowedRoles, children, unauthorizedComponent }) => {
  const { userData } = useAuth()

  if (customProtected) return unauthorizedComponent || <></>

  if (
    !userData?.company_user?.owner &&
    userData?.company_user?.company?.settings?.force_working_schedule &&
    isOutsideWorkHours(userData?.company_user?.working_schedules)
  ) {
    return unauthorizedComponent ? <UnauthorizedWorkhours /> : <></>
  }

  if (
    !userData ||
    (!userData?.company_user.owner && !userData.permissions.find((l) => allowedRoles?.includes(l)))
  ) {
    return unauthorizedComponent ? <UnauthorizedPermission /> : <></>
  }

  return <>{children}</>
}

ProtectedComponent.defaultProps = {
  allowedRoles: [],
  unauthorizedComponent: true,
  customProtected: false,
}

export default ProtectedComponent
