import { Image, Typography, Icon, Row, Card } from 'design-system'

const GraphicCard = ({
  icon,
  label,
  status,
  statusValue,
  value,
  alt,
  ...props
}) => {
  return (
    <Card
      flexGrow='1'
      minWidth={['100%', '100%', '280px', '280px']}
      maxWidth={['100%', '100%', '100%', '280px']}
      maxHeight='180px'
      {...props}
    >
      <Image width='40px' src={icon} alt={alt} />
      <Typography mt='16px' fontSize='16px'>
        {label}
      </Typography>
      <Typography mt='6px' fontSize='24px'>
        {value}
      </Typography>
      <Row mt='16px' gap='8px'>
        <Icon
          icon={graphicDict[status].icon}
          color={graphicDict[status].color}
        />
        <Typography
          fontSize='14px'
          color={graphicDict[status].color}
          fontWeight='600'
        >
          {statusValue}
        </Typography>
      </Row>
    </Card>
  )
}

const graphicDict = {
  up: { icon: 'GraphicArrowUp', color: '#00DEA3' },
  down: { icon: 'GraphicArrowDown', color: '#F23985' },
}

GraphicCard.defaultProps = {
  icon: null,
  alt: 'Logotipo da plataforma utilizada nos tickets',
  label: '',
  value: 0,
  status: 'up',
}

export default GraphicCard
