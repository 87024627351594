import { useClickout } from 'hooks'

import PopoverUserProfile from 'components/PopoverUserProfile'
import { Avatar, Icon, Row, Typography } from 'design-system'

const UserMenu = ({ user }) => {
  const { triggerRef, elementRef, openedState } = useClickout(false)

  return (
    <Row position='relative' minWidth='150px'>
      <Row className='cursor-pointer' alignItems='center' gap='8px' ref={triggerRef}>
        <Avatar src={user?.picture} alt={user?.name} square borderRadius='4px' />
        <Typography
          maxWidth='150px'
          textOverflow='ellipsis'
          overflow='hidden'
          whiteSpace='nowrap'
          color='white'
        >
          {user?.name}
        </Typography>
        <Icon icon='Arrow' direction='bottom' color='white' />
      </Row>
      {openedState && (
        <PopoverUserProfile
          open={openedState}
          elementRef={elementRef}
          user={user}
          top='50px'
          right='0'
        />
      )}
    </Row>
  )
}

export default UserMenu
