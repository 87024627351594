import styled, { keyframes } from 'styled-components'
import { space, layout, color, border, compose } from 'styled-system'

const styles = compose(space, layout, color, border)

const fade = keyframes`
  0% {
   opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`

const SkeletonBase = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.grey[50]};
  animation: ${fade} 2s ease-in-out 0.5s infinite normal none running;

  ${styles};
`

const Skeleton = ({ ...props }) => <SkeletonBase {...props} />

Skeleton.defaultProps = {
  width: '100%',
  height: '20px',
  borderRadius: '4px',
}

export default Skeleton
