import React from 'react'
import styled from 'styled-components'
import { typography, space, color, layout, compose } from 'styled-system'
import { variants } from './variants'

const style = compose(typography, space, color, layout)

const Base = styled.p`
  cursor: ${({ cursor = 'initial' }) => cursor};

  word-break: ${({ wordBreak = 'break-word' }) => wordBreak};
  white-space: ${({ whiteSpace = 'initial' }) => whiteSpace};
  text-overflow: ${({ textOverflow = 'initial' }) => textOverflow};
  text-decoration: ${({ textDecoration = 'initial' }) => textDecoration};
  user-select: ${({ notSelectable }) => (notSelectable ? 'none' : 'initial')};

  ${({ ellipsis }) =>
    ellipsis
      ? `
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `
      : ''}

  ${variants}
  ${style};
`

const Typography = ({ children, ...rest }) => <Base {...rest}>{children}</Base>

Typography.defaultProps = {
  cursor: 'initial',
  variant: 'paragraph',
  color: 'text',
  as: 'p',
  ellipsis: false,
}
export default Typography
