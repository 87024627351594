import { memo } from 'react'

const UserAdd = (props) => {
  return (
    <svg height='20' width='20' viewBox='0 0 20 20' {...props}>
      <path
        d='M15.0002 11.6663V9.16634H12.5002V7.49967H15.0002V4.99967H16.6668V7.49967H19.1668V9.16634H16.6668V11.6663H15.0002ZM7.50016 9.99967C6.5835 9.99967 5.79877 9.67329 5.146 9.02051C4.49322 8.36773 4.16683 7.58301 4.16683 6.66634C4.16683 5.74967 4.49322 4.96495 5.146 4.31217C5.79877 3.6594 6.5835 3.33301 7.50016 3.33301C8.41683 3.33301 9.20155 3.6594 9.85433 4.31217C10.5071 4.96495 10.8335 5.74967 10.8335 6.66634C10.8335 7.58301 10.5071 8.36773 9.85433 9.02051C9.20155 9.67329 8.41683 9.99967 7.50016 9.99967ZM0.833496 16.6663V14.333C0.833496 13.8608 0.955024 13.4268 1.19808 13.0309C1.44113 12.6351 1.76405 12.333 2.16683 12.1247C3.02794 11.6941 3.90294 11.3712 4.79183 11.1559C5.68072 10.9406 6.5835 10.833 7.50016 10.833C8.41683 10.833 9.31961 10.9406 10.2085 11.1559C11.0974 11.3712 11.9724 11.6941 12.8335 12.1247C13.2363 12.333 13.5592 12.6351 13.8022 13.0309C14.0453 13.4268 14.1668 13.8608 14.1668 14.333V16.6663H0.833496ZM2.50016 14.9997H12.5002V14.333C12.5002 14.1802 12.462 14.0413 12.3856 13.9163C12.3092 13.7913 12.2085 13.6941 12.0835 13.6247C11.3335 13.2497 10.5766 12.9684 9.81266 12.7809C9.04877 12.5934 8.27794 12.4997 7.50016 12.4997C6.72238 12.4997 5.95155 12.5934 5.18766 12.7809C4.42377 12.9684 3.66683 13.2497 2.91683 13.6247C2.79183 13.6941 2.69113 13.7913 2.61475 13.9163C2.53836 14.0413 2.50016 14.1802 2.50016 14.333V14.9997ZM7.50016 8.33301C7.9585 8.33301 8.35086 8.16981 8.67725 7.84342C9.00363 7.51704 9.16683 7.12467 9.16683 6.66634C9.16683 6.20801 9.00363 5.81565 8.67725 5.48926C8.35086 5.16287 7.9585 4.99967 7.50016 4.99967C7.04183 4.99967 6.64947 5.16287 6.32308 5.48926C5.99669 5.81565 5.8335 6.20801 5.8335 6.66634C5.8335 7.12467 5.99669 7.51704 6.32308 7.84342C6.64947 8.16981 7.04183 8.33301 7.50016 8.33301Z'
        fill='#656565'
      />
    </svg>
  )
}
const Memo = memo(UserAdd)
export default Memo
