import { Row, Typography } from 'design-system'

const ErrorRow = ({ errors }) =>
  errors?.body && (
    <Row bottom='47px' right='20px' position='absolute'>
      <Typography color='danger' variant='caption'>
        {errors?.body?.message}
      </Typography>
    </Row>
  )

export default ErrorRow
