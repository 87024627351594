
      import React, { memo } from 'react';

      const AccountCircle = (props) => {
        return (
          <svg height="20" width="20"  viewBox="0 0 20 20" {...props}>
	<path d="M4.87508 14.2503C5.58341 13.7087 6.37508 13.2816 7.25008 12.9691C8.12508 12.6566 9.04175 12.5003 10.0001 12.5003C10.9584 12.5003 11.8751 12.6566 12.7501 12.9691C13.6251 13.2816 14.4167 13.7087 15.1251 14.2503C15.6112 13.6809 15.9897 13.035 16.2605 12.3128C16.5313 11.5906 16.6667 10.8198 16.6667 10.0003C16.6667 8.1531 16.0174 6.58019 14.7188 5.28158C13.4202 3.98296 11.8473 3.33366 10.0001 3.33366C8.15286 3.33366 6.57994 3.98296 5.28133 5.28158C3.98272 6.58019 3.33341 8.1531 3.33341 10.0003C3.33341 10.8198 3.46883 11.5906 3.73966 12.3128C4.0105 13.035 4.38897 13.6809 4.87508 14.2503ZM10.0001 10.8337C9.18064 10.8337 8.48966 10.5524 7.92716 9.98991C7.36466 9.42741 7.08341 8.73644 7.08341 7.91699C7.08341 7.09755 7.36466 6.40658 7.92716 5.84408C8.48966 5.28158 9.18064 5.00033 10.0001 5.00033C10.8195 5.00033 11.5105 5.28158 12.073 5.84408C12.6355 6.40658 12.9167 7.09755 12.9167 7.91699C12.9167 8.73644 12.6355 9.42741 12.073 9.98991C11.5105 10.5524 10.8195 10.8337 10.0001 10.8337ZM10.0001 18.3337C8.8473 18.3337 7.76397 18.1149 6.75008 17.6774C5.73619 17.2399 4.85425 16.6462 4.10425 15.8962C3.35425 15.1462 2.7605 14.2642 2.323 13.2503C1.8855 12.2364 1.66675 11.1531 1.66675 10.0003C1.66675 8.84755 1.8855 7.76421 2.323 6.75033C2.7605 5.73644 3.35425 4.85449 4.10425 4.10449C4.85425 3.35449 5.73619 2.76074 6.75008 2.32324C7.76397 1.88574 8.8473 1.66699 10.0001 1.66699C11.1529 1.66699 12.2362 1.88574 13.2501 2.32324C14.264 2.76074 15.1459 3.35449 15.8959 4.10449C16.6459 4.85449 17.2397 5.73644 17.6772 6.75033C18.1147 7.76421 18.3334 8.84755 18.3334 10.0003C18.3334 11.1531 18.1147 12.2364 17.6772 13.2503C17.2397 14.2642 16.6459 15.1462 15.8959 15.8962C15.1459 16.6462 14.264 17.2399 13.2501 17.6774C12.2362 18.1149 11.1529 18.3337 10.0001 18.3337Z" fill="#656565"/>
</svg>
        );
      };
      const Memo = memo(AccountCircle)
      export default Memo
    