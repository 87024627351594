import { memo } from 'react'

const ArrowRight = (props) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M8.29492 16.59L12.8749 12L8.29492 7.41L9.70492 6L15.7049 12L9.70492 18L8.29492 16.59Z'
        fill='#656565'
      />
    </svg>
  )
}
const Memo = memo(ArrowRight)
export default Memo
