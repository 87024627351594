import { clientApi } from 'providers'

export const showQuickAnswersInfo = (id) => clientApi.get(`/quick-answers/${id}/`)

export const showQuickAnswersList = (params) => clientApi.get(`/quick-answers/`, { params })

export const createQuickAnswer = (data) => clientApi.post('/quick-answers/', data)

export const updatedQuickAnswer = (id, data) => clientApi.put(`/quick-answers/${id}/`, data)

export const deleteQuickAnswer = (id) => clientApi.delete(`/quick-answers/${id}/`)

//# TEXT VARIANTS
export const showTextVariables = (params) => clientApi.get(`/text-variables/`, { params })
