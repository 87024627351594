export const removeMask = (value) => value.replace(/[^\w]/g, '')

export const formattedCurrency = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value)

export const formattedPhone = (value) =>
  value && value?.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')

export const regexPatterns = {
  integer: {
    value: /^\d+$/,
    message: 'Este campo aceita apenas números inteiros. Ex. 99',
  },
  decimal: {
    value: /^\d*\.\d+$/,
    message: 'Este campo aceita apenas números decimais. Ex. 99.99',
  },
  boolean: {
    value: /^(true|false)$/,
    message: 'Este campo aceita apenas verdadeiro ou falso.',
  },
  text: {
    value: '',
    message:
      'Este campo aceita números, textos e caracteres, porém será tratado apenas como texto simples. Ex. ChatGDS #2024',
  },
}
