import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { Row, Form, Input, Button, Image, Typography, Column, Icon } from 'design-system'

import { registerUser } from 'services'

import { useStorage } from 'hooks'

import { chatGDSLogo1 } from 'assets'
import { useNavigate } from 'react-router-dom'
import { notify } from 'helpers'

const Register = () => {
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [success, setSuccess] = useState(false)

  const { setStorage } = useStorage()

  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const password = watch('password', '')

  const onSubmit = async (values) => {
    try {
      setLoading(true)

      setStorage('credential', values.email)

      await registerUser(values)

      setSuccess(true)
    } catch {
      notify.error('Não foi possível criar seu cadastro agora.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Row
      alignItems='center'
      justifyContent='center'
      height='100%'
      p='20px'
      minHeight='100vh'
      backgroundColor='rgb(99,81,172)'
      backgroundImage='linear-gradient(90deg, rgba(17,23,42,1) 0%, rgba(99,81,172,1) 52%, rgba(17,23,42,1) 95%)'
    >
      {success ? (
        <Column
          alignItems='center'
          maxWidth='380px'
          p='30px'
          border='1px solid #E8E8E8'
          borderRadius='5px'
          backgroundColor='#fff'
        >
          <Image src={chatGDSLogo1} width='200px' />
          <Icon icon='Check' size='xlg' color='success' />
          <Typography textAlign='center'>
            Registrado com sucesso, por favor, cheque sua caixa de email por um link de confirmação
          </Typography>
        </Column>
      ) : (
        <Form
          maxWidth='380px'
          p='30px'
          border='1px solid #E8E8E8'
          borderRadius='5px'
          backgroundColor='#fff'
          onSubmit={handleSubmit(onSubmit)}
        >
          <Image src={chatGDSLogo1} />
          <Typography variant='caption' textAlign='center'>
            Você já tem uma conta?{' '}
            <Typography
              as='span'
              color='blue.500'
              cursor='pointer'
              onClick={() => navigate('/login')}
            >
              Login
            </Typography>
          </Typography>
          <Row mt='24px'>
            <Input
              label='Nome'
              placeholder='Digite aqui seu texto'
              error={errors?.name}
              {...register('name', { required: true })}
            />
          </Row>
          <Row mt='24px'>
            <Input
              label='E-mail'
              placeholder='Digite aqui seu texto'
              error={errors?.email}
              {...register('email', { required: true })}
            />
          </Row>
          <Row mt='24px'>
            <Input
              label='Senha'
              type={showPassword ? 'text' : 'password'}
              placeholder='Digite sua senha'
              iconPosition='end'
              icon={showPassword ? 'Eye' : 'ClosedEye'}
              error={errors?.password}
              iconProps={{
                color: 'grey.400',
                onClick: () => setShowPassword((prevState) => !prevState),
              }}
              {...register('password', {
                required: 'Senha é obrigatória',
                minLength: { value: 6, message: 'A senha deve ter pelo menos 6 caracteres' },
              })}
            />
          </Row>
          <Row mt='24px'>
            <Input
              label='Confirmar a senha'
              type={showConfirmPassword ? 'text' : 'password'}
              placeholder='Digite sua senha'
              iconPosition='end'
              icon={showConfirmPassword ? 'Eye' : 'ClosedEye'}
              error={errors?.confirm_password}
              errorMessage={errors?.confirm_password?.message}
              iconProps={{
                color: 'grey.400',
                onClick: () => setShowConfirmPassword((prevState) => !prevState),
              }}
              {...register('confirm_password', {
                required: 'Confirmação de senha é obrigatória',
                validate: (value) => value === password || 'As senhas não coincidem',
              })}
            />
          </Row>
          <Button type='submit' mt='24px' disabled={loading}>
            Registrar
          </Button>
        </Form>
      )}
    </Row>
  )
}

export default Register
