import React, { forwardRef } from 'react'
import styled from 'styled-components'
import {
  space,
  layout,
  color,
  flexbox,
  compose,
  background,
  border,
  shadow,
  position,
} from 'styled-system'

const style = compose(space, layout, color, flexbox, background, border, shadow, position)

const Base = styled.div`
  display: flex;
  gap: ${({ gap = '0px' }) => gap};
  cursor: ${({ cursor = 'initial' }) => cursor};
  ${style};
`

const Row = forwardRef(({ children, ...props }, ref) => (
  <Base ref={ref} {...props}>
    {children}
  </Base>
))

export default Row
