import { useEffect, useState } from 'react'

import { Column, Row, Button, Icon, Card, Table, Avatar, Typography, Badge } from 'design-system'
import { PageHeader, ModalContact, ModalConfirmation, ProtectedComponent } from 'components'

import { deleteContact, showContactsList } from 'services'

import { useContactsReducer, actionTypes } from './store'
import { hexToRGBA, notify, withAuthorization } from 'helpers'

const Contacts = () => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(null)
  const [pagination, setPagination] = useState({
    count: 0,
    next: null,
    previus: null,
    page_size: 50,
    page: 1,
    total_pages: 1,
  })
  const [modalConfirmation, setModalConfirmation] = useState(false)

  const [state, dispatch] = useContactsReducer()

  useEffect(() => {
    fetch()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination?.page])

  const fetch = async () => {
    try {
      setLoading(true)
      const { data } = await showContactsList(pagination)
      dispatch({
        type: actionTypes.LOAD_ITEMS,
        payload: data.results,
      })

      delete data.results

      setPagination({ ...data })
    } catch {
      notify.error('Não foi possível resgatar lista de contatos.')
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async (id) => {
    try {
      setLoading(true)
      await deleteContact(id)
      dispatch({
        type: actionTypes.REMOVE_ITEM,
        payload: id,
      })
      notify.success('Contato excluído com sucesso.')
      setModalConfirmation(null)
    } catch {
      notify.error('Não foi possível excluir o contato.')
    } finally {
      setLoading(false)
    }
  }

  const columns = [
    {
      header: '',
      cellProps: { width: '70px' },
      render: ({ picture, name }) => <Avatar src={picture} alt={name} />,
    },
    {
      header: 'Nome',
      field: 'name',
    },
    {
      header: 'E-mail',
      field: 'email',
    },
    {
      header: 'Telefone',
      render: ({ phone }) => <Typography>{phone}</Typography>,
    },
    {
      header: 'Etiquetas',
      render: ({ tags }) => (
        <Row maxWidth='250px' flexWrap='wrap' gap='8px'>
          {tags.map((tag) => (
            <Badge
              width='100px'
              backgroundColor={hexToRGBA(tag.color, 0.2)}
              color={tag?.color}
              title={tag?.name}
            />
          ))}
        </Row>
      ),
    },
    {
      header: 'Ações',
      render: (row) => (
        <ProtectedComponent allowedRoles={['manage_contacts']} unauthorizedComponent={false}>
          <Row gap='20px'>
            <Icon cursor='pointer' icon='Edit' color='primary' onClick={() => setModal(row)} />
            {row?.can_delete && (
              <Icon
                cursor='pointer'
                icon='Trash'
                color='danger'
                onClick={() =>
                  setModalConfirmation({
                    type: 'danger',
                    title: `Excluir contato ${row?.name}.`,
                    message:
                      'Deseja realmente excluir o contato da sua lista? Todas as conversas vinculadas a este contato também serão deletadas do sistema. Ele ainda continuará em seus contatos no WhatsApp.',
                    handler: () => handleDelete(row.id),
                  })
                }
              />
            )}
          </Row>
        </ProtectedComponent>
      ),
    },
  ]

  return (
    <Column p='24px'>
      <PageHeader title='Contatos'>
        <ProtectedComponent allowedRoles={['manage_contacts']} unauthorizedComponent={false}>
          <Row gap='8px' justifyContent='flex-end'>
            <Button variant='icon' color='primary' onClick={() => setModal({})}>
              <Icon icon='UserAdd' />
            </Button>
          </Row>
        </ProtectedComponent>
      </PageHeader>
      <Card mt='16px' overflow='auto'>
        <Table
          title='contato'
          columns={columns}
          loading={loading}
          data={state.contacts}
          pagination={pagination}
          setPagination={setPagination}
          emptyMessage='Você ainda não tem contatos, adicione para começar a conversar!'
          handleEmptyData={() => setModal(true)}
        />
      </Card>
      <ModalContact
        contact={modal}
        onClose={() => setModal(null)}
        dispatch={dispatch}
        actionTypes={actionTypes}
      />

      <ModalConfirmation
        open={modalConfirmation}
        onClose={() => setModalConfirmation(null)}
        {...modalConfirmation}
      />
    </Column>
  )
}

export default withAuthorization(Contacts, ['access_contacts'])
