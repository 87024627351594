import { colors, ticketStatusDict } from 'configs'
import { Column, HighlightedText } from 'design-system'

const ContactDetails = ({ ticket }) => {
  const { contact, created_at, status, department, attendant } = ticket

  return (
    <Column>
      <Column p='8px 0' borderBottom={`1px solid ${colors.grey[50]}`}>
        <HighlightedText hightlight='Canal de origem' text='WhatsApp' />
      </Column>
      <Column p='8px 0' borderBottom={`1px solid ${colors.grey[50]}`}>
        <HighlightedText hightlight='ID' text={contact?.id} />
      </Column>
      <Column p='8px 0' borderBottom={`1px solid ${colors.grey[50]}`}>
        <HighlightedText hightlight='E-mail' text={contact?.email} />
      </Column>
      <Column p='8px 0' borderBottom={`1px solid ${colors.grey[50]}`}>
        <HighlightedText hightlight='Início da conversa' text={created_at} />
      </Column>
      <Column p='8px 0' borderBottom={`1px solid ${colors.grey[50]}`}>
        <HighlightedText hightlight='Status' text={ticketStatusDict[status]} />
      </Column>
      <Column p='8px 0' borderBottom={`1px solid ${colors.grey[50]}`}>
        <HighlightedText
          hightlight='Departamento'
          text={department ? department?.name : 'Sem departamento'}
        />
      </Column>
      <Column p='8px 0' borderBottom={`1px solid ${colors.grey[50]}`}>
        <HighlightedText
          hightlight='Responsável'
          text={attendant ? attendant?.name : 'Sem responsável'}
        />
      </Column>
    </Column>
  )
}

export default ContactDetails
