import { io } from 'socket.io-client'

import { system } from 'configs'

export const socket = io(system.SOCKET_API, {
  path: '/socket.io',
  secure: true,
  withCredentials: true,
  transports: ['websocket'],
  reconnection: true,
})
