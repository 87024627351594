
      import React, { memo } from 'react';

      const ArrowSwitch = (props) => {
        return (
          <svg height="20" width="20"  viewBox="0 0 20 20" {...props}>
	<g clipPath="url(#clip0_212_3043)">
		<path d="M5.83317 17.5L1.6665 13.3333L5.83317 9.16667L7.02067 10.3333L4.854 12.5H17.4998V14.1667H4.854L7.02067 16.3333L5.83317 17.5ZM14.1665 10.8333L12.979 9.66667L15.1457 7.5H2.49984V5.83333H15.1457L12.979 3.66667L14.1665 2.5L18.3332 6.66667L14.1665 10.8333Z" fill="#656565"/>
	</g>
	<defs>
		<clipPath id="clip0_212_3043">
			<rect height="20" width="20" fill="white"/>
		</clipPath>
	</defs>
</svg>
        );
      };
      const Memo = memo(ArrowSwitch)
      export default Memo
    