import { forwardRef, memo } from 'react'

const Filter2 = forwardRef((props, ref) => {
  return (
    <svg ref={ref} width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <path d='M10 18H14V16H10V18ZM3 6V8H21V6H3ZM6 13H18V11H6V13Z' fill='#7F7F7F' />
    </svg>
  )
})
const Memo = memo(Filter2)
export default Memo
