export const connectionsTypeOptions = [
  {
    label: 'GupShup',
    value: 'gupshup',
  },
]

export const fieldTypesOptions = [
  {
    label: 'Texto',
    field_type: 'text',
  },
  { label: 'Caixa de seleção', field_type: 'boolean' },
  { label: 'Número inteiro', field_type: 'integer' },
  { label: 'Número decimal', field_type: 'decimal' },
]

export const templateCategoryOptions = [
  {
    label: 'Marketing',
    value: 'MARKETING',
    category: 0,
  },
  {
    label: 'Utilidade',
    value: 'UTILITY',
    category: 1,
  },
]

export const templateTypeOptions = [
  {
    label: 'Texto',
    value: 'TEXT',
    category: 0,
  },
  {
    label: 'Texto',
    value: 'TEXT',
    category: 1,
  },
]
