import { useReducer } from 'react'

export const initialValues = {
  templates: [],
}

export const actionTypes = {
  LOAD_ITEMS: 'LOAD_ITEMS',
  ADD_ITEM: 'ADD_ITEM',
  UPDATE_ITEM: 'UPDATE_ITEM',
  REMOVE_ITEM: 'REMOVE_ITEM',
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.LOAD_ITEMS:
      return {
        ...state,
        templates: action?.payload?.sort((itemA, itemB) => itemA.name.localeCompare(itemB.name)),
      }
    case actionTypes.ADD_ITEM:
      return {
        ...state,
        templates: [...state.templates, action.payload].sort((itemA, itemB) =>
          itemA.name.localeCompare(itemB.name),
        ),
      }
    case actionTypes.UPDATE_ITEM:
      const filter = state.templates.filter((item) => item.id !== action.payload.id)
      return {
        ...state,
        templates: [...filter, action.payload].sort((itemA, itemB) =>
          itemA.name.localeCompare(itemB.name),
        ),
      }
    case actionTypes.REMOVE_ITEM:
      return {
        ...state,
        templates: state.templates.filter((item) => item.id !== action.payload),
      }
    default:
      return state
  }
}

export const useTemplatesReducer = () => useReducer(reducer, initialValues)
