import { memo } from 'react'

const Email = (props) => {
  return (
    <svg height='18' width='20' viewBox='0 0 20 18' {...props}>
      <path
        d='M18.3334 4.99967C18.3334 4.08301 17.5834 3.33301 16.6667 3.33301H3.33341C2.41675 3.33301 1.66675 4.08301 1.66675 4.99967V14.9997C1.66675 15.9163 2.41675 16.6663 3.33341 16.6663H16.6667C17.5834 16.6663 18.3334 15.9163 18.3334 14.9997V4.99967ZM16.6667 4.99967L10.0001 9.15801L3.33341 4.99967H16.6667ZM16.6667 14.9997H3.33341V6.66634L10.0001 10.833L16.6667 6.66634V14.9997Z'
        fill='#656565'
      />
    </svg>
  )
}
const Memo = memo(Email)
export default Memo
