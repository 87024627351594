import { memo } from 'react'

const Eye = (props) => {
  return (
    <svg height='20' width='20' viewBox='0 0 20 20' {...props}>
      <path
        d='M10.0002 5.41667C13.1585 5.41667 15.9752 7.19167 17.3502 10C15.9752 12.8083 13.1585 14.5833 10.0002 14.5833C6.84183 14.5833 4.02516 12.8083 2.65016 10C4.02516 7.19167 6.84183 5.41667 10.0002 5.41667ZM10.0002 3.75C5.8335 3.75 2.27516 6.34167 0.833496 10C2.27516 13.6583 5.8335 16.25 10.0002 16.25C14.1668 16.25 17.7252 13.6583 19.1668 10C17.7252 6.34167 14.1668 3.75 10.0002 3.75ZM10.0002 7.91667C11.1502 7.91667 12.0835 8.85 12.0835 10C12.0835 11.15 11.1502 12.0833 10.0002 12.0833C8.85016 12.0833 7.91683 11.15 7.91683 10C7.91683 8.85 8.85016 7.91667 10.0002 7.91667ZM10.0002 6.25C7.9335 6.25 6.25016 7.93333 6.25016 10C6.25016 12.0667 7.9335 13.75 10.0002 13.75C12.0668 13.75 13.7502 12.0667 13.7502 10C13.7502 7.93333 12.0668 6.25 10.0002 6.25Z'
        fill='#656565'
      />
    </svg>
  )
}
const Memo = memo(Eye)
export default Memo
