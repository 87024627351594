import { useEffect, useState } from 'react'

import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Typography,
  Row,
  Column,
  Spinner,
  Card,
  Input,
} from 'design-system'

import { showQuickAnswersList, convertTextVariable } from 'services'
import { notify } from 'helpers'
import styled from 'styled-components'
import RobotHelp from 'components/RobotHelp'
import { colors } from 'configs'

const ModalQuickAnswersSend = ({ onClose, quickAnswers, changeInput, ticketId, ...props }) => {
  const [loading, setLoading] = useState(false)
  const [quickAnswersList, setQuickAnswersList] = useState([])
  const [selected, setSelected] = useState(null)
  const [search, setSearch] = useState('')
  const [searchList, setSearchList] = useState([])

  useEffect(() => {
    if (quickAnswers) {
      handleGetInfo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickAnswers])

  const handleGetInfo = async () => {
    try {
      setLoading(true)
      const { data } = await showQuickAnswersList({ page_size: 1000 })

      setQuickAnswersList(data.results)
      setSearchList(data.results)
    } catch {
      notify.error('Não foi possível buscar dados da resposta rápida.')
    } finally {
      setLoading(false)
    }
  }

  const handleConvertQuickAnswers = async (text) => {
    try {
      let formated = text.message
      setLoading(true)
      const { data } = await convertTextVariable({ ticket: ticketId })

      formated = formated.replace(/{{\w+}}/g, (match) => data[match] || match)

      setSelected(formated)
    } catch {
      notify.error('Não foi possível converter o texto.')
    } finally {
      setLoading(false)
    }
  }

  const handleSelect = (value) => {
    changeInput(value)
    onClose()
  }

  const handleSearch = (value) => {
    setSearch(value)
    const filteredList = quickAnswersList.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase()),
    )
    setSearchList(filteredList)
  }

  return (
    <Modal size='md' open={quickAnswers} onClose={onClose} {...props}>
      <ModalHeader onClose={onClose}>
        <Typography variant='title' color='primary'>
          Respostas rápidas
        </Typography>
      </ModalHeader>
      <ModalBody>
        <Row gap='32px' maxHeight='250px'>
          {loading ? (
            <Column alignItems='center' justifyContent='center' width='50%'>
              <Spinner />
            </Column>
          ) : (
            <Column width='50%'>
              <Input
                placeholder='Pesquisar'
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
              />
              <Column mt='8px' overflow='auto'>
                {searchList.length > 0 ? (
                  searchList.map((quickAnswer, idx) => (
                    <Item
                      key={idx}
                      active={selected?.shortcut === quickAnswer?.shortcut}
                      onClick={() => handleConvertQuickAnswers(quickAnswer)}
                    >
                      {quickAnswer.name}
                    </Item>
                  ))
                ) : (
                  <Typography>Sem respostas rápidas</Typography>
                )}
              </Column>
            </Column>
          )}
          <Column
            justifyContent='center'
            alignItems='center'
            borderRadius='8px'
            p='32px'
            background='#E8E8E8'
          >
            {selected ? (
              <Card background={colors.violet[100]} borderRadius='8px 8px 0 8px'>
                {selected}
              </Card>
            ) : (
              <RobotHelp
                alert
                robot={false}
                color='#B35F1B'
                text='Selecione uma resposta rápida criada para visualizar uma prévia nesse espaço.'
              />
            )}
            {selected && (
              <Button
                mt='16px'
                width='70px'
                variant='outlined'
                backgroundColor='#E8E8E8'
                disabled={loading}
                onClick={() => handleSelect(selected)}
              >
                Aplicar
              </Button>
            )}
          </Column>
        </Row>
      </ModalBody>
    </Modal>
  )
}

const Item = styled(Row)`
  width: 100%;
  color: #656565;
  font-size: 16px;
  transition: all 0.5s ease;
  padding: 8px;
  border-radius: 0 8px 8px 0;
  cursor: pointer;
  border-left: ${({ active }) => (active ? '1px solid #5e54a9' : '1px solid #fff')};
  background: ${({ active }) => (active ? '#edf3fc' : 'transparent')};

  &:hover {
    border-left: 1px solid #5e54a9;
    background: #edf3fc;
    color: #5e54a9;
  }
`

export default ModalQuickAnswersSend
