import { createContext, useCallback, useEffect, useRef } from 'react'
import {
  useTicketReducer,
  actionTicketTypes,
  actionMessagesTypes,
  actionSelectedTicketTypes,
  actionCurrentStatusTypes,
} from './store'
import md5 from 'md5'
import { messageStatusDict, system } from 'configs'
import { socket } from 'providers/socket'
import { useAuth } from 'hooks'
import { getDateLabel } from 'helpers'

export const TicketContext = createContext({})

export const TicketProvider = ({ children }) => {
  const [state, dispatch] = useTicketReducer()

  const {
    userData: { company_user },
  } = useAuth()

  const { SOCKET_ENVIRONMENT } = system

  const socketRef = useRef(false)

  const { selected_ticket, current_status, tickets_list } = state

  const isPendingOrAttendingTab = ['pending', 'attending'].includes(current_status)
  const isClosedTab = current_status === 'closed'

  const handlerEventMessage = useCallback(
    ({ data, event }) => {
      const activeTicket = Number(selected_ticket?.id) === Number(data?.ticket?.id)

      if (!activeTicket) return

      if (event === 'created') {
        dispatch({
          type: actionMessagesTypes.ADD_MESSAGE,
          payload: { ...data, key: getDateLabel(data.created_at) },
        })
        if (data?.ticket?.id) {
          dispatch({
            type: actionSelectedTicketTypes.UPDATE_SELECTED_TICKET,
            payload: { ...data.ticket },
          })
        }
      }

      if (event === 'status-update') {
        if (data?.ticket?.id && data?.ticket?.status === current_status) {
          dispatch({
            type: actionTicketTypes.UPDATE_TICKET,
            payload: {
              ...selected_ticket,
              last_message: {
                ...selected_ticket?.last_message,
                status: messageStatusDict[data?.current_status],
              },
            },
          })
        }

        if (data?.id) {
          dispatch({
            type: actionMessagesTypes.UPDATE_MESSAGES,
            payload: {
              id: data.id,
              status: messageStatusDict[data?.current_status],
              key: getDateLabel(data?.created_at),
            },
          })
        }
      }
    },
    [dispatch, selected_ticket, current_status],
  )

  const handlerEventTicket = useCallback(
    ({ data, event }) => {
      const activeTicket = Number(selected_ticket?.id) === Number(data?.id)
      const findTicket = tickets_list?.find((t) => t.id === data.id)

      const isPendingOrAttending = ['pending', 'attending'].includes(data.status)
      const isClosed = data.status === 'closed'

      if (event === 'update') {
        if (activeTicket) {
          dispatch({ type: actionSelectedTicketTypes.UPDATE_SELECTED_TICKET, payload: data })
        }

        if (findTicket) {
          if ((isPendingOrAttendingTab && isPendingOrAttending) || (isClosedTab && isClosed)) {
            dispatch({ type: actionTicketTypes.UPDATE_TICKET, payload: data })
          } else if (
            (isPendingOrAttendingTab && isClosed) ||
            (isClosedTab && isPendingOrAttending)
          ) {
            dispatch({ type: actionTicketTypes.REMOVE_TICKET, payload: data.id })
          }
        } else if ((isPendingOrAttendingTab && isPendingOrAttending) || (isClosedTab && isClosed)) {
          dispatch({ type: actionTicketTypes.ADD_TICKET, payload: data })
        }
      } else if (event === 'created' && !findTicket) {
        if ((isPendingOrAttendingTab && isPendingOrAttending) || (isClosedTab && isClosed)) {
          dispatch({ type: actionTicketTypes.ADD_TICKET, payload: data })
        }
      }
    },
    [dispatch, selected_ticket?.id, tickets_list, isPendingOrAttendingTab, isClosedTab],
  )

  useEffect(() => {
    if (!socketRef.current) {
      const roomcode = `${SOCKET_ENVIRONMENT}_chatgds_ticket_${md5(`${selected_ticket?.id}`)}`
      socket.emit('join-room', roomcode)
      socketRef.current = true
    }

    socket.on('ticket-message', handlerEventMessage)
    socket.on('ticket', handlerEventTicket)

    return () => {
      socket.off('ticket-message', handlerEventMessage)
      socket.off('ticket', handlerEventTicket)
      socketRef.current = false
    }
  }, [
    company_user,
    selected_ticket?.id,
    handlerEventMessage,
    handlerEventTicket,
    SOCKET_ENVIRONMENT,
  ])

  return (
    <TicketContext.Provider
      value={{
        state,
        dispatch,
        actionTicketTypes,
        actionMessagesTypes,
        actionSelectedTicketTypes,
        actionCurrentStatusTypes,
      }}
    >
      {children}
    </TicketContext.Provider>
  )
}

export default TicketProvider
