import Routes from 'routes'

import { AuthProvider } from 'providers'

import { GlobalConfigs } from 'configs'

const App = () => {
  return (
    <GlobalConfigs>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </GlobalConfigs>
  )
}

export default App
