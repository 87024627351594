
      import React, { memo } from 'react';

      const Book = (props) => {
        return (
          <svg height="20" width="20"  viewBox="0 0 20 20" {...props}>
	<path d="M17.5002 3.33366C16.5752 3.04199 15.5585 2.91699 14.5835 2.91699C12.9585 2.91699 11.2085 3.25033 10.0002 4.16699C8.79183 3.25033 7.04183 2.91699 5.41683 2.91699C3.79183 2.91699 2.04183 3.25033 0.833496 4.16699V16.3753C0.833496 16.5837 1.04183 16.792 1.25016 16.792C1.3335 16.792 1.37516 16.7503 1.4585 16.7503C2.5835 16.2087 4.2085 15.8337 5.41683 15.8337C7.04183 15.8337 8.79183 16.167 10.0002 17.0837C11.1252 16.3753 13.1668 15.8337 14.5835 15.8337C15.9585 15.8337 17.3752 16.0837 18.5418 16.7087C18.6252 16.7503 18.6668 16.7503 18.7502 16.7503C18.9585 16.7503 19.1668 16.542 19.1668 16.3337V4.16699C18.6668 3.79199 18.1252 3.54199 17.5002 3.33366ZM17.5002 14.5837C16.5835 14.292 15.5835 14.167 14.5835 14.167C13.1668 14.167 11.1252 14.7087 10.0002 15.417V5.83366C11.1252 5.12533 13.1668 4.58366 14.5835 4.58366C15.5835 4.58366 16.5835 4.70866 17.5002 5.00033V14.5837Z" fill="#656565"/>
</svg>
        );
      };
      const Memo = memo(Book)
      export default Memo
    