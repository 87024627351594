import { clientApi } from 'providers'

export const showContactInfo = (id) => clientApi.get(`/contacts/${id}/`)

export const showContactsList = (params) => clientApi.get(`/contacts/`, { params })

export const showContactsListSelect = (params) => clientApi.get(`/contacts/select`, { params })

export const createContact = (data) => clientApi.post('/contacts/', data)

export const updatedContact = (id, data) => clientApi.put(`/contacts/${id}/`, data)

export const deleteContact = (id) => clientApi.delete(`/contacts/${id}/`)
