import { csvLogo, documentLogo, imageLogo, pdfLogo, sheetLogo, unknowLogo, xlsxLogo } from 'assets'
import Column from 'design-system/Column'
import Icon from 'design-system/Icon'
import Image from 'design-system/Image'
import Typography from 'design-system/Typography'
import styled from 'styled-components'

const Base = styled.embed`
  overflow: hidden !important;
  border-radius: 4px 4px 0 0;
`

const Download = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${({ preview }) => (preview === 'pdf' ? '0 0 4px 4px' : '4px')};
  text-decoration: none;
  padding: 10px;
  gap: 12px;
`

const filesLogoDict = {
  pdf: pdfLogo,
  csv: csvLogo,
  xsls: xlsxLogo,
  sheet: sheetLogo,
  document: documentLogo,
  image: imageLogo,
}

const FilePreview = ({ preview, downloadLink, fileName, ...props }) => {
  return (
    <Column className='cursor-pointer'>
      {preview === 'pdf' && <Base src={downloadLink} {...props} />}
      <Download
        preview={preview}
        target='_blank'
        href={downloadLink}
        download={fileName}
        rel='noreferrer'
      >
        <Image src={filesLogoDict[preview] || unknowLogo} width='24px' />
        <Typography whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' width='130px'>
          {fileName || 'Download'}
        </Typography>
        <Icon icon='Download2' size='lg' color='grey.300' />
      </Download>
    </Column>
  )
}

export default FilePreview
