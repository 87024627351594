import { memo } from 'react'

const Edit = (props) => {
  return (
    <svg height='20' width='20' viewBox='0 0 20 20' {...props}>
      <path
        d='M2.49902 17.5011H5.62402L14.8407 8.28444L11.7157 5.15944L2.49902 14.3761V17.5011ZM4.16569 15.0678L11.7157 7.51777L12.4824 8.28444L4.93236 15.8344H4.16569V15.0678Z'
        fill='#656565'
      />
      <path
        d='M15.3074 2.74277C14.9824 2.41777 14.4574 2.41777 14.1324 2.74277L12.6074 4.26777L15.7324 7.39277L17.2574 5.86777C17.5824 5.54277 17.5824 5.01777 17.2574 4.69277L15.3074 2.74277Z'
        fill='#656565'
      />
    </svg>
  )
}
const Memo = memo(Edit)
export default Memo
