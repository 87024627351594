import { useNavigate } from 'react-router-dom'

import { Avatar, Badge, ColorChip, Column, Row, Timer, Typography } from 'design-system'

import { platformTypeDict } from 'configs'
import lastMessageDict from './lastMessageDict'

import { hexToRGBA, notify } from 'helpers'

import { useAuth } from 'hooks'

const TicketCard = ({ ticket, ...props }) => {
  const navigate = useNavigate()
  const {
    userData: { company_user },
  } = useAuth()

  const senderContact =
    ticket?.last_message?.sender_type === 'contact' && ticket.status !== 'closed'

  const handleClick = () => {
    const canNavigate =
      company_user?.owner ||
      !ticket?.department ||
      company_user?.departments.some((dp) => dp.id === ticket?.department?.id) ||
      ticket.attendant === null

    if (canNavigate) {
      navigate(`/tickets/${ticket?.id}`)
    } else {
      notify.warning('Você não pode acessar o atendimento de outro atendente.')
    }
  }
  return (
    <Row
      p='12px 8px'
      borderRight='none'
      borderTop='1px solid #E8E8E8'
      borderLeft={`5px solid ${ticket?.department ? ticket?.department?.color : '#E8E8E8'}`}
      borderRadius='8px 0 0 8px'
      gap='16px'
      className='cursor-pointer'
      onClick={handleClick}
      {...props}
    >
      <Column>
        <Avatar
          size='lg'
          square
          src={ticket?.contact?.picture}
          alt={ticket?.contact?.name}
          badge={platformTypeDict['whatsapp']?.logo}
        />
      </Column>
      <Column width='100%'>
        <Column>
          <Row justifyContent='space-between'>
            <Typography
              ellipsis
              fontSize='14px'
              fontWeight={senderContact ? '600' : '400'}
              width='150px'
            >
              {ticket?.contact?.name}
            </Typography>
            {ticket?.attendant && (
              <Badge
                width='100px'
                backgroundColor={hexToRGBA('#5D597C', 0.05)}
                color='#5D597C'
                icon='RecordVoice'
                title={ticket?.attendant?.name}
                fontStyle={{ fontSize: '12px' }}
              />
            )}
          </Row>

          {lastMessageDict[ticket?.last_message?.message_type || 'text'](ticket, senderContact)}
          <Row width='100%' mt='4px' justifyContent='flex-end' alignItems='center'>
            <Badge backgroundColor={hexToRGBA('#E8E8E8', 0.2)}>
              <Typography fontSize='12px' fontWeight={senderContact ? '600' : '400'}>
                <Timer initialTime={ticket?.last_message?.created_at} />
              </Typography>
              {senderContact && <ColorChip color='#136CDC' width='10px' height='10px' />}
            </Badge>
          </Row>
        </Column>
      </Column>
    </Row>
  )
}

export default TicketCard
