import { variant } from 'styled-system'

export const variants = () =>
  variant({
    prop: 'size',
    variants: {
      11: {
        width: '11px',
        height: '11px',
        minWidth: '11px',
        minHeight: '11px',
      },
      14: {
        width: '14px',
        height: '14px',
        minWidth: '14px',
        minHeight: '14px',
      },
      xs: {
        width: '16px',
        height: '16px',
        minWidth: '16px',
        minHeight: '16px',
      },
      sm: {
        width: '18px',
        height: '18px',
        minWidth: '18px',
        minHeight: '18px',
      },
      md: {
        width: '20px',
        height: '20px',
        minWidth: '20px',
        minHeight: '20px',
      },
      lg: {
        width: '24px',
        height: '24px',
        minWidth: '24px',
        minHeight: '24px',
      },
      xlg: {
        width: '32px',
        height: '32px',
        minWidth: '32px',
        minHeight: '32px',
      },
    },
  })
