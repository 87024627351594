import { forwardRef, useState } from 'react'
import styled from 'styled-components'
import { space, layout, compose } from 'styled-system'
import Typography from '../Typography'
import Spinner from 'design-system/Spinner'
import Badge from 'design-system/Badge'
import Row from 'design-system/Row'
import Column from 'design-system/Column'
import SelectAsyncCreate from 'design-system/SelectAsyncCreate'
import { ProtectedComponent } from 'components'
import { colors } from 'configs'

const styles = compose(space, layout)

const AccordionContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colors.grey[50]}`};
  background-color: ${({ theme }) => theme.colors.grey[40]};
  ${styles};
`

const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 16px 16px 8px 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.grey[40]};
  &:hover,
  &:hover p {
    cursor: pointer;
  }
`

const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`

const LoaderContainer = styled.div`
  display: flex;
`

const AccordionContent = styled.div`
  border-radius: 8px;
  transition: all 0.3s ease;
  margin-top: 16px;
  overflow: hidden;
  height: ${({ open }) => (open ? 'auto' : '0')};
  padding: ${({ open }) => (open ? '0 18px 18px' : '0 18px')};
  background-color: ${({ theme }) => theme.colors.grey[40]};
`

const AccordionBadges = forwardRef(
  (
    {
      title,
      children,
      actionLabel = 'Adicionar',
      items = [],
      maxItems = 3,
      loading = false,
      permissions = [],
      badgesAction = null,
      loadOptions,
      createAction,
      ...props
    },
    ref,
  ) => {
    const [search, setSearch] = useState(false)

    const handleCallAction = (data) => {
      setSearch(false)
      badgesAction && badgesAction(data)
    }

    return (
      <AccordionContainer ref={ref} {...props}>
        <AccordionHeader aria-controls={`accordion-content-${title}`} role='button'>
          <Column width='100%'>
            <IconContainer>
              <Row gap='8px' alignItems='center'>
                <Typography
                  fontWeight='600'
                  color={!loading ? 'grey.300' : 'grey.50'}
                  notSelectable
                >
                  {title}
                </Typography>
                <ProtectedComponent allowedRoles={permissions} unauthorizedComponent={null}>
                  <Badge
                    backgroundColor={colors.grey[50]}
                    onClick={() => !loading && setSearch((prev) => !prev)}
                    title={actionLabel}
                    icon='Plus'
                    borderRadius='360px'
                    noTooltip
                    border={`1px dashed ${colors.grey[300]}`}
                    iconProps={{ color: 'grey.300' }}
                  />
                </ProtectedComponent>
              </Row>

              {loading && (
                <LoaderContainer>
                  <Spinner size='sm' />
                </LoaderContainer>
              )}
            </IconContainer>

            {search && (
              <Column mt='16px'>
                <SelectAsyncCreate
                  placeholder='Pesquisar etiqueta'
                  defaultOptions
                  loadOptions={loadOptions}
                  keys={{ label: 'name', value: 'id' }}
                  isCreatable
                  onCreateOption={createAction}
                  callBack={handleCallAction}
                />
              </Column>
            )}
          </Column>
        </AccordionHeader>

        <AccordionContent id={`accordion-content-${title}`} open={true}>
          {children}
        </AccordionContent>
      </AccordionContainer>
    )
  },
)

export default AccordionBadges
