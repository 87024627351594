import { forwardRef } from 'react'
import { Controller } from 'react-hook-form'
import styled from 'styled-components'
import { layout, space, color, compose } from 'styled-system'
import DatePicker from 'react-datepicker'

import Typography from 'design-system/Typography'

import 'react-datepicker/dist/react-datepicker.css'
import './style.css'

import ptBR from 'date-fns/locale/pt-BR'
import Icon from 'design-system/Icon'

const styles = compose(layout, space, color)

const Base = styled.label`
  display: flex;
  flex-direction: column;
  position: relative;
  height: fit-content;
  width: 100%;
  line-height: normal;
  ${styles};
`

const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  ${({ position }) => (position === 'end' ? 'right: 8px;' : 'left: 8px;')};
  top: ${({ label, error }) => (label && !error ? ' 10px;' : '0px;')};
`

const DatepickerLib = styled(DatePicker)`
  width: 100%;
  padding: 5px 16px 5px 32px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme: { colors } }) => colors.grey[300]};
  border: ${({ theme: { colors } }) => `1px solid ${colors.grey[50]}`};
  &:hover {
    border-color: ${({ theme: { colors } }) => colors.grey[80]};
  }
  ${styles};
`

const Datepicker = forwardRef(
  (
    {
      label,
      maxWidth,
      name,
      control,
      defaultValue,
      value,
      error,
      errorMessage,
      icon,
      required,
      rules,
      onChange,
      containerProps,
      iconPosition,
      iconProps,
      ...props
    },
    ref,
  ) => {
    return (
      <Base for={ref} maxWidth={maxWidth} {...containerProps}>
        {label && <Typography>{`${label} ${props?.required ? '*' : ''}`}</Typography>}
        {control ? (
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={{ required, ...rules }}
            render={({ field }) => (
              <DatepickerLib
                ref={ref}
                id={ref}
                locale={ptBR}
                dateFormat='dd/MM/yyyy'
                selected={field.value}
                onChange={(date) => field.onChange(date)}
                className='react-datepicker'
              />
            )}
          />
        ) : (
          <DatepickerLib
            ref={ref}
            id={ref}
            locale={ptBR}
            dateFormat='dd/MM/yyyy'
            selected={value}
            {...props}
          />
        )}
        <IconWrapper error={error} label={label} position={iconPosition}>
          <Icon icon='Calendar' {...iconProps} />
        </IconWrapper>

        {error && (
          <Typography variant='helper' color='danger'>
            {errorMessage || 'Campo obrigatório'}
          </Typography>
        )}
      </Base>
    )
  },
)

Datepicker.defaultProps = {
  name: 'datepicker',
  value: new Date(),
  onChange: (date) => console.log(date),
}

export default Datepicker
