
      import React, { memo } from 'react';

      const Mic = (props) => {
        return (
          <svg height="20" width="20"  viewBox="0 0 20 20" {...props}>
	<path d="M10.0001 11.667C9.30564 11.667 8.71536 11.4239 8.22925 10.9378C7.74314 10.4517 7.50008 9.86144 7.50008 9.16699V4.16699C7.50008 3.47255 7.74314 2.88227 8.22925 2.39616C8.71536 1.91005 9.30564 1.66699 10.0001 1.66699C10.6945 1.66699 11.2848 1.91005 11.7709 2.39616C12.257 2.88227 12.5001 3.47255 12.5001 4.16699V9.16699C12.5001 9.86144 12.257 10.4517 11.7709 10.9378C11.2848 11.4239 10.6945 11.667 10.0001 11.667ZM9.16675 17.5003V14.9378C7.7223 14.7434 6.52786 14.0975 5.58341 13.0003C4.63897 11.9031 4.16675 10.6253 4.16675 9.16699H5.83341C5.83341 10.3198 6.23966 11.3024 7.05216 12.1149C7.86466 12.9274 8.8473 13.3337 10.0001 13.3337C11.1529 13.3337 12.1355 12.9274 12.948 12.1149C13.7605 11.3024 14.1667 10.3198 14.1667 9.16699H15.8334C15.8334 10.6253 15.3612 11.9031 14.4167 13.0003C13.4723 14.0975 12.2779 14.7434 10.8334 14.9378V17.5003H9.16675Z" fill="#656565"/>
</svg>
        );
      };
      const Memo = memo(Mic)
      export default Memo
    