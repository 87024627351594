import { Routes as RouterDomRoutes, Route } from 'react-router-dom'

import { Layout } from 'components'

import AuthGuard from './AuthGuard'
import {
  Contacts,
  Login,
  Page404,
  Settings,
  Tags,
  QuickAnswers,
  Departments,
  Tickets,
  Templates,
  Register,
  ConfirmEmail,
  Unauthorized,
} from 'pages'

const Routes = () => {
  return (
    <RouterDomRoutes>
      {/* UNAUTHENTICATED ROUTES */}
      <Route path='/' element={<Login />} />
      <Route path='/login' element={<Login />} />
      <Route path='/register' element={<Register />} />
      <Route path='/confirm-email/:confirmation_code' element={<ConfirmEmail />} />
      {/* AUTHENTICATED ROUTES */}
      <Route element={<AuthGuard />}>
        <Route path='/' element={<Layout />}>
          {/* MAIN ROUTES: */}
          <Route path='/contacts' element={<Contacts />} />
          <Route path='/departments' element={<Departments />} />
          <Route path='/quick-responses' element={<QuickAnswers />} />
          <Route path='/tags' element={<Tags />} />
          <Route path='/templates' element={<Templates />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/tickets/:ticketId?' element={<Tickets />} />

          <Route path='/unauthorized' element={<Unauthorized />} />
          <Route path='*' element={<Page404 />} />
        </Route>
      </Route>
    </RouterDomRoutes>
  )
}

export default Routes
