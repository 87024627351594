import Button from 'design-system/Button'
import Row from 'design-system/Row'
import Tooltip from 'design-system/Tooltip'
import { notify } from 'helpers'

const CopyBoard = ({ title, content, tooltipTitle, color }) => (
  <Row
    className='cursor-pointer'
    alignItems='center'
    width='fit-content'
    gap='4px'
    onClick={() => {
      navigator?.clipboard?.writeText(content)
      notify.success('Copiado com sucesso!')
    }}
  >
    <Tooltip title={tooltipTitle}>
      <Button
        size='sm'
        iconLeft='Copy'
        variant='outlined'
        textDecoration='underline'
        color={color}
        iconProps={{ size: '11' }}
      >
        {title}
      </Button>
    </Tooltip>
  </Row>
)

CopyBoard.defaultProps = {
  title: 'Copy-to-board',
  content: 'copy text',
  tooltipTitle: 'Copiar',
  color: 'secondary',
}

export default CopyBoard
