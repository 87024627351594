
      import React, { memo } from 'react';

      const ContactCard = (props) => {
        return (
          <svg height="20" width="20"  viewBox="0 0 20 20" {...props}>
	<g clipPath="url(#clip0_212_3032)">
		<g clipPath="url(#clip1_212_3032)">
			<path d="M3.33341 19.1663V17.4997H16.6667V19.1663H3.33341ZM3.33341 2.49967V0.833008H16.6667V2.49967H3.33341ZM10.0001 10.833C10.6945 10.833 11.2848 10.59 11.7709 10.1038C12.257 9.61773 12.5001 9.02745 12.5001 8.33301C12.5001 7.63856 12.257 7.04829 11.7709 6.56217C11.2848 6.07606 10.6945 5.83301 10.0001 5.83301C9.30564 5.83301 8.71536 6.07606 8.22925 6.56217C7.74314 7.04829 7.50008 7.63856 7.50008 8.33301C7.50008 9.02745 7.74314 9.61773 8.22925 10.1038C8.71536 10.59 9.30564 10.833 10.0001 10.833ZM3.33341 16.6663C2.87508 16.6663 2.48272 16.5031 2.15633 16.1768C1.82994 15.8504 1.66675 15.458 1.66675 14.9997V4.99967C1.66675 4.54134 1.82994 4.14898 2.15633 3.82259C2.48272 3.4962 2.87508 3.33301 3.33341 3.33301H16.6667C17.1251 3.33301 17.5174 3.4962 17.8438 3.82259C18.1702 4.14898 18.3334 4.54134 18.3334 4.99967V14.9997C18.3334 15.458 18.1702 15.8504 17.8438 16.1768C17.5174 16.5031 17.1251 16.6663 16.6667 16.6663H3.33341ZM4.79175 14.9997C5.41675 14.2219 6.17369 13.6108 7.06258 13.1663C7.95147 12.7219 8.93064 12.4997 10.0001 12.4997C11.0695 12.4997 12.0487 12.7219 12.9376 13.1663C13.8265 13.6108 14.5834 14.2219 15.2084 14.9997H16.6667V4.99967H3.33341V14.9997H4.79175ZM7.25008 14.9997H12.7501C12.3473 14.7219 11.9133 14.5136 11.448 14.3747C10.9827 14.2358 10.5001 14.1663 10.0001 14.1663C9.50008 14.1663 9.01744 14.2358 8.55216 14.3747C8.08689 14.5136 7.65286 14.7219 7.25008 14.9997ZM10.0001 9.16634C9.76397 9.16634 9.56605 9.08648 9.40633 8.92676C9.24661 8.76704 9.16675 8.56912 9.16675 8.33301C9.16675 8.0969 9.24661 7.89898 9.40633 7.73926C9.56605 7.57954 9.76397 7.49967 10.0001 7.49967C10.2362 7.49967 10.4341 7.57954 10.5938 7.73926C10.7536 7.89898 10.8334 8.0969 10.8334 8.33301C10.8334 8.56912 10.7536 8.76704 10.5938 8.92676C10.4341 9.08648 10.2362 9.16634 10.0001 9.16634Z" fill="#656565"/>
		</g>
	</g>
	<defs>
		<clipPath id="clip0_212_3032">
			<rect height="20" width="20" fill="white"/>
		</clipPath>
		<clipPath id="clip1_212_3032">
			<rect height="20" width="20" fill="white"/>
		</clipPath>
	</defs>
</svg>
        );
      };
      const Memo = memo(ContactCard)
      export default Memo
    