import Column from '../Column'
import { theme } from 'configs'

const Card = ({ children, ...rest }) => (
  <Column
    p='24px'
    backgroundColor='#fff'
    borderRadius='8px'
    border={`1px solid ${theme.colors.grey[50]}`}
    {...rest}
  >
    {children}
  </Column>
)

export default Card
