import { Column, Image, Typography } from 'design-system'
import { robotAlert } from 'assets/ilustrations'

const UnauthorizedWorkhours = (props) => (
  <Column
    width='100%'
    mt='200px'
    height='auto'
    alignItems='center'
    justifyContent='center'
    p='24px'
    {...props}
  >
    <Image maxWidth='225px' width='100%' src={robotAlert} />
    <Typography mt='56px' fontWeight='600' fontSize='24px'>
      Fora do horário de expediente
    </Typography>
    <Typography width='415px' textAlign='center' fontSize='16px' mt='16px'>
      Por favor, retorne durante o horário de trabalho para continuar usando o ChatGDS.
    </Typography>
  </Column>
)

export default UnauthorizedWorkhours
