import { clientApi } from 'providers'

//USERS
export const updateMe = (data) => clientApi.put(`/users/me/`, data)

export const changeUserCompany = (data) => clientApi.post('/users/change-company/', data)

//USER INVITES
export const showUserInvites = () => clientApi.get('/users/me/invites/')

export const acceptUserInvite = (id, data) => clientApi.put(`/users/me/invites/${id}/`, data)

//USER REGISTER
export const registerUser = (data) => clientApi.post('/users/register/', data)

export const confirmaUserEmail = (data) => clientApi.put('/users/confirm-email/', data)

//USER COMPANY
export const showCompanyUsersList = (params) => clientApi.get(`/users/company-users/`, { params })

export const showCompanyUsersListSelect = (params) =>
  clientApi.get(`/users/company-users/select/`, { params })

export const showCompanyUserInfo = (id) => clientApi.get(`/users/company-users/${id}/`)

export const createCompanyUser = (data) => clientApi.post('/users/company-users/', data)

export const updatedCompanyUser = (id, data) => clientApi.put(`/users/company-users/${id}/`, data)

export const deleteCompanyUser = (id) => clientApi.delete(`/users/company-users/${id}/`)
