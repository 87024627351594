import styled from 'styled-components'

import { Column, Icon, Image, Row } from 'design-system'

import Menu from './Menu'

import { theme } from 'configs'
import { chatGDSLogoSidebar } from 'assets'

const Desktop = ({ open, setOpen }) => {
  const { colors } = theme

  return (
    <Base height='100vh' alignItems='center' background={colors?.white} p='0 13px' open={open}>
      <Row
        p='0 10px'
        width='100%'
        alignItems='center'
        justifyContent={open ? 'space-between' : 'flex-end'}
      >
        {open && <Image width='120px' src={chatGDSLogoSidebar} />}
        <Icon
          icon='Hamburger'
          color='primary'
          size='xlg'
          cursor='pointer'
          onClick={() => setOpen(!open)}
        />
      </Row>
      <Column mt='16px' width='100%' overflow='hidden'>
        <Menu open={open} setOpen={setOpen} />
      </Column>
    </Base>
  )
}

const Base = styled(Column)`
  position: fixed;
  z-index: 1000;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 16px 10px 30px;
  height: 100vh;
  transition: all 0.3s ease;
  min-width: ${({ open }) => (open ? '216px' : '80px')};
  width: ${({ open }) => (open ? '216px' : '80px')};
  & svg {
    min-width: 34px;
  }
`

export default Desktop
