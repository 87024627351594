import { useState } from 'react'

import { Accordion, Avatar, Button, Card, Column, Row, Skeleton, Typography } from 'design-system'
import ModalContact from 'components/ModalContact'
import ContactDetails from './ContactDetails'
import TicketHistoric from './TicketHistoric'
import ContactTags from './ContactTags'
import { formattedPhone } from 'utils'
import SkeletonContactCard from '../SkeletonContactCard'

const ContactInfos = ({ ticket, loading }) => {
  const [modal, setModal] = useState(null)

  const { contact } = ticket

  return (
    <Column p='16px' width='100%' alignItems='center'>
      <Column width='100%' alignItems='center'>
        <Typography fontSize='16px' fontWeight='600'>
          Informações do contato
        </Typography>
        <Card width='100%' mt='16px' p='16px'>
          {loading ? (
            <SkeletonContactCard />
          ) : (
            <Row
              gap='12px'
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent='center'
              alignItems='center'
            >
              <Avatar square size='110' src={contact?.picture} alt={contact?.name} />
              <Column gap='4px'>
                <Typography fontWeight='600' textAlign='center'>
                  {contact?.name}
                </Typography>
                <Typography textAlign='center'>{formattedPhone(contact?.dial_code)}</Typography>
                <Button
                  iconLeft='Edit'
                  variant='text'
                  color='secondary'
                  onClick={() => setModal(contact)}
                >
                  Editar
                </Button>
              </Column>
            </Row>
          )}
        </Card>
        {loading ? <Skeleton mt='16px' height='50px' /> : <ContactTags contact={ticket?.contact} />}
        {loading ? (
          <Skeleton mt='16px' height='50px' />
        ) : (
          <Accordion mt='16px' title='Detalhes do contato'>
            <ContactDetails ticket={ticket} />
          </Accordion>
        )}

        {loading ? (
          <Skeleton mt='16px' height='50px' />
        ) : (
          <Accordion mt='16px' maxHeight='380px' title='Histórico de conversas' defaultOpened>
            <TicketHistoric ticket={ticket} />
          </Accordion>
        )}
      </Column>
      <ModalContact contact={modal} onClose={() => setModal(null)} />
    </Column>
  )
}

export default ContactInfos
