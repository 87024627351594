import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import {
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Input,
  Select,
  Row,
  Button,
  InputMasked,
  Typography,
  Card,
  TextArea,
  Column,
  Image,
  Spinner,
} from 'design-system'

import { notify } from 'helpers'
import { useStorage } from 'hooks'
import { removeMask } from 'utils'

import {
  createContact,
  showContactInfo,
  showExtraFieldsList,
  showTagsList,
  updatedContact,
} from 'services'

import { robotNodata } from 'assets/ilustrations'
import { countryCodes } from 'configs'
import CustomField from './CustomField'

const ModalContact = ({ onClose, contact, dispatch, actionTypes, ...props }) => {
  const [loading, setLoading] = useState(false)
  const [disablePhoneField, setDisablePhoneField] = useState(false)

  const [extrafieldsListOptions, setExtrafieldsListOptions] = useState([])
  const [tagsListOptions, setTagsListOptions] = useState([])

  const { setStorage, getStorage, deleteStorage } = useStorage()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    if (contact) {
      handleGetInfo()
    }

    if (contact && !contact.id) {
      const cacheValues = JSON.parse(getStorage('form-contact'))

      if (cacheValues?.id) {
        contact.id = cacheValues.id
      }

      reset(cacheValues || { name: '', email: '', tags: [], extra_fields: [] })
      deleteStorage('form-contact')
    }

    return () => reset({ name: '', email: '', tags: [], extra_fields: [] })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact])

  const onSubmit = async (values) => {
    try {
      setLoading(true)

      values.tags = values.tags.map((t) => t.id)
      values.country_code = values.country_code.code
      values.dial_code = removeMask(values.dial_code)
      values.extra_fields = values.extra_fields
        .map((m) => ({
          value: m.field_type === 'boolean' ? m.value || false : m.value,
          extra_field: m.extra_field.id,
        }))
        .filter((f) => f.value !== '')

      delete values.picture

      const { data } = contact?.id
        ? await updatedContact(contact?.id, values)
        : await createContact(values)

      dispatch &&
        dispatch({
          type: actionTypes.UPDATE_ITEM,
          payload: data,
        })
      notify.success(
        contact?.id ? 'Informações salvas com sucesso.' : 'Novo contato criado com sucesso.',
      )
      onClose()
    } catch {
      notify.error('Não foi possível salvar contato.')
    } finally {
      setLoading(false)
    }
  }

  const handleGetInfo = async () => {
    try {
      setLoading(true)
      const { data: infos } = contact?.id ? await showContactInfo(contact?.id) : {}

      const [{ data: extrafields }, { data: tagsList }] = await Promise.all([
        showExtraFieldsList({ page_size: 1000 }),
        showTagsList({ page_size: 1000 }),
      ])

      if (infos) {
        infos.country_code = countryCodes.find((item) => item.value === infos.country_code)

        infos.extra_fields = extrafields?.results?.map((ef) => ({
          ...ef,
          ...infos.extra_fields.find(({ extra_field }) => extra_field.id === ef.id),
        }))

        setDisablePhoneField(() => infos?.can_update_phone)

        reset(infos)
      }

      setExtrafieldsListOptions(extrafields?.results)
      setTagsListOptions(tagsList?.results)
    } catch {
      notify.error('Não foi possível buscar dados do contato.')
    } finally {
      setLoading(false)
    }
  }

  const handlerOnNavigate = () => {
    const cacheValues = getValues()

    setStorage('form-contact', JSON.stringify(cacheValues))
    notify.robot(
      'Os dados de criação do seu contato foram salvos. Quando você retornar à criação do contato, os campos serão automaticamente preenchidos.',
    )
    navigate('/settings', { state: { path: 'extra_fields' } })
  }

  return (
    <Modal open={contact} onClose={onClose} {...props}>
      <ModalHeader onClose={onClose}>
        <Typography variant='title' color='primary'>
          {contact?.id ? 'Editar contato' : 'Novo contato'}
        </Typography>
      </ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Row gap='16px'>
            <Input
              label='Nome completo'
              error={errors?.name}
              disabled={loading}
              loading={loading}
              required
              {...register('name', { required: true })}
            />
            <Input
              disabled={loading}
              loading={loading}
              label='E-mail'
              error={errors?.email}
              {...register('email')}
            />
          </Row>
          <Row mt='16px' gap='16px'>
            <Select
              label='Código do país'
              options={countryCodes}
              name='country_code'
              defaultValue={{ label: 'Brasil', code: '55' }}
              control={control}
              keys={{ label: 'label', value: 'code' }}
              loading={loading}
            />
            <InputMasked
              label='Telefone'
              name='dial_code'
              error={errors?.dial_code}
              mask='(11) 11111-1111'
              control={control}
              disabled={loading || (contact?.id && !disablePhoneField)}
              loading={loading}
              required
            />
          </Row>
          <Row mt='16px'>
            <Select
              isMulti
              label='Etiquetas'
              options={tagsListOptions}
              name='tags'
              control={control}
              keys={{ label: 'name', value: 'id' }}
              loading={loading}
            />
          </Row>
          <Row mt='16px'>
            <TextArea
              label='Observações'
              height='150px'
              error={errors?.notes}
              loading={loading}
              {...register('notes')}
            />
          </Row>
          <Card mt='16px' width='100%' p='16px'>
            {loading ? (
              <Row justifyContent='center' alignItems='center' height='100px'>
                <Spinner />
              </Row>
            ) : extrafieldsListOptions?.length > 0 ? (
              <CustomField
                extrafieldsList={extrafieldsListOptions}
                register={register}
                errors={errors}
                loading={loading}
              />
            ) : (
              <Row gap='16px' justifyContent='center' alignItems='center'>
                <Image src={robotNodata} width='100px' />
                <Column justifyContent='center' alignItems='center' height='100px'>
                  <Typography>Parece que você ainda não tem campos personalizados.</Typography>
                  <Button mt='16px' width='100%' maxWidth='280px' onClick={handlerOnNavigate}>
                    Crie o seu primeiro campo aqui.
                  </Button>
                </Column>
              </Row>
            )}
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button maxWidth='100px' variant='text' color='grey.500' onClick={onClose}>
            Cancelar
          </Button>
          <Button maxWidth='70px' type='submit' disabled={loading}>
            Salvar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default ModalContact
