import { colors } from 'configs'
import styled from 'styled-components'
import { space, color, layout, compose, border } from 'styled-system'

const style = compose(space, color, layout, border)

const Base = styled.audio`
  width: 100%;
  height: 40px;
  background-color: ${colors.grey[50]} !important;
  border: none;
  ${style};
`

const AudioPlayer = ({ src, ...props }) => (
  <Base controls {...props}>
    <source src={src} type='audio/ogg' />
    <source src={src} type='audio/mpeg' />
  </Base>
)

export default AudioPlayer
