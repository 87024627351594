export const validateHour = (value) => {
  if (!value || value === '')
    return 'O horário selecionado é inválido. Por favor, escolha outro horário.'

  const splitHour = value.split(':')

  if (Number(splitHour[0]) > 23 || Number(splitHour[0]) < 0)
    return 'O horário selecionado é inválido. Por favor, escolha outro horário.'

  if (Number(splitHour[1]) > 59 || Number(splitHour[1]) < 0)
    return 'O horário selecionado é inválido. Por favor, escolha outro horário.'

  return false
}

export const validateRangeHour = (value1, value2) => {
  if (!value1 || value1 === '')
    return 'O horário selecionado é inválido. Por favor, escolha outro horário.'
  if (!value2 || value2 === '')
    return 'O horário selecionado é inválido. Por favor, escolha outro horário.'

  const splitHour1 = Number(value1.replace(':', ''))
  const splitHour2 = Number(value2.replace(':', ''))

  if (splitHour1 > splitHour2) return 'O horário de início não pode ser maior que o de término.'

  return false
}
