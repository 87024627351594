import React from 'react'
import styled from 'styled-components'
import { space, layout, compose } from 'styled-system'

import Typography from '../Typography'
import variants from './variants'
import Image from 'design-system/Image'

const styles = compose(space, layout)

const Base = styled.div`
  position: relative;
`

const AvatarBase = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
  border-radius: ${({ square, borderRadius }) => (square ? borderRadius : '50%')};
  cursor: ${({ cursor }) => cursor};
  ${styles}
  ${variants}
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${({ borderRadius }) => borderRadius};
`

const Avatar = ({ badge, src, alt = 'Avatar', square, borderRadius, ...props }) => {
  const isValidImage = src && typeof src === 'string' && src.trim() !== ''

  return (
    <Base>
      <AvatarBase badge={badge} square={square} borderRadius={borderRadius} {...props}>
        {isValidImage ? (
          <Img src={src} alt={alt} {...props} />
        ) : (
          <Typography fontSize='24' fontWeight='bold' cursor={props.cursor}>
            {alt.charAt(0).toUpperCase()}
          </Typography>
        )}
      </AvatarBase>
      {badge && <Image position='absolute' bottom='-5px' right='-5px' src={badge} width='24px' />}
    </Base>
  )
}
Avatar.defaultProps = {
  size: 'sm',
  cursor: 'initial',
  badge: null,
  borderRadius: '16px',
}

export default Avatar
