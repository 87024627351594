import { system } from 'configs'

const useStorage = () => {
  const { STORAGE, ENVIRONMENT } = system
  const getKey = (item) => `${STORAGE}_${ENVIRONMENT}_${item}`

  const getStorage = (item) => {
    try {
      return JSON.parse(localStorage.getItem(getKey(item)))
    } catch (error) {
      console.error(`Error reading from Local Storage: ${error.message}`)
      return null
    }
  }

  const setStorage = (item, data) => {
    try {
      localStorage.setItem(getKey(item), JSON.stringify(data))
    } catch (error) {
      console.error(`Error writing to Local Storage: ${error.message}`)
    }
  }

  const deleteStorage = (item) => {
    try {
      localStorage.removeItem(getKey(item))
    } catch (error) {
      console.error(`Error deleting from Local Storage: ${error.message}`)
    }
  }

  return { getStorage, setStorage, deleteStorage }
}

export default useStorage
