
      import React, { memo } from 'react';

      const Chat = (props) => {
        return (
          <svg height="20" width="20"  viewBox="0 0 20 20" {...props}>
	<path d="M5.00008 11.667H11.6667V10.0003H5.00008V11.667ZM5.00008 9.16699H15.0001V7.50033H5.00008V9.16699ZM5.00008 6.66699H15.0001V5.00033H5.00008V6.66699ZM1.66675 18.3337V3.33366C1.66675 2.87533 1.82994 2.48296 2.15633 2.15658C2.48272 1.83019 2.87508 1.66699 3.33341 1.66699H16.6667C17.1251 1.66699 17.5174 1.83019 17.8438 2.15658C18.1702 2.48296 18.3334 2.87533 18.3334 3.33366V13.3337C18.3334 13.792 18.1702 14.1844 17.8438 14.5107C17.5174 14.8371 17.1251 15.0003 16.6667 15.0003H5.00008L1.66675 18.3337ZM4.29175 13.3337H16.6667V3.33366H3.33341V14.2712L4.29175 13.3337Z" fill="#656565"/>
</svg>
        );
      };
      const Memo = memo(Chat)
      export default Memo
    