
      import React, { memo } from 'react';

      const Trash = (props) => {
        return (
          <svg height="20" width="20"  viewBox="0 0 20 20" {...props}>
	<path d="M13.3334 7.5V15.8333H6.66675V7.5H13.3334ZM12.0834 2.5H7.91675L7.08341 3.33333H4.16675V5H15.8334V3.33333H12.9167L12.0834 2.5ZM15.0001 5.83333H5.00008V15.8333C5.00008 16.75 5.75008 17.5 6.66675 17.5H13.3334C14.2501 17.5 15.0001 16.75 15.0001 15.8333V5.83333Z" fill="#656565"/>
</svg>
        );
      };
      const Memo = memo(Trash)
      export default Memo
    