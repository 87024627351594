import React, { memo } from 'react'

const NotificationsUnread = (props) => {
  return (
    <svg height='20' width='20' viewBox='0 0 20 20' {...props}>
      <path
        d='M10.0002 18.3337C9.54183 18.3337 9.14947 18.1705 8.82308 17.8441C8.49669 17.5177 8.3335 17.1253 8.3335 16.667H11.6668C11.6668 17.1253 11.5036 17.5177 11.1772 17.8441C10.8509 18.1705 10.4585 18.3337 10.0002 18.3337ZM3.3335 15.8337V14.167H5.00016V8.33366C5.00016 7.18088 5.34739 6.15658 6.04183 5.26074C6.73627 4.36491 7.63905 3.7781 8.75016 3.50033V2.91699C8.75016 2.56977 8.87169 2.27463 9.11475 2.03158C9.3578 1.78852 9.65294 1.66699 10.0002 1.66699C10.3474 1.66699 10.6425 1.78852 10.8856 2.03158C11.1286 2.27463 11.2502 2.56977 11.2502 2.91699V3.18783C11.1113 3.4656 11.0071 3.75727 10.9377 4.06283C10.8682 4.36838 10.8335 4.68088 10.8335 5.00033C10.8335 6.1531 11.2397 7.13574 12.0522 7.94824C12.8647 8.76074 13.8474 9.16699 15.0002 9.16699V14.167H16.6668V15.8337H3.3335ZM15.0002 7.50033C14.3057 7.50033 13.7154 7.25727 13.2293 6.77116C12.7432 6.28505 12.5002 5.69477 12.5002 5.00033C12.5002 4.30588 12.7432 3.7156 13.2293 3.22949C13.7154 2.74338 14.3057 2.50033 15.0002 2.50033C15.6946 2.50033 16.2849 2.74338 16.771 3.22949C17.2571 3.7156 17.5002 4.30588 17.5002 5.00033C17.5002 5.69477 17.2571 6.28505 16.771 6.77116C16.2849 7.25727 15.6946 7.50033 15.0002 7.50033Z'
        fill='#656565'
      />
    </svg>
  )
}
const Memo = memo(NotificationsUnread)
export default Memo
