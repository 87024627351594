import { colors } from 'configs'
import { Typography } from 'design-system'
import styled from 'styled-components'

const DateSeparator = ({ date }) => (
  <Base>
    <Typography textAlign='center' fontWeight='600' color='grey.400' notSelectable>
      {date}
    </Typography>
  </Base>
)

const Base = styled.div`
  width: 100%;
  max-width: 90px;
  background-color: ${colors.blue[60]};
  border-radius: 8px;
  position: sticky;
  top: 20px;
  left: 50%;
  padding: 4px 0;
  transform: translateX(-50%);
`

export default DateSeparator
