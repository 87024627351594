import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'

import Row from 'design-system/Row'
import Typography from 'design-system/Typography'
import Icon from 'design-system/Icon'

import { navigation } from 'configs'
import { useAuth } from 'hooks'

const Menu = ({ open, setOpen, ...rest }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { userData } = useAuth()

  const { company_user } = userData

  return navigation.map(({ icon, title, defaultRoute }, idx) => (
    <Base
      key={`${defaultRoute}_${idx}`}
      open={open}
      active={String(defaultRoute === location.pathname)}
      onClick={() => (company_user ? navigate(defaultRoute) : undefined)}
      {...rest}
    >
      <Icon icon={icon} color={company_user ? 'primary' : 'text'} size='lg' />
      {open && (
        <Typography width='100%' lineHeight='24px' color='grey.300' cursor whiteSpace='nowrap'>
          {title}
        </Typography>
      )}
    </Base>
  ))
}

const Base = styled(Row)`
  width: 100%;
  padding: 12px 16px;
  margin: 8px 0 0;
  cursor: pointer;
  overflow: hidden;
  gap: 6px;
  align-items: center;
  justify-content: ${({ open }) => (open ? 'flex-start' : 'center')};
  border-radius: 0 8px 8px 0;
  transition: all 0.3s ease;
  background-color: ${({ active, theme: { colors } }) =>
    active === 'true' ? colors.blue[40] : 'transparent'};
  border-left: ${({ active, theme: { colors } }) =>
    active === 'true' ? `1px solid ${colors.primary}` : '1px solid #fff'};
  &:hover {
    background-color: #edf3fc;
    border-left: ${({ theme: { colors } }) => `1px solid ${colors.primary}`};
  }
`

export default Menu
