const system = {
  COOKIE_TOKEN: process.env.REACT_APP_COOKIE_TOKEN,
  API_URL: process.env.REACT_APP_API_URL,
  STORAGE: process.env.REACT_APP_STORAGE,
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
  SOCKET_ENVIRONMENT: process.env.REACT_APP_SOCKET_ENVIRONMENT,
  SOCKET_API: process.env.REACT_APP_SOCKET_API,
}

export default system
