import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { space, layout, compose } from 'styled-system'

import Icon from '../Icon'
import Typography from '../Typography'
import Skeleton from 'design-system/Skeleton'

const styles = compose(space, layout)

const Base = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: fit-content;
  width: 100%;
  ${styles}
`

const InputField = styled.input`
  width: inherit;
  border-radius: 4px;
  font-size: 14px;
  color: ${({ theme: { colors } }) => colors.grey[300]};
  padding: ${({ position, icon }) =>
    icon ? (position === 'end' ? '5px 36px 5px 8px' : '5px 8px 5px 32px') : '5px 16px'};
  border: ${({ theme: { colors } }) => `1px solid ${colors.grey[50]}`};
  &:hover,
  &:focus {
    border: ${({ theme: { colors } }) => `1px solid ${colors.blue[80]}`};
  }
  &::placeholder {
    color: ${({ theme: { colors } }) => colors.grey[75]};
  }
  ${styles};
`

const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  ${({ position }) => (position === 'end' ? 'right: 8px;' : 'left: 8px;')};
  top: ${({ label, error }) => (label && !error ? ' 10px;' : '0px;')};
`

const Input = forwardRef(
  (
    {
      label,
      icon,
      iconPosition,
      containerProps,
      maxWidth,
      iconProps,
      error,
      errorMessage,
      loading,
      ...props
    },
    ref,
  ) => {
    return loading ? (
      <Base maxWidth={maxWidth} {...containerProps}>
        {label && <Skeleton width='100px' height='20px' />}
        <Skeleton mt='2px' maxWidth={maxWidth} p='15px' {...containerProps} />
      </Base>
    ) : (
      <Base maxWidth={maxWidth} {...containerProps}>
        {label && <Typography>{`${label} ${props?.required ? '*' : ''}`}</Typography>}
        <InputField ref={ref} icon={icon} position={iconPosition} {...props} autoComplete='off' />
        {icon && (
          <IconWrapper error={error} label={label} position={iconPosition}>
            <Icon icon={icon} {...iconProps} />
          </IconWrapper>
        )}
        {error && (
          <Typography mt='2px' ml='2px' variant='helper' color='danger' lineHeight='10px'>
            {errorMessage || 'Campo obrigatório'}
          </Typography>
        )}
      </Base>
    )
  },
)

Input.defaultProps = {
  type: 'text',
}

export default Input
