import { useReducer } from 'react'

export const initialValues = {
  roles: [],
  users: [],
  extra_fields: [],
  invites: [],
  user_invites: [],
  connections: [],
}

export const actionTypes = {
  LOAD_ITEMS: 'LOAD_ITEMS',
  ADD_ITEM: 'ADD_ITEM',
  UPDATE_ITEM: 'UPDATE_ITEM',
  REMOVE_ITEM: 'REMOVE_ITEM',
}

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.LOAD_ITEMS:
      return {
        ...state,
        [action.tab]: action.payload,
      }
    case actionTypes.ADD_ITEM:
      return {
        ...state,
        [action.tab]: [...state[action.tab], action.payload],
      }
    case actionTypes.UPDATE_ITEM:
      const filter = state[action.tab].filter((item) => item.id !== action.payload.id)
      return {
        ...state,
        [action.tab]: [...filter, action.payload],
      }
    case actionTypes.REMOVE_ITEM:
      return {
        ...state,
        [action.tab]: state[action.tab].filter((item) => item.id !== action.payload),
      }
    default:
      return state
  }
}

export const useSettingsReducer = () => useReducer(reducer, initialValues)
