import { theme } from 'configs'

const hexToRGBA = (hex, alpha) => {
  if (!hex) return

  if (!hex.startsWith('#')) return

  hex = hex.startsWith('#')
    ? hex?.replace(/^#/, '')
    : hex.startsWith('rgb')
    ? hex
    : theme.colors[hex]?.replace(/^#/, '')

  let r, g, b

  if (hex.length === 3) {
    r = parseInt(hex[0] + hex[0], 16)
    g = parseInt(hex[1] + hex[1], 16)
    b = parseInt(hex[2] + hex[2], 16)
  } else if (hex.length === 6) {
    r = parseInt(hex.slice(0, 2), 16)
    g = parseInt(hex.slice(2, 4), 16)
    b = parseInt(hex.slice(4, 6), 16)
  } else {
    return `rbga(255, 255, 255, 1)`
  }

  const result = `rgba(${r}, ${g}, ${b}, ${alpha || 1})`
  return result
}

export default hexToRGBA
