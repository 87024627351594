import { Column, Row, Skeleton } from 'design-system'

const SkeletonTicketCard = () => {
  return (
    <Column>
      <Row
        p='12px 8px'
        borderRight='none'
        borderTop='1px solid #E8E8E8'
        borderRadius='8px 0 0 8px'
        borderLeft='5px solid #E8E8E8'
        gap='16px'
      >
        <Skeleton borderRadius='16px' maxWidth='70px' height='70px' />
        <Column width='100%'>
          <Skeleton maxWidth='150px' height='10px' />
          <Skeleton mt='8px' maxWidth='250px' height='10px' />
          <Column mt='8px' width='100%' alignItems='flex-end'>
            <Skeleton maxWidth='50px' height='10px' />
          </Column>
        </Column>
      </Row>
      <Row
        p='12px 8px'
        borderRight='none'
        borderTop='1px solid #E8E8E8'
        borderRadius='8px 0 0 8px'
        borderLeft='5px solid #E8E8E8'
        gap='16px'
      >
        <Skeleton borderRadius='16px' maxWidth='70px' height='70px' />
        <Column width='100%'>
          <Skeleton maxWidth='150px' height='10px' />
          <Skeleton mt='8px' maxWidth='250px' height='10px' />
          <Column mt='8px' width='100%' alignItems='flex-end'>
            <Skeleton maxWidth='50px' height='10px' />
          </Column>
        </Column>
      </Row>
      <Row
        p='12px 8px'
        borderRight='none'
        borderTop='1px solid #E8E8E8'
        borderRadius='8px 0 0 8px'
        borderLeft='5px solid #E8E8E8'
        gap='16px'
      >
        <Skeleton borderRadius='16px' maxWidth='70px' height='70px' />
        <Column width='100%'>
          <Skeleton maxWidth='150px' height='10px' />
          <Skeleton mt='8px' maxWidth='250px' height='10px' />
          <Column mt='8px' width='100%' alignItems='flex-end'>
            <Skeleton maxWidth='50px' height='10px' />
          </Column>
        </Column>
      </Row>
      <Row
        p='12px 8px'
        borderRight='none'
        borderTop='1px solid #E8E8E8'
        borderRadius='8px 0 0 8px'
        borderLeft='5px solid #E8E8E8'
        gap='16px'
      >
        <Skeleton borderRadius='16px' maxWidth='70px' height='70px' />
        <Column width='100%'>
          <Skeleton maxWidth='150px' height='10px' />
          <Skeleton mt='8px' maxWidth='250px' height='10px' />
          <Column mt='8px' width='100%' alignItems='flex-end'>
            <Skeleton maxWidth='50px' height='10px' />
          </Column>
        </Column>
      </Row>
      <Row
        p='12px 8px'
        borderRight='none'
        borderTop='1px solid #E8E8E8'
        borderRadius='8px 0 0 8px'
        borderLeft='5px solid #E8E8E8'
        gap='16px'
      >
        <Skeleton borderRadius='16px' maxWidth='70px' height='70px' />
        <Column width='100%'>
          <Skeleton maxWidth='150px' height='10px' />
          <Skeleton mt='8px' maxWidth='250px' height='10px' />
          <Column mt='8px' width='100%' alignItems='flex-end'>
            <Skeleton maxWidth='50px' height='10px' />
          </Column>
        </Column>
      </Row>
      <Row
        p='12px 8px'
        borderRight='none'
        borderTop='1px solid #E8E8E8'
        borderRadius='8px 0 0 8px'
        borderLeft='5px solid #E8E8E8'
        gap='16px'
      >
        <Skeleton borderRadius='16px' maxWidth='70px' height='70px' />
        <Column width='100%'>
          <Skeleton maxWidth='150px' height='10px' />
          <Skeleton mt='8px' maxWidth='250px' height='10px' />
          <Column mt='8px' width='100%' alignItems='flex-end'>
            <Skeleton maxWidth='50px' height='10px' />
          </Column>
        </Column>
      </Row>
    </Column>
  )
}

export default SkeletonTicketCard
