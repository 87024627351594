import axios from 'axios'
import Cookies from 'js-cookie'

import { system } from 'configs'

const { COOKIE_TOKEN, API_URL } = system

const defaultConfig = {
  baseURL: `${API_URL}/api`,
  withCredentials: true,
}

const clientApi = axios.create(defaultConfig)

const requestInterceptor = (config) => {
  const token = Cookies.get(COOKIE_TOKEN)
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: token ? `token ${token}` : '',
    },
  }
}

const responseInterceptor = (response) => response
const errorInterceptor = (error) => {
  if (error.response && error.response.status === 401) {
    Cookies.remove(COOKIE_TOKEN)
  }

  return Promise.reject(error)
}

clientApi.interceptors.request.use(requestInterceptor)
clientApi.interceptors.response.use(responseInterceptor, errorInterceptor)

export default clientApi
