import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { space, color, layout, position, compose } from 'styled-system'

const styles = compose(space, color, layout, position)

const directionDict = {
  top: {
    top: 0,
  },
  'top-left': {
    top: 0,
    left: 0,
  },
  'top-right': {
    top: 0,
    right: 0,
  },
  bottom: {
    bottom: 0,
  },
  'bottom-left': {
    bottom: 0,
    left: 0,
  },
  'bottom-right': {
    bottom: 0,
    right: 0,
  },
  right: {
    right: 0,
  },
  left: {
    left: 0,
  },
}

const BasePopover = styled.div`
  position: absolute;
  min-width: 200px;
  background-color: #fff;
  box-shadow: 4px 5px 15px -7px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  z-index: 100;
  height: fit-content;
  ${({ position }) => (position ? directionDict[position] : '')};
  ${styles};
`

const Popover = forwardRef(({ open, children, ...props }, ref) =>
  open ? (
    <BasePopover ref={ref} {...props}>
      {children}
    </BasePopover>
  ) : null,
)

export default Popover
